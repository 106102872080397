import React, { useEffect, useState } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import {
  TextField,
  TableContainer,
  Paper,
  Card,
  Grid,
  Table,
  TableBody,
  CircularProgress,
  Dialog,
  DialogContent,
  Typography,
  TableRow,
  Tooltip,
} from "@mui/material";
import MDBox from "components/MDBox";
import EditIcon from "@mui/icons-material/Edit";
import {
  StyledTypography,
  StyledButton,
  StyledButtonCF,
  StyledButtonC,
  StyledTableCell,
  StyledAlert,
  StyledSnackbar,
  inputStyle,
} from "components/StyledComponents/styledComponents.js";
import { configStyles } from "components/Styles/companyConfigurationStyles";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import URLs from "constants/urls";
import Cookie from "js-cookie";
import { useNavigate } from "react-router-dom";
import { tableShadow } from "components/Styles/globalStyles";

const CompanyConfiguration = () => {
  const [selectedCompanyName, setSelectedCompanyName] = useState("");
  const [selectedStreet, setSelectedStreet] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState("");
  const [selectedEmailID, setSelectedEmailID] = useState("");
  const [OPEXPercentage, setOPEXPercentage] = useState("");
  const [TDS, setTDS] = useState("");
  const [BusinessLoyalty, setBusinessLoyalty] = useState("");
  const [EmployeeProductivityTimes, setEmployeeProductivityTimes] = useState("");
  const [id, setId] = useState(0);
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [companyData, setCompanyData] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [apiPutStatus, setApiPutStatus] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [alertType, setAlertType] = useState("success");
  const [subManager, setSubManager] = useState(null);
  const [companyTDSPercent, setCompanyTDSPercent] = useState("");
  const [opexamount, setOpexamount] = useState(0.0);
  const [bm_cH_Name, setBm_cH_Name] = useState(null);
  const [isEmailValid, setIsEmailValid] = useState(true); // Track email validity
  const MInput = inputStyle();
  const jwt_token = Cookie.get("jwt_token");
  const navigate = useNavigate();

  const setAlert = (msg, type) => {
    setMessage(msg);
    setAlertType(type);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    fetchCompanyData();
  }, []);

  const handleOpexAmountChange = (e) => {
    const value = e.target.value;
    // Regular expression to allow only numbers and up to one decimal point
    const moneyRegex = /^\d*\.?\d{0,2}$/;
    if (moneyRegex.test(value)) {
      setOpexamount(value);
    }
  };

  const fetchCompanyData = async () => {
    const url = URLs.companyConfigurationUrl;
    setLoading(true);
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt_token}`, // Include the JWT token here
      },
    };
    try {
      const response = await fetch(url, options);
      if (response.status === 401) {
        Cookie.remove("jwt_token");
        navigate("/login");
        return null;
      }
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      if (data && data.length > 0) {
        // Data exists, update the state for updating
        setApiPutStatus(true); // Indicate that we should update instead of create
        setId(data[0].companyId); // Set the ID for updating
        const filteredData = {
          companyName: data[0].companyName,
          street: data[0].street,
          city: data[0].city,
          state: data[0].state,
          phoneNumber: data[0].phoneNumber,
          emailID: data[0].emailID,
          opexPercent: data[0].opexPercent,
          tdsPercent: data[0].tdsPercent,
          businessLoyaltyPercent: data[0].businessLoyaltyPercent,
          empProductivityTimes: data[0].empProductivityTimes,
          subManager: data[0].subManager || "",
          chName: data[0].bm_cH_Name || "",
          companyTDSPercent: data[0].companyTDSPercent,
          opexAmount: data[0].opexamount,
          companyId: data[0].companyId,
        };
        setCompanyData(filteredData); // Set the first item (assuming only one entry)
      } else {
        // No data found, create a new entry
        setApiPutStatus(false); // Indicate that we will create a new entry
        setCompanyData({}); // Reset the form or data fields for a new entry
        setAlert("No Data Found, Creating New Entry", "warning");
      }
    } catch (error) {
      setAlert("Failed to fetch data", "error");
    }
    setLoading(false);
  };

  const createOrUpdateCompany = async () => {
    const url = apiPutStatus
      ? `${URLs.companyConfigurationUrl}/${id}`
      : `${URLs.companyConfigurationUrl}`;

    const data = {
      companyId: id,
      companyName: selectedCompanyName,
      street: selectedStreet,
      city: selectedCity,
      state: selectedState,
      phoneNumber: selectedPhoneNumber,
      emailID: selectedEmailID,
      opexPercent: parseFloat(OPEXPercentage),
      tdsPercent: parseFloat(TDS),
      businessLoyaltyPercent: parseFloat(BusinessLoyalty),
      empProductivityTimes: parseFloat(EmployeeProductivityTimes),
      subManager: subManager,
      companyTDSPercent: parseFloat(companyTDSPercent),
      opexamount: parseFloat(opexamount),
      bm_cH_Name: bm_cH_Name,
    };

    const options = {
      method: apiPutStatus ? "PUT" : "POST", // Switch between PUT and POST
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt_token}`,
      },
      body: JSON.stringify(data),
    };
    setLoading(true);
    try {
      const response = await fetch(url, options);
      if (response.status === 401) {
        Cookie.remove("jwt_token");
        navigate("/login");
        return null;
      }
      if (response.ok) {
        {
          apiPutStatus && setAlert("Updated Successfully", "success");
        }
        {
          !apiPutStatus && setAlert("Posted Successfully", "success");
        }
        resetForm();
      } else {
        throw new Error();
      }
    } catch (error) {
      {
        apiPutStatus && setAlert("Failed to Update: " + error.message, "error");
      }
      {
        !apiPutStatus && setAlert("Failed to Update: " + error.message, "error");
      }
    }
    setLoading(false);
    setTimeout(() => {
      fetchCompanyData();
    }, 700);
  };

  const rows = Object.entries(companyData);
  const formatKey = (key) => {
    // Insert a space before each uppercase letter and capitalize the first letter of the string
    const formattedKey = key.replace(/([A-Z])/g, " $1").replace(/^./, (str) => str.toUpperCase());
    return formattedKey;
  };

  const handleSave = (e) => {
    e.preventDefault();
    if (!isEmailValid) {
      setAlert("Please enter a valid email.");
      return; // Stop form submission if the email is invalid
    }
    if (validateForm()) {
      createOrUpdateCompany();
    }
  };

  const resetForm = () => {
    setOPEXPercentage("");
    setSelectedCompanyName("");
    setSelectedStreet("");
    setSelectedCity("");
    setSelectedState("");
    setSelectedPhoneNumber("");
    setSelectedEmailID("");
    setTDS("");
    setBusinessLoyalty("");
    setEmployeeProductivityTimes("");
    setIsEditMode(false);
    setSubManager("");
    setBm_cH_Name("");
    setOpexamount("");
    setCompanyTDSPercent("");
    setOpexamount("");
  };

  const validateForm = () => {
    return (
      selectedCompanyName.trim() &&
      selectedStreet.trim() &&
      selectedCity.trim() &&
      selectedState.trim() &&
      selectedPhoneNumber.trim() &&
      selectedEmailID.trim() &&
      TDS &&
      OPEXPercentage &&
      BusinessLoyalty &&
      EmployeeProductivityTimes
    );
  };

  const handleEdit = () => {
    setIsEditMode(true);
    setId(companyData.companyId);
    setOPEXPercentage(companyData.opexPercent);
    setSelectedCompanyName(companyData.companyName);
    setSelectedStreet(companyData.street);
    setSelectedCity(companyData.city);
    setSelectedState(companyData.state);
    setSelectedPhoneNumber(companyData.phoneNumber);
    setSelectedEmailID(companyData.emailID);
    setTDS(companyData.tdsPercent);
    setBusinessLoyalty(companyData.businessLoyaltyPercent);
    setEmployeeProductivityTimes(companyData.empProductivityTimes);
    setSubManager(companyData.subManager);
    setBm_cH_Name(companyData.chName);
    setCompanyTDSPercent(companyData.companyTDSPercent);
    setOpexamount(companyData.opexAmount);
  };
  // Email regex for validation
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  // Utility function to format the input
  const formatInputValue = (value) => {
    return value.replace(/^\s+|\s+$/g, " ").replace(/\s+/g, " ");
  };

  const handleEmailChange = (e) => {
    const email = e.target.value;
    setSelectedEmailID(email);
    // Validate the email while setting it
    if (!emailRegex.test(email)) {
      setAlert("Invalid email format", "error");
      setIsEmailValid(false); // Set validity to false if invalid
    } else {
      setAlert(null); // Clear alert if email is valid
      setIsEmailValid(true); // Set validity to true if valid
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <TableContainer component={Paper} sx={configStyles.mainTable}>
        <Dialog
          open={loading}
          onClose={() => setLoading(false)}
          aria-labelledby="loading-dialog-title"
        >
          <DialogContent style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <CircularProgress color="primary" />
            <Typography variant="h6" style={{ marginTop: "20px" }}>
              Loading, please wait...
            </Typography>
          </DialogContent>
        </Dialog>
        <Grid container spacing={2}>
          <Grid item xs={12} md={7}>
            <TableContainer component={Paper} sx={{ ...tableShadow }}>
              <div style={{ position: "relative", width: "100%" }}>
                <StyledTypography>
                  {!(isEditMode && apiPutStatus)
                    ? "Company Configuration"
                    : "Edit Company Configuration"}
                </StyledTypography>
                {isEditMode && (
                  <Tooltip title="Reset">
                    <StyledButtonC onClick={resetForm}>
                      <RestartAltIcon />
                    </StyledButtonC>
                  </Tooltip>
                )}
              </div>
              <form onSubmit={handleSave}>
                <StyledSnackbar
                  open={open}
                  autoHideDuration={5000}
                  onClose={handleClose}
                  anchorOrigin={{ vertical: "top", horizontal: "center" }}
                  severity={alertType}
                >
                  <StyledAlert onClose={handleClose} severity={alertType}>
                    {message}
                  </StyledAlert>
                </StyledSnackbar>
                <MDBox pt={3} pb={4} px={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                      <TextField
                        label="Company Name"
                        fullWidth
                        required
                        type="text"
                        value={selectedCompanyName}
                        onChange={(e) => setSelectedCompanyName(formatInputValue(e.target.value))}
                        inputProps={{
                          maxLength: 150, // Set the maximum character limit to 10
                        }}
                        sx={MInput.b}
                        InputLabelProps={{
                          sx: {
                            color: MInput.a,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        label="Email ID"
                        type="email"
                        fullWidth
                        required
                        value={selectedEmailID}
                        onChange={handleEmailChange} // Validate email while setting it
                        sx={MInput.b}
                        InputLabelProps={{
                          sx: {
                            color: MInput.a,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        label="Sub Manager Name"
                        fullWidth
                        required
                        value={subManager}
                        onChange={(e) => setSubManager(formatInputValue(e.target.value))}
                        inputProps={{
                          maxLength: 80, // Set the maximum character limit to 10
                        }}
                        sx={MInput.b}
                        InputLabelProps={{
                          shrink: subManager,
                          sx: {
                            color: MInput.a,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        label="Ch Name"
                        fullWidth
                        required
                        value={bm_cH_Name}
                        onChange={(e) => setBm_cH_Name(formatInputValue(e.target.value))}
                        inputProps={{
                          maxLength: 80, // Set the maximum character limit to 10
                        }}
                        sx={MInput.b}
                        InputLabelProps={{
                          shrink: bm_cH_Name,
                          sx: {
                            color: MInput.a,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        label="Street"
                        fullWidth
                        required
                        value={selectedStreet}
                        onChange={(e) => setSelectedStreet(formatInputValue(e.target.value))}
                        inputProps={{
                          maxLength: 80, // Set the maximum character limit to 10
                        }}
                        sx={MInput.b}
                        InputLabelProps={{
                          sx: {
                            color: MInput.a,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        label="City"
                        fullWidth
                        required
                        value={selectedCity}
                        onChange={(e) => setSelectedCity(formatInputValue(e.target.value))}
                        inputProps={{
                          maxLength: 80, // Set the maximum character limit to 10
                        }}
                        sx={MInput.b}
                        InputLabelProps={{
                          sx: {
                            color: MInput.a,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        label="State"
                        fullWidth
                        required
                        value={selectedState}
                        onChange={(e) => setSelectedState(formatInputValue(e.target.value))}
                        inputProps={{
                          maxLength: 80, // Set the maximum character limit to 10
                        }}
                        sx={MInput.b}
                        InputLabelProps={{
                          sx: {
                            color: MInput.a,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        label="Phone Number"
                        fullWidth
                        required
                        type="tel"
                        onChange={(e) => {
                          const phoneNumberPattern = /^[1-9]\d{9}$/; // Example for Indian numbers
                          const value = e.target.value;
                          setSelectedPhoneNumber(value);
                          setPhoneNumberError(!phoneNumberPattern.test(value));
                        }}
                        value={selectedPhoneNumber}
                        error={phoneNumberError} // Display error if phone number is invalid
                        helperText={phoneNumberError ? "Please enter a valid phone number" : ""}
                        inputProps={{
                          maxLength: 10, // Restrict to 10 digits for phone numbers
                          pattern: "[6-9]{1}[0-9]{9}",
                        }}
                        sx={MInput.b}
                        InputLabelProps={{
                          sx: {
                            color: MInput.a,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        label="Opex Amount"
                        variant="outlined"
                        value={opexamount}
                        onChange={handleOpexAmountChange}
                        fullWidth
                        InputProps={{
                          inputMode: "decimal", // Helps on mobile keyboards to show numeric pad
                          pattern: "\\d*\\.?\\d{0,2}", // HTML5 validation pattern for better UX
                        }}
                        sx={MInput.b}
                        InputLabelProps={{
                          sx: {
                            color: MInput.a,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        label="OPEX Percentage %"
                        fullWidth
                        required
                        value={OPEXPercentage}
                        onChange={(e) => {
                          const value = e.target.value;
                          // Regex to match the format YY.XXX or y.XXXX where YY < 100 and up to 3 decimal places
                          if (/^([0-9]{1,2}(\.\d{0,2})?)?$/.test(value)) {
                            setOPEXPercentage(value);
                          }
                        }}
                        sx={MInput.b}
                        InputLabelProps={{
                          sx: {
                            color: MInput.a,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        label="Company TDS %"
                        fullWidth
                        required
                        value={companyTDSPercent}
                        onChange={(e) => {
                          const value = e.target.value;
                          // Regex to match the format YY.XXX or y.XXXX where YY < 100 and up to 3 decimal places
                          if (/^([0-9]{1,2}(\.\d{0,2})?)?$/.test(value)) {
                            setCompanyTDSPercent(value);
                          }
                        }}
                        sx={MInput.b}
                        InputLabelProps={{
                          sx: {
                            color: MInput.a,
                          },
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} md={3}>
                      <TextField
                        label="Business loyalty %"
                        fullWidth
                        required
                        value={BusinessLoyalty}
                        onChange={(e) => {
                          const value = e.target.value;
                          // Regex to match the format YY.XXX or y.XXXX where YY < 100 and up to 3 decimal places
                          if (/^([0-9]{1,2}(\.\d{0,2})?)?$/.test(value)) {
                            setBusinessLoyalty(value);
                          }
                        }}
                        sx={MInput.b}
                        InputLabelProps={{
                          sx: {
                            color: MInput.a,
                          },
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} md={3}>
                      <TextField
                        label="TDS"
                        fullWidth
                        required
                        value={TDS}
                        onChange={(e) => {
                          const value = e.target.value;
                          // Regex to match the format YY.XXX or y.XXXX where YY < 100 and up to 3 decimal places
                          if (/^([0-9]{1,2}(\.\d{0,2})?)?$/.test(value)) {
                            setTDS(value);
                          }
                        }}
                        sx={MInput.b}
                        InputLabelProps={{
                          sx: {
                            color: MInput.a,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        label="Employee Productivity"
                        fullWidth
                        required
                        value={EmployeeProductivityTimes}
                        onChange={(e) => {
                          const value = e.target.value;
                          // Regex to match a single digit (0-9)
                          if (/^[0-9]?$/.test(value)) {
                            setEmployeeProductivityTimes(value);
                          }
                        }}
                        sx={MInput.b}
                        InputLabelProps={{
                          sx: {
                            color: MInput.a,
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                  <MDBox mt={3} mb={0} display="flex" justifyContent="flex-end">
                    <StyledButton variant="contained" type="submit">
                      Submit
                    </StyledButton>
                  </MDBox>
                </MDBox>
              </form>
            </TableContainer>
          </Grid>
          <Grid item xs={12} md={5} mt={0} sx={configStyles.table}>
            <div
              style={{
                position: "relative",
                borderRadius: "20px",
                width: "100%",
                ...tableShadow,
              }}
            >
              <StyledButtonCF onClick={handleEdit}>
                <EditIcon />
              </StyledButtonCF>
              <TableContainer component={Paper} sx={configStyles.tableContainer}>
                <Table>
                  <TableBody>
                    <TableRow>
                      <StyledTableCell
                        colSpan={2}
                        boxShadow={true}
                        align="center"
                        sx={{
                          position: "sticky",
                          top: 0, // Stick to the top
                          zIndex: 0, // Ensure it's above other rows while scrolling
                          backgroundColor: "#fff", // Match the background to avoid visual issues
                          boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)", // Add shadow for better visibility
                        }}
                      >
                        <strong>Company Configuration Data</strong>
                      </StyledTableCell>
                    </TableRow>
                    {rows
                      .filter(([key]) => key !== "companyId")
                      .map(([key, value], index) => (
                        <TableRow key={index}>
                          <StyledTableCell>
                            <strong>{formatKey(key)}</strong>
                          </StyledTableCell>
                          <StyledTableCell>{value}</StyledTableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </Grid>
        </Grid>
      </TableContainer>
    </DashboardLayout>
  );
};

export default CompanyConfiguration;
