import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types"; // For prop-types validation
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  TableContainer,
  Paper,
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  CircularProgress,
  Typography,
  Autocomplete,
  TextField,
} from "@mui/material";
import {
  ViewButton,
  StyledButton,
  StyledAlert,
  StyledSnackbar,
  StyledAgGridContainer,
  inputStyle,
  StyledDialogBoxTitle,
} from "components/StyledComponents/styledComponents.js";
import { useNavigate } from "react-router-dom";
import useBusinessMonthYear from "components/GlobalHooks/useMonthlyBusiness";
import { writeFile, utils } from "xlsx"; // Import XLSX for Excel export
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline"; // Download icon for the button
import { monthEndStyles } from "components/Styles/monthEndStyles";
import { CustomPaper } from "components/Styles/globalStyles";
import URLs from "constants/urls";
import Cookie from "js-cookie";
import dayjs from "dayjs";
export default function ConnectorWiseBusiness() {
  const { monthIdData, setAlert, handleClose } = useBusinessMonthYear();
  //   const [monthId, setMonthId] = useState(monthIdData[0].businessMonthID);
  const [monthId, setMonthId] = useState(0);
  const businessCodeRef = useRef(0);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [alertType, setAlertType] = useState("success");
  const [loading, setLoading] = useState(false);
  const [servicesData, setServicesData] = useState([]);
  const [openDailog, setOpenDailog] = useState(false);
  const [financeName, setFinanceName] = useState("");
  const MInput = inputStyle();
  // Set default values for startDate and endDate
  const currentMonthStart = dayjs().startOf("month").format("YYYY-MM-DD");
  const currentMonthEnd = dayjs().endOf("month").format("YYYY-MM-DD");
  const [rowData, setRowData] = useState([]);
  const [footerData, setFooterData] = useState([]);
  const [startDate, setStartDate] = useState(currentMonthStart);
  const [endDate, setEndDate] = useState(currentMonthEnd);
  const jwt_token = Cookie.get("jwt_token");
  const [providerBusinessData, setProviderBusinessData] = useState([]);
  const navigate = useNavigate();

  const fetchEntriesByMonthId = async () => {
    if (startDate && endDate) {
      const formattedStartDate = dayjs(startDate).format("YYYY-MM-DD");
      const formattedEndDate = dayjs(endDate).format("YYYY-MM-DD");
      setLoading(true);
      try {
        // Fetch entries by monthId
        const options = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwt_token}`,
          },
        };
        //   const response = await fetch(`${URLs.monthlyBusinessUrl}/${monthId}`, options);
        const response = await fetch(
          `${URLs.connectorWiseBusinesByDateRange}startDate=${formattedStartDate}&endDate=${formattedEndDate}`,
          options
        );
        const data = await response.json();
        if (response.ok) {
          setServicesData(data);
        } else {
          throw new Error("Failed to fetch entries data");
        }
      } catch (error) {
        showAlert(`Failed to fetch data: ${error.message}`, "error");
      } finally {
        setLoading(false);
      }
    } else {
      showAlert(`Please select both start and end dates`, "error");
    }
  };
  const showAlert = (msg, type) => {
    setMessage(msg);
    setAlertType(type);
    setOpen(true);
  };
  const handleClickOpenDailog = () => {
    setOpenDailog(true);
  };

  const handleCloseDailog = () => {
    setOpenDailog(false);
  };
  const handleCloseAlert = () => {
    setOpen(false);
  };
  const handleViewClick = async (rowData) => {
    const subDsaId = rowData.subDSAId;
    setFinanceName(rowData.dsaName);
    handleClickOpenDailog();
    if (startDate && endDate) {
      const formattedStartDate = dayjs(startDate).format("YYYY-MM-DD");
      const formattedEndDate = dayjs(endDate).format("YYYY-MM-DD");
      setLoading(true);
      try {
        const options = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwt_token}`,
          },
        };
        // 1&startDate=1&endDate=3
        const url = `${URLs.connectorWiseEntriesByDateRange}${subDsaId}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
        const response = await fetch(url, options);
        const data = await response.json();
        if (response.ok) {
          setProviderBusinessData(data);
        } else {
          throw new Error("Failed to fetch entries data");
        }
      } catch (error) {
        showAlert(`Failed to fetch data: ${error.message}`, "error");
      } finally {
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    if (monthIdData && monthIdData.length > 0) {
      // Set monthId only when monthIdData exists and is not empty
      setMonthId(monthIdData[0].businessMonthID);
      fetchEntriesByMonthId();
    }
  }, [monthIdData]); // Dependency on monthIdData

  const ActionCellRenderer = (props) => {
    const handleDownload = () => {
      if (!servicesData.length || !columns.length) return;

      // Extract headers from column definitions
      const headers = columns.map((col) => col.headerName);

      // Map rowData to match the headers
      const rowData = servicesData.map((row) => {
        const rowObject = {};
        columns.forEach((col) => {
          rowObject[col.headerName] = row[col.field] || ""; // Map field values to headers
        });
        return rowObject;
      });

      // Create a worksheet with headers and rowData
      const ws = utils.json_to_sheet(rowData, { header: headers });

      // Create a workbook and append the sheet
      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws, "EmployeeData");

      // Save the workbook
      writeFile(wb, "Monthly_Business_Connector_Data.xlsx");
    };

    return (
      <StyledButton onClick={handleDownload}>
        <DownloadForOfflineIcon fontSize="medium" />
      </StyledButton>
    );
  };
  function formatTimestampToDate(timestamp) {
    const date = new Date(timestamp); // Convert timestamp to Date object
    return date.toISOString().slice(0, 10); // Format as "YYYY-MM-DD"
  }
  const ActionCellRendererProviderBusiness = (props) => {
    const rowIndex = props.node.rowIndex;
    const rowData = props.api.getDisplayedRowAtIndex(rowIndex).data;
    return (
      <ViewButton type="button" onClick={() => handleViewClick(rowData)}>
        <VisibilityIcon />
      </ViewButton>
    );
  };
  ActionCellRendererProviderBusiness.propTypes = {
    node: PropTypes.shape({
      rowIndex: PropTypes.number.isRequired,
    }).isRequired,
    api: PropTypes.shape({
      getDisplayedRowAtIndex: PropTypes.func.isRequired,
    }).isRequired,
  };
  const columns = [
    // {
    //   headerName: "Connector ID",
    //   field: "subDSAId",
    //   filter: true,
    //   sorted: true,
    //   minWidth: 130,
    //   flex: 1,
    // },
    {
      headerName: "Connector Name",
      field: "dsaName",
      filter: true,
      sortable: true,
      minWidth: 200,
      flex: 2,
    },
    {
      headerName: "Dsa Revenue",
      field: "dsaRevenue",
      filter: true,
      sortable: true,
      minWidth: 170,
      flex: 1.5,
      cellStyle: { textAlign: "right", fontWeight: "500" },
      valueFormatter: (params) =>
        `₹ ${params.value.toLocaleString("en-IN", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`,
    },
    {
      headerName: "Connector Revenue",
      field: "grossConnectorAmount",
      filter: true,
      sortable: true,
      minWidth: 170,
      flex: 1.5,
      cellStyle: { textAlign: "right", fontWeight: "500" },
      valueFormatter: (params) =>
        `₹ ${params.value.toLocaleString("en-IN", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`,
    },
    {
      headerName: "TDS Amount",
      field: "totalSubTdsAmount",
      filter: true,
      sortable: true,
      minWidth: 200,
      flex: 1,
      cellStyle: { textAlign: "right", fontWeight: "500" },
      valueFormatter: (params) =>
        `₹ ${params.value.toLocaleString("en-IN", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`,
    },
    {
      headerName: "Revenue",
      field: "connectorFinalPayout",
      filter: true,
      sortable: true,
      minWidth: 200,
      flex: 1,
      cellStyle: { textAlign: "right", fontWeight: "500" },
      valueFormatter: (params) =>
        `₹ ${params.value.toLocaleString("en-IN", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`,
    },
    {
      headerName: "View",
      cellRenderer: ActionCellRendererProviderBusiness,
      width: 80,
    },
  ];
  const providerBusinessColumns = [
    {
      headerName: "Location",
      field: "city",
      filter: true,
      sorted: true,
      minWidth: 130,
      flex: 1,
    },
    {
      headerName: "Applicarion Number",
      field: "applicationNumber",
      filter: true,
      sorted: true,
      minWidth: 200,
      flex: 1,
    },
    {
      headerName: "Report Status",
      field: "remarks",
      filter: true,
      sorted: true,
      minWidth: 300,
      flex: 1,
    },
    {
      headerName: "Loan Type",
      field: "loanType",
      filter: true,
      sortable: true,
      minWidth: 120,
      flex: 1,
    },
    {
      headerName: "Status",
      field: "status",
      filter: true,
      sortable: true,
      minWidth: 150,
      flex: 1,
    },
    {
      headerName: "Customer Name",
      field: "customerName",
      filter: true,
      sortable: true,
      minWidth: 180,
      flex: 1.5,
    },
    {
      headerName: "Loan Amount",
      field: "loanAmount",
      filter: true,
      sortable: true,
      minWidth: 170,
      flex: 1.5,
      cellStyle: { textAlign: "right", fontWeight: "500" },
      valueFormatter: (params) => {
        const value = params.value ?? 0; // Use nullish coalescing to default to 0
        return `₹ ${value.toLocaleString("en-IN", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`;
      },
    },
    {
      headerName: "Source",
      field: "source",
      filter: true,
      sorted: true,
      minWidth: 120,
      flex: 1,
    },
    {
      headerName: "Revenue (%)",
      field: "revenuePercentage",
      filter: true,
      sortable: true,
      minWidth: 150,
      flex: 1.5,
      cellStyle: { textAlign: "right", fontWeight: "500" },
      valueFormatter: (params) =>
        params.value !== undefined && params.value !== null ? `${params.value.toFixed(2)}%` : "",
    },
    {
      headerName: "Revenue",
      field: "revenue",
      filter: true,
      sortable: true,
      minWidth: 230,
      flex: 1.5,
      cellStyle: { textAlign: "right", fontWeight: "500" },
      valueFormatter: (params) => {
        const value = params.value || 0; // Handle undefined or null values
        return `₹ ${value.toLocaleString("en-IN", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`;
      },
    },
    {
      headerName: "DSA Pay-out (%)",
      field: "dsaPayoutPercentage",
      filter: true,
      sortable: true,
      minWidth: 170,
      flex: 1.5,
      cellStyle: { textAlign: "right", fontWeight: "500" },
      valueFormatter: (params) =>
        params.value !== undefined && params.value !== null ? `${params.value.toFixed(2)}%` : "",
    },
    {
      headerName: "DSA Pay-out Value",
      field: "dsaPayoutValue",
      filter: true,
      sortable: true,
      minWidth: 200,
      flex: 1,
      cellStyle: { textAlign: "right", fontWeight: "500" },
      valueFormatter: (params) => {
        const value = params.value || 0; // Default to 0 if value is undefined or null
        return `₹ ${value.toLocaleString("en-IN", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`;
      },
    },
    {
      headerName: "Company Retention",
      field: "companyRetention",
      filter: true,
      sortable: true,
      minWidth: 200,
      flex: 1,
      cellStyle: { textAlign: "right", fontWeight: "500" },
      valueFormatter: (params) => {
        const value = params.value ?? 0; // Use nullish coalescing to default to 0 if value is null or undefined
        return `₹ ${value.toLocaleString("en-IN", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`;
      },
    },
    {
      headerName: "Retention (%)",
      field: "retentionPercentage",
      filter: true,
      sortable: true,
      minWidth: 150,
      flex: 1.5,
      cellStyle: { textAlign: "right", fontWeight: "500" },
      valueFormatter: (params) =>
        params.value !== undefined && params.value !== null ? `${params.value.toFixed(2)}%` : "",
    },
    {
      headerName: "DSA Name",
      field: "dsaName",
      filter: true,
      sortable: true,
      minWidth: 170,
      flex: 1,
    },
    {
      headerName: "Sub Manager",
      field: "subManager",
      filter: true,
      sorted: true,
      minWidth: 200,
      flex: 1,
    },
    {
      headerName: "BM/CH Name",
      field: "bmChName",
      filter: true,
      sorted: true,
      minWidth: 200,
      flex: 1,
    },
    {
      headerName: "EMP ID",
      field: "employeeCode",
      filter: true,
      sortable: true,
      minWidth: 150,
      flex: 1,
    },
    {
      headerName: "EMP NAME",
      field: "employeeName",
      filter: true,
      sortable: true,
      minWidth: 170,
      flex: 1.5,
    },
    {
      headerName: "BANK NAME",
      field: "bankName",
      filter: true,
      sortable: true,
      minWidth: 200,
      flex: 2,
    },
  ];
  useEffect(() => {
    setRowData(providerBusinessData); // Update rowData
    const calculateFooterData = () => {
      const totalLoanAmount = providerBusinessData.reduce(
        (sum, row) => sum + (row.loanAmount || 0),
        0
      );
      const totalRevenue = providerBusinessData.reduce((sum, row) => sum + (row.revenue || 0), 0);
      const totalDSAPayout = providerBusinessData.reduce(
        (sum, row) => sum + (row.dsaPayoutValue || 0),
        0
      );
      const totalRetention = providerBusinessData.reduce(
        (sum, row) => sum + (row.companyRetention || 0),
        0
      );

      setFooterData([
        {
          remarks: "Total",
          loanAmount: totalLoanAmount,
          revenue: totalRevenue,
          dsaPayoutValue: totalDSAPayout,
          companyRetention: totalRetention,
          footerRow: true,
          revenuePercentag: null,
          dsaPayoutPercentage: null,
          retentionPercentage: null,
        },
      ]);
    };
    calculateFooterData(); // Calculate footer data directly with servicesData
  }, [providerBusinessData]);
  const triggerApiCall = () => {
    businessCodeRef.current = monthId;
    fetchEntriesByMonthId();
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Dialog
        open={loading}
        onClose={() => setLoading(false)}
        aria-labelledby="loading-dialog-title"
      >
        <DialogContent style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <CircularProgress color="primary" />
          <Typography variant="h6" style={{ marginTop: "20px" }}>
            Loading, please wait...
          </Typography>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openDailog}
        onClose={handleCloseDailog}
        fullScreen
        sx={{
          ".MuiDialog-paper": {
            width: "90%", // 10% less than full width
            height: "90%", // 10% less than full height
            maxWidth: "none", // Remove the default max-width limit
            minWidth: "500px",
            margin: "auto", // Center the dialog
            borderRadius: "8px",
          },
        }}
      >
        <DialogTitle>
          <StyledDialogBoxTitle>Connector {financeName} Business</StyledDialogBoxTitle>
          <StyledButton
            edge="end"
            color="inherit"
            onClick={handleCloseDailog}
            aria-label="close"
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            Close
          </StyledButton>
        </DialogTitle>
        <DialogContent>
          <StyledAgGridContainer component={Paper} className={`ag-theme-alpine`}>
            <AgGridReact
              rowData={providerBusinessData}
              columnDefs={providerBusinessColumns}
              pagination={true}
              paginationPageSize={10}
              pinnedBottomRowData={footerData} // Add footer row
              // groupIncludeFooter={true}
              // groupIncludeTotalFooter={true}
              // suppressAggFuncInHeader={true}
              animateRows={true}
            />
          </StyledAgGridContainer>
        </DialogContent>
      </Dialog>
      <StyledSnackbar
        open={open}
        autoHideDuration={5000}
        onClose={handleCloseAlert}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        severity={alertType}
      >
        <StyledAlert onClose={handleCloseAlert} severity={alertType}>
          {message}
        </StyledAlert>
      </StyledSnackbar>
      <TableContainer component={Paper} sx={monthEndStyles.tableContainer}>
        <Box sx={monthEndStyles.formContainer}>
          <Box sx={monthEndStyles.formItem}>
            <TextField
              label="Start Date"
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              sx={{ ...MInput.b, maxWidth: "140px" }}
              InputLabelProps={{
                shrink: true,
                sx: {
                  color: MInput.a,
                },
              }}
            />
            <TextField
              label="End Date"
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              sx={{ ...MInput.b, maxWidth: "140px" }}
              InputLabelProps={{
                shrink: true,
                sx: {
                  color: MInput.a,
                },
              }}
            />
            <StyledButton onClick={triggerApiCall}>Get</StyledButton>
          </Box>
          {ActionCellRenderer()}
        </Box>
        <StyledAgGridContainer component={Paper} className={`ag-theme-alpine`}>
          <AgGridReact
            rowData={servicesData}
            columnDefs={columns}
            pagination={true}
            paginationPageSize={10}
          />
        </StyledAgGridContainer>
      </TableContainer>
    </DashboardLayout>
  );
}
