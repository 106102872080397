export const mdBoxStyle = {
  position: "absolute",
  width: "96%",
  padding: "1%",
  top: "-20px",
  left: "2%", // Adjust to center within the Dialog
  zIndex: "1000",
};
export const typoStyle = {
  sx: {
    fontSize: { xs: "0.875rem", sm: "1rem", md: "1rem" },
    padding: { xs: "4px", sm: "6px", md: "8px" },
  },
};
export const dialogStyles = {
  sx: {
    width: { xs: "95%", md: "95%", lg: "100%" },
    minHeight: "300px",
    position: "relative",
    padding: "0px",
    margin: "10px",
    minHeight: { xs: "400px", lg: "400px" }, // Increase height on lg screens
    overflow: "visible", // Ensure no clipping of the title box
  },
};
export const dialogContentStyle = {
  sx: {
    maxHeight: "100%",
    marginTop: "80px",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "4px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#888",
      borderRadius: "10px",
      boxShadow: "inset 0 0 5px rgba(0,0,0,0.3)",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "linear-gradient(180deg, #6a6a6a 0%, #3e3e3e 100%)",
    },
    scrollbarColor: "#888 #f1f1f1",
    scrollbarWidth: "thin",
  },
};

export const subDialogContentStyle = {
  padding: "10px",
  textAlign: "center",
  fontWeight: "bold",
};

export const dialogContentTextStyle = {
  fontSize: "1.1rem",
  fontWeight: 700,
  color: "black", // Light Purple
  lineHeight: "1.5",
};
