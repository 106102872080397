export const configStyles = {
  root: {
    height: "100vh",
    minHeight: "430px",
    maxHeight: "470px",
    marginTop: "10px",
    overflowY: "auto",
    padding: "15px",
  },
  tableContainer: {
    height: "95%",
    minWidth: "100%",
    borderRadius: "10px",
    overflowY: "auto",
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      width: "0px", // Initially hide scrollbar
      transition: "width 0.3s ease", // Smooth transition when hover occurs
    },
    "&:hover::-webkit-scrollbar": {
      width: "8px", // Show scrollbar on hover
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#888", // Color of the scrollbar thumb
      borderRadius: "4px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "#666", // Darker thumb on hover
      boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.5)",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent", // Transparent track
    },
  },
  mainTable: {
    display: "flex",
    width: "100%", // Full width
    height: "calc(100vh - 16.5vh)", // Full height minus header height
    minHeight: "400px", // Minimum height
    maxHeight: "800px", // Maximum height
    padding: "16px",
    marginTop: "16px",
    overflowY: "auto", // Allow vertical scrolling if content exceeds height
    overflowX: "hidden", // Hide horizontal scrolling
    "&::-webkit-scrollbar": {
      width: "8px", // Scrollbar width
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#888", // Scrollbar thumb color
      borderRadius: "4px", // Rounded scrollbar thumb
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "#666", // Darker color on hover
      boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.5)", // Subtle shadow effect
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent", // Transparent scrollbar track
    },
  },
  table: {
    borderRadius: "20px",
    minHeight: "200px",
    height: "100%",
    display: "flex",
  },
};
