import { useState, useEffect } from "react";
import URLs from "constants/urls"; // Make sure URLs is being used or remove it if not needed

export const useAccountsApi = (resetFormState) => {
  const [accounts, setAccounts] = useState([]);
  const [paymentAccounts, setPaymentAccounts] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [alertType, setAlertType] = useState("success");
  const [loading, setLoading] = useState(false);
  const [voucherData, setVoucherData] = useState({});

  const showAlert = (msg, type) => {
    setMessage(msg);
    setAlertType(type);
    setOpen(true);
  };

  const handleCloseAlert = () => {
    setOpen(false);
  };

  const fetchAllData = async () => {
    setLoading(true);
    try {
      const [accountsResponse, companiesResponse, paymentAccountsResponse] = await Promise.all([
        fetch(URLs.GetCashBankAccounts),
        fetch(URLs.GetCompanies),
        fetch(URLs.GetPaymentAccounts),
      ]);

      if (!accountsResponse.ok) {
        throw new Error(`Failed to fetch accounts: ${accountsResponse.statusText}`);
      }
      if (!companiesResponse.ok) {
        throw new Error(`Failed to fetch companies: ${companiesResponse.statusText}`);
      }
      if (!paymentAccountsResponse.ok) {
        throw new Error(`Failed to fetch payment accounts: ${paymentAccountsResponse.statusText}`);
      }

      const accountsData = await accountsResponse.json();
      const companiesData = await companiesResponse.json();
      const paymentAccountsData = await paymentAccountsResponse.json();

      setAccounts(Array.isArray(accountsData) ? accountsData : []);
      setCompanies(Array.isArray(companiesData) ? companiesData : []);
      setPaymentAccounts(Array.isArray(paymentAccountsData) ? paymentAccountsData : []);
    } catch (error) {
      showAlert("Error fetching data: " + error.message, "error");
      setAccounts([]); // Fallback to empty array on error
      setCompanies([]); // Fallback to empty array on error
      setPaymentAccounts([]); // Fallback to empty array on error
    }
    setLoading(false);
  };

  const manageVouchersApi = async (payload, fromDate, toDate, Company, seachTypeForGet) => {
    setLoading(true);
    try {
      const response = await fetch(URLs.PostVoucherEntry, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      if (!response.ok) {
        const errorText = await response.text(); // Get response text
        throw new Error(`Failed to post voucher entry: ${errorText}`);
      }

      const result = await response.json(); // Parse JSON response

      if (payload.RequestType === "ADD") {
        if (result.PrintFormat) {
          const newWindow = window.open("", "_blank");
          newWindow.document.open();
          newWindow.document.write(result.PrintFormat);
          newWindow.document.close();
        }
      } else {
        showAlert("Voucher Updated", "success");
      }
      const pyload = {
        companyId: Company,
        fromDate,
        toDate,
        searchType: seachTypeForGet,
      };
      resetFormState();
      fetchListOfVouchers(pyload);
    } catch (error) {
      // Enhanced error message with server-provided text
      showAlert(error.message, "error");
    } finally {
      // Ensure these actions are always performed
      setIsEditMode(false);
      setLoading(false);
    }
  };

  const handleDeleteVoucher = async (voucherID, cid, fromDate, toDate, Company) => {
    setLoading(true);
    const payload = {
      companyId: cid,
      voucherID: voucherID,
    };

    try {
      const response = await fetch(URLs.GetJVItem, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch voucher details for deletion: ${response.statusText}`);
      }

      const data = await response.json();

      // Directly call deleteVoucher with fetched data
      await deleteVoucher(data, fromDate, toDate, Company);
    } catch (error) {
      showAlert("Error fetching voucher details before deletion: " + error.message, "error");
    }
    setLoading(false);
  };

  const deleteVoucher = async (data, fromDate, toDate, Company) => {
    const payload = {
      ...data,
      RequestType: "DELETE",
    };
    setLoading(true);
    try {
      const response = await fetch(URLs.PostVoucherEntry, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        const errorMessage = `Failed to delete voucher: ${response.statusText}`;
        showAlert(errorMessage, "error");
        return; // Exit the function to prevent further execution
      }
      showAlert("Voucher deleted successfully.", "success");
      const pyload = {
        companyId: Company,
        fromDate,
        toDate,
        searchType: "PMT",
      };
      fetchListOfVouchers(pyload);
    } catch (error) {
      showAlert("Error deleting voucher: " + error.message, "error");
    }
    setLoading(false);
  };

  const fetchListOfVouchers = async (payload) => {
    setLoading(true);
    try {
      const response = await fetch(URLs.GetVoucherEntryList, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch vouchers: ${response.statusText}`);
      }

      const data = await response.json();
      const formattedData = data.map((item) => ({
        Date: item.entryDate,
        VoucherNo: item.voucherClientID,
        Particulars: item.particulars,
        Amount: item.amount,
        voucherID: item.voucherID,
        companyId: item.companyId,
      }));

      setTableData(formattedData);
    } catch (error) {
      showAlert("Error fetching vouchers: " + error.message, "error");
    }
    setLoading(false);
  };

  const getVoucherApi = async (payload) => {
    setLoading(true); // Start loading before the API call
    try {
      const response = await fetch(URLs.GetJVItem, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch voucher details: ${response.statusText}`);
      }

      const data = await response.json();
      setVoucherData(data); // Store fetched data
      return data; // Return the fetched data
    } catch (error) {
      showAlert("Error fetching voucher details: " + error.message, "error");
      throw error; // Rethrow error to handle it in the caller
    } finally {
      setLoading(false); // Ensure loading state is turned off after the request, no matter the outcome
    }
  };

  useEffect(() => {
    fetchAllData();
  }, []);

  return {
    companies,
    accounts,
    paymentAccounts,
    tableData,
    voucherData,
    isEditMode,
    open,
    setOpen,
    showAlert,
    message,
    alertType,
    loading,
    setLoading,
    handleDeleteVoucher,
    getVoucherApi,
    setIsEditMode,
    fetchListOfVouchers,
    manageVouchersApi,
    handleCloseAlert,
  };
};
