import React, { useState, useRef, useEffect } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { useNavigate } from "react-router-dom";
import {
  TableContainer,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
  CircularProgress,
  Typography,
  Autocomplete,
  TextField,
} from "@mui/material";
import {
  StyledButton,
  StyledAlert,
  StyledSnackbar,
  StyledAgGridContainer,
  inputStyle,
} from "components/StyledComponents/styledComponents.js";
import { writeFile, utils } from "xlsx"; // Import XLSX for Excel export
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline"; // Download icon for the button
import { monthEndStyles } from "components/Styles/monthEndStyles";
import { selectScrollStyle } from "components/Styles/globalStyles";
import { tableShadow, globalStyles, CustomPaper } from "components/Styles/globalStyles";
import useDsaAndEmployeeBusinessApi from "components/GlobalHooks/useMonthlyDsa&EmployeeBusiness";
import useBusinessMonthYear from "components/GlobalHooks/useMonthlyBusiness";
import URLs from "constants/urls";
import Cookie from "js-cookie";
export default function MonthlyEmployeeBusiness() {
  const { monthIdData } = useBusinessMonthYear();
  const [monthId, setMonthId] = useState(0);
  const [employeeId, setEmployeeId] = useState(0);
  const businessCodeRef = useRef(0);
  const employeeIdRef = useRef(0);
  const MInput = inputStyle();
  const navigate = useNavigate();
  const {
    loading,
    message,
    alertType,
    servicesData,
    employeeData,
    open,
    handleClose,
    setLoading,
    setAlert,
    setOpen,
    setMessage,
    fetchEmployeeBusinessByMonthId,
  } = useDsaAndEmployeeBusinessApi("employee");
  const handleCloseAlert = () => {
    setOpen(false);
  };
  useEffect(() => {
    if (monthIdData?.length > 0 && employeeData?.length > 0) {
      const mId = monthIdData[0].businessMonthID;
      const eId = employeeData[0].employeeId;
      setMonthId(mId);
      setEmployeeId(eId);
      fetchEmployeeBusinessByMonthId(mId, eId);
    }
  }, [monthIdData, employeeData]);
  useEffect(() => {
    employeeIdRef.current = employeeId;
    businessCodeRef.current = monthId;
  }, [monthId, employeeId]); // Add dependencies for monthId and dsaId

  const ActionCellRenderer = (props) => {
    const handleDownload = () => {
      if (!servicesData.length) return;
      const wb = utils.book_new();
      const ws = utils.json_to_sheet(servicesData); // Convert the entire rowData to a sheet
      utils.book_append_sheet(wb, ws, "EmployeeData");
      writeFile(wb, "Montlhy_Employee_Business_entries_Data.xlsx"); // Save the workbook
    };

    return (
      <StyledButton onClick={handleDownload}>
        <DownloadForOfflineIcon fontSize="medium" />
      </StyledButton>
    );
  };
  const columns = [
    {
      headerName: "Date",
      field: "date",
      filter: true,
      sortable: true,
      minWidth: 150,
      flex: 1,
      valueFormatter: (params) => {
        const date = new Date(params.value); // Convert the timestamp to a Date object
        const day = date.getDate().toString().padStart(2, "0"); // Get day with leading zero
        const month = date.toLocaleString("default", { month: "short" }); // Get month as short name
        const year = date.getFullYear(); // Get full year
        return `${day}-${month}-${year}`; // Return in "dd-MMM-yyyy" format
      },
    },
    {
      headerName: "Current Status",
      field: "currentStatus",
      filter: true,
      sortable: true,
      minWidth: 150,
      flex: 1,
    },
    {
      headerName: "Appl. No.",
      field: "applicatioNumber",
      filter: true,
      sorted: true,
      minWidth: 120,
      flex: 1,
    },
    {
      headerName: "Business Name",
      field: "businessName",
      filter: true,
      sortable: true,
      minWidth: 150,
      flex: 1,
    },
    {
      headerName: "Provider Name",
      field: "financialInstitutionName",
      filter: true,
      sortable: true,
      minWidth: 200,
      flex: 2,
    },
    {
      headerName: "Customer Name",
      field: "customerName",
      filter: true,
      sortable: true,
      minWidth: 170,
      flex: 1.5,
    },
    {
      headerName: "Main DSA",
      field: "mainDSA",
      filter: true,
      sortable: true,
      minWidth: 150,
      flex: 1,
    },
    {
      headerName: "Sales Manager",
      field: "salesManager",
      filter: true,
      sortable: true,
      minWidth: 170,
      flex: 1.5,
    },
    {
      headerName: "DSA Sales Manager",
      field: "dsaSalesManager",
      filter: true,
      sortable: true,
      minWidth: 200,
      flex: 1.5,
      valueGetter: (params) => (params.data.dsaSalesManager ? params.data.dsaSalesManager : "N/A"),
    },
    {
      headerName: "Sub DSA Sales Manager",
      field: "subDSA",
      filter: true,
      sortable: true,
      minWidth: 150,
      flex: 1,
      valueGetter: (params) => (params.data.subDSA ? params.data.subDSA : "N/A"),
    },
    {
      headerName: "Applied Amount",
      field: "appliedAmount",
      filter: true,
      sortable: true,
      minWidth: 170,
      flex: 1.5,
      cellStyle: { textAlign: "right", fontWeight: "500" },
      valueFormatter: (params) =>
        `₹ ${params.value.toLocaleString("en-IN", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`,
    },
    {
      headerName: "Disbursed Amount",
      field: "disbursedAmount",
      filter: true,
      sortable: true,
      minWidth: 170,
      flex: 1.5,
      cellStyle: { textAlign: "right", fontWeight: "500" },
      valueFormatter: (params) =>
        `₹ ${params.value.toLocaleString("en-IN", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`,
    },
    {
      headerName: "Gross Payout (%)",
      field: "grossPayoutPercent",
      filter: true,
      sortable: true,
      minWidth: 180,
      flex: 1.5,
      cellStyle: { textAlign: "right", fontWeight: "500" },
      valueFormatter: (params) =>
        params.value !== undefined && params.value !== null ? `${params.value.toFixed(2)}%` : "0",
    },
    {
      headerName: "Gross Payout Amount",
      field: "grossPayoutAmount",
      filter: true,
      sortable: true,
      minWidth: 230,
      flex: 1.5,
      cellStyle: { textAlign: "right", fontWeight: "500" },
      valueFormatter: (params) =>
        `₹ ${params.value.toLocaleString("en-IN", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`,
    },
    {
      headerName: "Final Gross Payout Amount",
      field: "finalGrossPayoutAmount",
      filter: true,
      sortable: true,
      minWidth: 250,
      flex: 1.5,
      cellStyle: { textAlign: "right", fontWeight: "500" },
      valueFormatter: (params) =>
        `₹ ${params.value.toLocaleString("en-IN", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`,
    },
    {
      headerName: "DSA Payout (%)",
      field: "dsaPayoutPercent",
      filter: true,
      sortable: true,
      minWidth: 170,
      flex: 1.5,
      cellStyle: { textAlign: "right", fontWeight: "500" },
      valueFormatter: (params) =>
        params.value !== undefined && params.value !== null ? `${params.value.toFixed(2)}%` : "0",
    },
    {
      headerName: "DSA Payout Amount",
      field: "dsaPayoutAmount",
      filter: true,
      sortable: true,
      minWidth: 200,
      flex: 1,
      cellStyle: { textAlign: "right", fontWeight: "500" },
      valueFormatter: (params) =>
        `₹ ${params.value.toLocaleString("en-IN", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`,
    },
    {
      headerName: "Final DSA Gross Payout Amount",
      field: "finalDsaGrossPayoutAmount",
      filter: true,
      sortable: true,
      minWidth: 250,
      flex: 1.5,
      cellStyle: { textAlign: "right", fontWeight: "500" },
      valueFormatter: (params) =>
        `₹ ${params.value.toLocaleString("en-IN", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`,
    },
    {
      headerName: "Sub DSA Payout (%)",
      field: "subDsaPayoutPercent",
      filter: true,
      sortable: true,
      minWidth: 200,
      flex: 1.5,
      cellStyle: { textAlign: "right", fontWeight: "500" },
      valueFormatter: (params) =>
        params.value !== undefined && params.value !== null ? `${params.value.toFixed(2)}%` : "0",
    },
    {
      headerName: "Sub DSA Payment Amount",
      field: "subDsaPaymentAmount",
      filter: true,
      sortable: true,
      minWidth: 250,
      flex: 1.5,
      cellStyle: { textAlign: "right", fontWeight: "500" },
      valueFormatter: (params) =>
        `₹ ${
          params.value
            ? params.value.toLocaleString("en-IN", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            : "0.00"
        }`,
    },
    {
      headerName: "Manager",
      field: "salesManager",
      filter: true,
      sortable: true,
      minWidth: 150,
      flex: 1.5,
    },
  ];
  const menuProps = {
    PaperProps: {
      sx: {
        ...selectScrollStyle,
      },
    },
  };

  const triggerApiCall = () => {
    fetchEmployeeBusinessByMonthId(businessCodeRef.current, employeeIdRef.current);
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Dialog
        open={loading}
        onClose={() => setLoading(false)}
        aria-labelledby="loading-dialog-title"
      >
        <DialogContent style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <CircularProgress color="primary" />
          <Typography variant="h6" style={{ marginTop: "20px" }}>
            Loading, please wait...
          </Typography>
        </DialogContent>
      </Dialog>
      <StyledSnackbar
        open={open}
        autoHideDuration={5000}
        onClose={handleCloseAlert}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        severity={alertType}
      >
        <StyledAlert onClose={handleCloseAlert} severity={alertType}>
          {message}
        </StyledAlert>
      </StyledSnackbar>
      <TableContainer component={Paper} sx={monthEndStyles.tableContainer}>
        <Box sx={monthEndStyles.formContainer}>
          <Box sx={monthEndStyles.formItem}>
            {/* Autocomplete for Date */}
            <Autocomplete
              options={monthIdData}
              getOptionLabel={(option) => option.date || ""}
              value={monthIdData.find((data) => data.businessMonthID === monthId) || null}
              onChange={(event, newValue) => {
                if (newValue) {
                  setMonthId(newValue.businessMonthID);
                }
              }}
              filterOptions={(options, state) => {
                const inputValue = state.inputValue.toLowerCase();

                const startsWithInput = options.filter((option) =>
                  option.date.toLowerCase().startsWith(inputValue)
                );

                const containsInput = options.filter(
                  (option) =>
                    !option.date.toLowerCase().startsWith(inputValue) &&
                    option.date.toLowerCase().includes(inputValue)
                );

                return [...startsWithInput, ...containsInput];
              }}
              PaperComponent={CustomPaper}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Date"
                  variant="outlined"
                  required
                  sx={{
                    height: 42,
                    ...MInput.b,
                    ...monthEndStyles.yearField,
                    "& .MuiInputBase-root": {
                      height: "100%",
                      minHeight: 42,
                      width: "160px",
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      color: MInput.a, // Ensure this applies to the label
                      "&.Mui-focused": {
                        color: "red", // Focused label color
                      },
                    },
                  }}
                />
              )}
              isOptionEqualToValue={(option, value) =>
                option.businessMonthID === value.businessMonthID
              }
            />

            {/* Autocomplete for Employee Name */}
            <Autocomplete
              options={employeeData}
              getOptionLabel={(option) =>
                `${option.employeeFirstName} ${option.employeeLastName}` || ""
              }
              value={employeeData.find((employee) => employee.employeeId === employeeId) || null}
              onChange={(event, newValue) => {
                if (newValue) {
                  setEmployeeId(newValue.employeeId);
                }
              }}
              filterOptions={(options, state) => {
                const inputValue = state.inputValue.toLowerCase();

                const startsWithInput = options.filter((option) =>
                  `${option.employeeFirstName} ${option.employeeLastName}`
                    .toLowerCase()
                    .startsWith(inputValue)
                );

                const containsInput = options.filter(
                  (option) =>
                    !`${option.employeeFirstName} ${option.employeeLastName}`
                      .toLowerCase()
                      .startsWith(inputValue) &&
                    `${option.employeeFirstName} ${option.employeeLastName}`
                      .toLowerCase()
                      .includes(inputValue)
                );

                return [...startsWithInput, ...containsInput];
              }}
              PaperComponent={CustomPaper}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Employee Name"
                  variant="outlined"
                  required
                  sx={{
                    height: 43,
                    ...MInput.b,
                    "& .MuiInputBase-root": {
                      height: "100%",
                      // ...monthEndStyles.nameField,
                      minHeight: 43,
                      width: "20vw",
                      maxWidth: "300px",
                      minWidth: "200px",
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      color: MInput.a, // Ensure this applies to the label
                      "&.Mui-focused": {
                        color: "red", // Focused label color
                      },
                    },
                  }}
                />
              )}
              isOptionEqualToValue={(option, value) => option.employeeId === value.employeeId}
            />

            <StyledButton onClick={triggerApiCall}>Get</StyledButton>
          </Box>

          {ActionCellRenderer()}
        </Box>
        <StyledAgGridContainer component={Paper} className={`ag-theme-alpine`}>
          <AgGridReact
            rowData={servicesData}
            columnDefs={columns}
            pagination={true}
            paginationPageSize={10}
          />
        </StyledAgGridContainer>
      </TableContainer>
    </DashboardLayout>
  );
}
