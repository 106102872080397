import React, { useState, useRef, useEffect, useCallback } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useTheme } from "@mui/material/styles";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS
import "ag-grid-community/styles/ag-theme-alpine.css"; // Alpine theme CSS
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import PropTypes from "prop-types";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PendingIcon from "@mui/icons-material/Pending";
import PaymentIcon from "@mui/icons-material/Payment";
import Cookie from "js-cookie";
import {
  Grid,
  MenuItem,
  TableContainer,
  Paper,
  Box,
  Select,
  TextField,
  InputLabel,
  FormControl,
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
  CircularProgress,
  Typography,
  Tooltip,
  Autocomplete,
  InputAdornment,
} from "@mui/material";
import MDBox from "components/MDBox";
import {
  ViewButton,
  DeleteButton,
  StyledTypography,
  StyledButton,
  StyledButtonC,
  StyledAlert,
  StyledSnackbar,
  StyledIconButton,
  inputStyle,
  StyledAgGridContainer,
} from "components/StyledComponents/styledComponents.js";
import { subDialogContentStyle, dialogContentTextStyle } from "components/Styles/approvalStyles";
import { dsaStyles } from "components/Styles/dsaAdvanceFormStyle";
import { selectScrollStyle, CustomPaper, tableStyles } from "components/Styles/globalStyles";
import { tableShadow } from "components/Styles/globalStyles";
import useBusinessMonthYear from "components/GlobalHooks/useMonthlyBusiness";
import useDsaAndEmployeeBusinessApi from "components/GlobalHooks/useMonthlyDsa&EmployeeBusiness";
import { globalStyles } from "components/Styles/globalStyles";
import URLs from "constants/urls";

export default function EntryAdvance() {
  const [editStatus, setEditStatus] = useState(false);
  const [businessMonthId, setBusinessMonthId] = useState(0);
  const [remarks, setRemarks] = useState();
  const [amount, setAmount] = useState("");
  const [bankAccount, setBankAccount] = useState("");
  const [paymentMode, setPaymentMode] = useState("");
  const { monthIdData } = useBusinessMonthYear();
  const [monthId, setMonthId] = useState(0);
  const [dsaId, setDsaId] = useState(0);
  const businessCodeRef = useRef(0);
  const dsaIdref = useRef(0);
  const [selectedData, setSelectedData] = useState({});
  const MInput = inputStyle();
  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    if (!/^\d*\.?\d*$/.test(inputValue)) {
      setAlert("Please enter a valid number", "error"); // Custom alert function
      return;
    }
    setAmount(inputValue);
  };
  // Format the amount using toLocaleString for display
  const formattedAmount = amount;
  const {
    dsaData,
    loading,
    message,
    alertType,
    servicesData,
    open,
    handleClose,
    setLoading,
    accountsData,
    fetchCashBankAccounts,
    setAlert,
    setOpen,
    setMessage,
    fetchEntriesByMonthId,
    updateAdvanceDetails,
    postAdvanceDetails,
    fetchSelfDsaEntriesData,
  } = useDsaAndEmployeeBusinessApi("subDsa");
  useEffect(() => {
    if (monthIdData.length > 0) {
      businessCodeRef.current = monthIdData[0].businessMonthID;
      setMonthId(businessCodeRef.current);
      fetchSelfDsaEntriesData(businessCodeRef.current, dsaId);
    }
  }, [monthIdData]);
  console.log("Accccc", accountsData);
  useEffect(() => {
    document.querySelectorAll("input").forEach((input) => {
      input.autocomplete = "off";
    });
  }, []);
  useEffect(() => {
    fetchCashBankAccounts();
  }, []);

  const menuProps = {
    PaperProps: {
      sx: {
        ...selectScrollStyle,
      },
    },
  };
  const resetAdvanceForm = () => {
    setSelectedData({});
    setEditStatus(false);
    setAmount("");
    setRemarks("");
  };
  function formatTimestampToDate(timestamp) {
    const date = new Date(timestamp); // Convert timestamp to Date object
    return date.toISOString().slice(0, 10); // Format it as "YYYY-MM-DD"
  }

  const handleBankAccountChange = (newValue) => {
    setBankAccount(newValue);
  };

  const handlePaymentModeChange = (event) => {
    setPaymentMode(event.target.value);
  };

  const ActionCellRenderer = (props) => {
    const rowIndex = props.node.rowIndex;
    let rowData = null;

    if (props.api && props.api.getDisplayedRowAtIndex(rowIndex)) {
      rowData = props.api.getDisplayedRowAtIndex(rowIndex).data;
    } else {
      console.error("API or row data is not available.");
    }

    const handleEditClick = () => {
      if (rowData) {
        console.log(rowData);
        setEditStatus(true);
        setSelectedData(rowData);
        setAmount(rowData.advancePayment);
        setRemarks(rowData.advanceRemarks);
        // setBankAccount(rowData.paymentAccountId);
      } else {
        console.warn("Row data is not available for editing.");
      }
    };

    // Move text declaration inside the renderStatusIcon function
    const renderStatusIcon = () => {
      if (!rowData) return null;

      let iconColor; // Define a variable for icon color
      let icon; // Define a variable for the icon
      let statusText; // Define a variable for status text

      switch (rowData.currentStatus) {
        case "N":
          icon = <PendingIcon />; // Replace with the pending icon you want to use
          iconColor = "#FFA500"; // Orange for Pending
          statusText = "New";
          break;
        case "D":
          icon = <CheckCircleIcon />;
          iconColor = "#4CAF50";
          statusText = "Disbursed";
          break;
        case "R":
          icon = <PaymentIcon />;
          iconColor = "#1da1f2"; // Blue for Money Received
          statusText = "Payout Received";
          break;
        case "C":
          icon = <PaymentIcon />;
          iconColor = "#ff4d4d"; // Red for Commission Paid
          statusText = "Paid to DSA";
          break;
        case "S":
          icon = <PaymentIcon />;
          iconColor = "#ff9800";
          statusText = "Paid to  Sub DSA";
          break;
        default:
          icon = null; // Or a default icon if needed
          iconColor = "#000"; // Default color if no status matches
          statusText = "Unknown Status"; // Optional: default text
          break;
      }
      return {
        icon: (
          <StyledIconButton type="button" color={iconColor}>
            {icon}
          </StyledIconButton>
        ),
        text: statusText, // Return the text along with the icon
      };
    };
    const { icon, text } = renderStatusIcon(); // Destructure icon and text
    return (
      <div>
        <Tooltip title={text}>{icon}</Tooltip>
        <ViewButton type="button" onClick={handleEditClick}>
          <EditIcon />
        </ViewButton>
      </div>
    );
  };
  const triggerApiCall = () => {
    businessCodeRef.current = monthId;
    dsaIdref.current = dsaId;
    fetchSelfDsaEntriesData(monthId, dsaId);
  };
  const columns = [
    {
      headerName: "Date",
      field: "date",
      filter: true,
      sorted: true,
      width: 100,
      flex: 1,
      valueFormatter: (params) => {
        const date = new Date(params.value); // Convert the timestamp to a Date object
        const day = date.getDate().toString().padStart(2, "0"); // Get day with leading zero
        const month = date.toLocaleString("default", { month: "short" }); // Get month as short name
        const year = date.getFullYear(); // Get full year
        return `${day}-${month}-${year}`; // Return in "dd-MMM-yyyy" format
      },
    },
    {
      headerName: "Appl. No.",
      field: "applicatioNumber",
      filter: true,
      sorted: true,
      minWidth: 140,
      flex: 1,
    },
    {
      headerName: "Loan Amount",
      field: "appliedAmount",
      filter: true,
      sortable: true,
      minWidth: 160,
      flex: 1,
      cellStyle: { textAlign: "right", fontWeight: "500" }, // Aligns the text to the right
      valueFormatter: (params) =>
        `₹ ${params.value.toLocaleString("en-IN", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`, // Formats and rounds to two decimals
    },
    // {
    //   headerName: "Sub DSA",
    //   field: "subDSA",
    //   filter: true,
    //   sorted: true,
    //   minWidth: 170,
    //   flex: 1,
    // },
    // {
    //   headerName: "Main DSA",
    //   field: "mainDSA",
    //   filter: true,
    //   sorted: true,
    //   minWidth: 160,
    //   flex: 1,
    // },
    { headerName: "Actions", cellRenderer: ActionCellRenderer, width: 100 },
  ];
  const getCurrentDate = () => new Date().toISOString().split("T")[0];
  const handleSave = (event) => {
    event.preventDefault();
    const postOrPutData = {
      paymentId: selectedData.paymentId || 0,
      voucherId: 0,
      companyID: selectedData.dsa_Id,
      paymentAccountId: bankAccount,
      entryId: selectedData.entryId,
      date: getCurrentDate(),
      advancePayment: parseFloat(amount),
      remarks: remarks,
    };
    if (selectedData.paymentId > 0) {
      updateAdvanceDetails(selectedData.paymentId, postOrPutData, resetAdvanceForm, monthId, dsaId);
    } else {
      postAdvanceDetails(postOrPutData, resetAdvanceForm, monthId, dsaId);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <TableContainer component={Paper} sx={globalStyles.accountsTable}>
        <Box sx={dsaStyles.card}>
          <FormControl>
            <Autocomplete
              options={monthIdData}
              getOptionLabel={(option) => option.date}
              value={
                monthIdData.some((item) => item.businessMonthID === monthId)
                  ? monthIdData.find((item) => item.businessMonthID === monthId)
                  : null
              }
              onChange={(event, value) => setMonthId(value ? value.businessMonthID : 0)}
              disableClearable={false}
              filterOptions={(options, state) => {
                const input = state.inputValue.toLowerCase();

                // Filter options that start with the input value
                const startsWithInput = options.filter((option) =>
                  option.date.toLowerCase().startsWith(input)
                );

                // Filter options that contain the input value but do not start with it
                const containsInput = options.filter(
                  (option) =>
                    !option.date.toLowerCase().startsWith(input) &&
                    option.date.toLowerCase().includes(input)
                );

                // Combine the options: ones that start with the input first, then the rest
                return [...startsWithInput, ...containsInput];
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Date"
                  variant="outlined"
                  required
                  sx={{
                    height: 42,
                    ...MInput.b,
                    ...dsaStyles.yearField,
                    "& .MuiInputBase-root": {
                      height: "100%",
                      minHeight: 42,
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      color: MInput.a, // Ensure this applies to the label
                      "&.Mui-focused": {
                        color: "red", // Focused label color
                      },
                    },
                  }}
                />
              )}
              PaperComponent={CustomPaper}
              isOptionEqualToValue={(option, value) =>
                option.businessMonthID === value.businessMonthID
              }
              disableCloseOnSelect
            />
          </FormControl>

          <FormControl>
            <Autocomplete
              options={dsaData}
              getOptionLabel={(option) => option.dsaName}
              value={
                dsaData.some((item) => item.dsaId === dsaId)
                  ? dsaData.find((item) => item.dsaId === dsaId)
                  : null
              }
              onChange={(event, value) => setDsaId(value ? value.dsaId : 0)}
              disableClearable={false}
              filterOptions={(options, state) => {
                const input = state.inputValue.toLowerCase();

                // Filter options that start with the input value
                const startsWithInput = options.filter((option) =>
                  option.dsaName.toLowerCase().startsWith(input)
                );

                // Filter options that contain the input value but do not start with it
                const containsInput = options.filter(
                  (option) =>
                    !option.dsaName.toLowerCase().startsWith(input) &&
                    option.dsaName.toLowerCase().includes(input)
                );

                // Combine the options: ones that start with the input first, then the rest
                return [...startsWithInput, ...containsInput];
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Dsa Name"
                  variant="outlined"
                  required
                  sx={{
                    height: 43,
                    ...MInput.b,
                    ...dsaStyles.nameField,
                    "& .MuiInputBase-root": {
                      height: "100%",
                      minHeight: 42,
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      color: MInput.a, // Ensure this applies to the label
                      "&.Mui-focused": {
                        color: "red", // Focused label color
                      },
                    },
                  }}
                />
              )}
              PaperComponent={CustomPaper}
              isOptionEqualToValue={(option, value) => option.dsaId === value.dsaId}
              disableCloseOnSelect
            />
          </FormControl>
          <StyledButton onClick={triggerApiCall}>Get</StyledButton>
        </Box>
        <Dialog
          open={loading}
          onClose={() => setLoading(false)}
          aria-labelledby="loading-dialog-title"
        >
          <DialogContent style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <CircularProgress color="primary" />
            <Typography variant="h6" style={{ marginTop: "20px" }}>
              Loading, please wait...
            </Typography>
          </DialogContent>
        </Dialog>
        <Grid container spacing={2} sx={dsaStyles.mainCard}>
          <Grid item xs={12} md={7}>
            <TableContainer component={Paper} sx={{ ...tableShadow, ...dsaStyles.marginStyle1 }}>
              <div style={dsaStyles.divStyle}>
                <StyledTypography>
                  {!editStatus ? "Business Advance Form" : "Edit Business Advance"}
                </StyledTypography>
                {editStatus && (
                  <Tooltip title="Reset">
                    <StyledButtonC onClick={resetAdvanceForm}>
                      <RestartAltIcon />
                    </StyledButtonC>
                  </Tooltip>
                )}
              </div>
              <StyledSnackbar
                open={open}
                autoHideDuration={5000}
                onClose={handleClose}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                severity={alertType}
              >
                <StyledAlert onClose={handleClose} severity={alertType}>
                  {message}
                </StyledAlert>
              </StyledSnackbar>
              <form onSubmit={handleSave} autoComplete="off">
                <MDBox pt={1} pb={2} px={3}>
                  <Grid container spacing={2} pt={2}>
                    <Grid item xs={12} md={3}>
                      <TextField
                        label="Loan Id"
                        fullWidth
                        value={selectedData.entryId || ""}
                        InputProps={{
                          readOnly: true,
                        }}
                        sx={MInput.b}
                        inputProps={{ autoComplete: "off" }}
                        InputLabelProps={{
                          shrink: selectedData.entryId,
                          sx: {
                            color: MInput.a,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                      <TextField
                        label="Date"
                        fullWidth
                        value={
                          selectedData?.date
                            ? formatTimestampToDate(selectedData.date)
                            : new Date().toLocaleDateString()
                        }
                        InputProps={{
                          readOnly: true,
                          autoComplete: "off",
                        }}
                        sx={MInput.b}
                        InputLabelProps={{
                          shrink: true,
                          sx: {
                            color: MInput.a,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        label="Application Number"
                        fullWidth
                        value={selectedData.applicatioNumber || ""}
                        InputProps={{
                          readOnly: true,
                          autoComplete: "off",
                        }}
                        sx={MInput.b}
                        InputLabelProps={{
                          shrink: selectedData.applicatioNumber,
                          sx: {
                            color: MInput.a,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        label="DSA Name"
                        fullWidth
                        value={selectedData.mainDSA || ""}
                        InputProps={{
                          readOnly: true,
                          autoComplete: "off",
                        }}
                        sx={MInput.b}
                        InputLabelProps={{
                          sx: {
                            color: MInput.a,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        label="Sub DSA"
                        fullWidth
                        value={selectedData.subDSA || ""}
                        InputProps={{
                          readOnly: true,
                          autoComplete: "off",
                        }}
                        sx={MInput.b}
                        InputLabelProps={{
                          sx: {
                            color: MInput.a,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        label="Andromeda Manager"
                        fullWidth
                        value={selectedData.salesManager || ""}
                        InputProps={{
                          readOnly: true,
                          autoComplete: "off",
                        }}
                        sx={MInput.b}
                        InputLabelProps={{
                          sx: {
                            color: MInput.a,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        label="Dsa Sales Manager"
                        fullWidth
                        value={selectedData.dsaSalesManager || ""}
                        InputProps={{
                          readOnly: true,
                          autoComplete: "off",
                        }}
                        sx={MInput.b}
                        InputLabelProps={{
                          sx: {
                            color: MInput.a,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        label="Policy Name"
                        fullWidth
                        value={selectedData.businessName || ""}
                        InputProps={{
                          readOnly: true,
                          autoComplete: "off",
                        }}
                        sx={MInput.b}
                        InputLabelProps={{
                          sx: {
                            color: MInput.a,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        label="Customer Name"
                        fullWidth
                        value={selectedData.customerName || ""}
                        InputProps={{
                          readOnly: true,
                          autoComplete: "off",
                        }}
                        sx={MInput.b}
                        InputLabelProps={{
                          sx: {
                            color: MInput.a,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        label="Banker Name"
                        fullWidth
                        value={selectedData.financialInstitutionName || ""}
                        InputProps={{
                          readOnly: true,
                          autoComplete: "off",
                        }}
                        sx={MInput.b}
                        InputLabelProps={{
                          sx: {
                            color: MInput.a,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        label="Applied Amount"
                        fullWidth
                        value={
                          selectedData?.appliedAmount
                            ? `${selectedData.appliedAmount.toLocaleString("en-IN", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}`
                            : ""
                        }
                        InputProps={{
                          startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                          readOnly: true,
                          autoComplete: "off",
                        }}
                        sx={MInput.b}
                        InputLabelProps={{
                          sx: {
                            color: MInput.a,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        label="Loan Disbursed Amount"
                        fullWidth
                        value={
                          selectedData?.disbursedAmount
                            ? `${selectedData.disbursedAmount.toLocaleString("en-IN", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}`
                            : ""
                        }
                        InputProps={{
                          startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                          readOnly: true,
                          autoComplete: "off",
                        }}
                        sx={MInput.b}
                        InputLabelProps={{
                          sx: {
                            color: MInput.a,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        label="Final Payout Amount"
                        fullWidth
                        value={
                          selectedData?.finalDsaGrossPayoutAmount
                            ? `${selectedData.finalDsaGrossPayoutAmount.toLocaleString("en-IN", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}`
                            : ""
                        }
                        InputProps={{
                          startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                          readOnly: true,
                          autoComplete: "off",
                        }}
                        sx={MInput.b}
                        InputLabelProps={{
                          sx: {
                            color: MInput.a,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        label="Calculated Sub DSA Amount"
                        fullWidth
                        value={
                          selectedData?.subDsaPaymentAmount
                            ? `${selectedData.subDsaPaymentAmount.toLocaleString("en-IN", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}`
                            : ""
                        }
                        InputProps={{
                          startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                          readOnly: true,
                          autoComplete: "off",
                        }}
                        sx={MInput.b}
                        InputLabelProps={{
                          sx: {
                            color: MInput.a,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        label="Revenue"
                        fullWidth
                        value={
                          selectedData?.finalDsaGrossPayoutAmount != null &&
                          selectedData?.subDsaPaymentAmount != null
                            ? `${(
                                selectedData.finalDsaGrossPayoutAmount -
                                selectedData.subDsaPaymentAmount
                              ).toLocaleString("en-IN", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}`
                            : ""
                        }
                        InputProps={{
                          startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                          readOnly: true,
                          autoComplete: "off",
                        }}
                        sx={MInput.b}
                        InputLabelProps={{
                          sx: {
                            color: MInput.a,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        label="Advance Amount"
                        // value={amount}
                        // onChange={handleAmountChange}
                        value={formattedAmount}
                        onChange={handleInputChange}
                        fullWidth
                        required
                        InputProps={{
                          startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                          autoComplete: "off",
                        }}
                        sx={MInput.b}
                        InputLabelProps={{
                          sx: {
                            color: MInput.a,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Autocomplete
                        options={accountsData} // Array of account objects
                        getOptionLabel={(option) => option.accountName} // Label for dropdown options
                        value={accountsData.find((item) => item.accountID === bankAccount) || null} // Selected value
                        onChange={(event, newValue) =>
                          handleBankAccountChange(newValue ? newValue.accountID : "")
                        }
                        PaperComponent={CustomPaper}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Account"
                            variant="outlined"
                            required
                            sx={{
                              height: 43,
                              ...MInput.b,
                              "& .MuiInputBase-root": {
                                height: "100%",
                                minHeight: 43,
                              },
                            }}
                            // inputProps={{ autoComplete: "off" }}
                            InputLabelProps={{
                              shrink: bankAccount,
                              sx: {
                                color: MInput.a,
                              },
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <TextField
                        label="Remarks"
                        fullWidth
                        multiline
                        rows={1}
                        value={remarks}
                        onChange={(e) => setRemarks(e.target.value)}
                        sx={MInput.b}
                        inputProps={{ autoComplete: "off" }}
                        InputLabelProps={{
                          shrink: selectedData.remarks,
                          sx: {
                            color: MInput.a,
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                  <MDBox mt={2} mb={0} display="flex" justifyContent="flex-end">
                    <StyledButton type="submit"> Save</StyledButton>
                  </MDBox>
                </MDBox>
              </form>
            </TableContainer>
          </Grid>
          <Grid item xs={12} md={5}>
            <StyledAgGridContainer
              component={Paper}
              className={`ag-theme-alpine ${dsaStyles.girdClass}`}
            >
              <AgGridReact
                rowData={servicesData}
                columnDefs={columns}
                pagination={true}
                paginationPageSize={11}
              />
            </StyledAgGridContainer>
          </Grid>
        </Grid>
      </TableContainer>
    </DashboardLayout>
  );
}

EntryAdvance.propTypes = {
  data: PropTypes.object.isRequired,
  node: PropTypes.shape({
    rowIndex: PropTypes.number.isRequired,
  }).isRequired,
  uuid: PropTypes.string.isRequired, // Add uuid to propTypes
  api: PropTypes.shape({
    getDisplayedRowAtIndex: PropTypes.func.isRequired,
  }).isRequired,
};
