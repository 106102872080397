import { useState, useEffect } from "react";
import URLs from "constants/urls"; // Make sure URLs is being used or remove it if not needed
import Cookie from "js-cookie";
import { useNavigate } from "react-router-dom";

export const useAccountsPayableApi = (resetFormState) => {
  const [companies, setCompanies] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [alertType, setAlertType] = useState("success");
  const [loading, setLoading] = useState(false);
  const jwt_token = Cookie.get("jwt_token");
  const navigate = useNavigate();
  const setAlert = (msg, type) => {
    setMessage(msg);
    setAlertType(type);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const fetchSelfDsaData = async () => {
    const url = URLs.dsaMasterGetUrl;
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt_token}`, // Include the JWT token here
      },
    };
    setLoading(true);
    try {
      const response = await fetch(url, options);
      const data = await response.json();
      const filteredData = data.data.filter((item) => item.dsaStatus === "S");
      setCompanies(filteredData);
    } catch (error) {
      setAlert(`Failed to fetch data: ${error.message}`, "error");
    }
    setLoading(false);
  };
  const fetchAccountsPayableAndromeda = async () => {
    setLoading(true);
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt_token}`, // Include the JWT token here
      },
    };
    try {
      const response = await fetch(URLs.AccountPayableAndrURL, options);
      const data = await response.json();
      if (response.ok) {
        setAccounts(data);
      } else {
        throw new Error("Failed to fetch entries data");
      }
    } catch (error) {
      setAlert(`Failed to fetch data: ${error.message}`, "error");
    } finally {
      setLoading(false);
    }
  };
  const fetchAccountsReceivableAndromeda = async () => {
    setLoading(true);
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt_token}`, // Include the JWT token here
      },
    };
    try {
      const response = await fetch(URLs.AccountReceivableAndrURL, options);
      const data = await response.json();
      if (response.ok) {
        setAccounts(data);
      } else {
        throw new Error("Failed to fetch entries data");
      }
    } catch (error) {
      setAlert(`Failed to fetch data: ${error.message}`, "error");
    } finally {
      setLoading(false);
    }
  };
  const fetchAccountsReceivableByDsaId = async (dsaId) => {
    setLoading(true);
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt_token}`, // Include the JWT token here
      },
    };
    try {
      const response = await fetch(`${URLs.AccountReceivableDsaURL}?dsaId=${dsaId}`, options);
      const data = await response.json();
      if (response.ok) {
        setAccounts(data);
      } else {
        throw new Error("Failed to fetch entries data");
      }
    } catch (error) {
      setAlert(`Failed to fetch data: ${error.message}`, "error");
    } finally {
      setLoading(false);
    }
  };
  const fetchAccountsPayableByDsaId = async (dsaId) => {
    setLoading(true);
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt_token}`, // Include the JWT token here
      },
    };
    try {
      const response = await fetch(`${URLs.AccountPayableDsaURL}?dsaId=${dsaId}`, options);
      const data = await response.json();
      if (response.ok) {
        setAccounts(data);
      } else {
        throw new Error("Failed to fetch entries data");
      }
    } catch (error) {
      setAlert(`Failed to fetch data: ${error.message}`, "error");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchSelfDsaData();
  }, []);
  return {
    loading,
    message,
    alertType,
    open,
    companies,
    accounts,
    setAlert,
    setOpen,
    setMessage,
    handleClose,
    setLoading,
    fetchAccountsPayableAndromeda,
    fetchAccountsReceivableAndromeda,
    fetchAccountsReceivableByDsaId,
    fetchAccountsPayableByDsaId,
  };
};
