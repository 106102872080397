import React, { useEffect, useState } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import URLs from "constants/urls";
import Cookie from "js-cookie";
import { Download, CloudUpload, CheckCircle } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import {
  Grid,
  MenuItem,
  TableContainer,
  Paper,
  TextField,
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
  CircularProgress,
  DialogTitle,
  Typography,
  Autocomplete,
  InputAdornment,
  Popover,
  Box,
  Button,
} from "@mui/material";
import * as XLSX from "xlsx";
import {
  StyledButton,
  StyledAlert,
  StyledSnackbar,
  CustomTableCell,
  inputStyle,
  ButtonBox,
  ViewButton,
  StyledAgGridContainer,
  StyledTypographyAA,
} from "components/StyledComponents/styledComponents.js";
import EntryDailog from "./entryDailog";
import { CustomPaper, autoSelectStyle, globalStyles } from "components/Styles/globalStyles";
import MDBox from "components/MDBox";
import {
  mdBoxStyle,
  typoStyle,
  dialogContentStyle,
  dialogStyles,
} from "components/Styles/dataDock";
import useCombinedDetails from "customApiHooks/dataDocksApi";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

export default function DataDock() {
  const MInput = inputStyle();
  const [open, setOpen] = useState(false);
  const [isFileUploaded, setIsFileUploaded] = useState(false); // To check if file is uploaded
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setIsFileUploaded(!!file);
  };
  const handleConfirmUpload = async () => {
    const file = document.getElementById("csv-file-upload").files[0];
    if (!file) {
      showAlert("No file selected.", "error");
      return;
    }

    const jwtToken = Cookie.get("jwt_token");
    const formData = new FormData();
    formData.append("file", file);

    try {
      setLoading(true);
      const response = await fetch(URLs.fileUpload, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
        body: formData,
      });

      const data = await response.json(); // Parse the response body first

      if (!response.ok) {
        throw new Error(data[0] || "Error uploading file."); // Use data[0] if available, fallback otherwise
      }

      if (Array.isArray(data) && data.length > 0) {
        showAlert(data[0], "success"); // Display the message from the array
      }

      console.log(data);
    } catch (error) {
      showAlert(error.message, "error"); // Show the error message
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  const {
    combinedData,
    openA,
    message,
    loading,
    setLoading,
    handleCloseAlert,
    showAlert,
    alertType,
    refetch: fetchCombinedDetails,
  } = useCombinedDetails();
  const [gridApis, setGridApis] = useState({
    business: null,
    employee: null,
    finance: null,
    dsa: null,
  });
  const onGridReadyBusiness = (params) => {
    setGridApis((prevApis) => ({ ...prevApis, business: params.api }));
  };
  const onGridReadyEmployee = (params) => {
    setGridApis((prevApis) => ({ ...prevApis, employee: params.api }));
  };
  const onGridReadyFinance = (params) => {
    setGridApis((prevApis) => ({ ...prevApis, finance: params.api }));
  };
  const onGridReadyDsa = (params) => {
    setGridApis((prevApis) => ({ ...prevApis, finance: params.api }));
  };
  // Function to download CSV template
  const downloadTemplate = () => {
    const headers = [
      "date",
      "applicationNumber",
      "finananceId",
      "businessType",
      "businessId",
      "dsaId",
      "employeeId",
      "customerName",
      "disbursedAmount",
      "grossPayout",
      "dsaPayout",
      "grossPayoutAmount",
      "dsaPayoutAmount",
      "businessStatus",
      "loginDate",
    ];
    // Convert headers to CSV format
    const csvContent = headers.join(",") + "\n";

    // Create a Blob and download it
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "DataTemplate.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleClickOpen = () => {
    setOpen(true); // Open the dialog
  };

  const handleClose = () => {
    setOpen(false); // Close the dialog
  };

  const defaultColDef = {
    flex: 1,
    minWidth: 100,
    resizable: true,
    filter: true,
    sorted: true,
  };

  const businessDetailsColumnDefs = [
    { headerName: "Product ID", field: "businessID" },
    { headerName: "Product Code", field: "businessTypeCode" },
  ];

  const employeeDetailsColumnDefs = [
    { headerName: "Employee ID", field: "employeeId" },
    {
      headerName: "Full Name",
      valueGetter: (params) => `${params.data.employeeFirstName} ${params.data.employeeLastName}`,
    },
  ];

  const financeDetailsColumnDefs = [
    { headerName: "Provider ID", field: "finananceId" },
    { headerName: "Provider Name", field: "finainacialInstitutionName" },
  ];

  const dsaDetailsColumnDefs = [
    { headerName: "Dsa ID", field: "dsaId" },
    { headerName: "Dsa Name", field: "dsaName" },
  ];

  const downloadExcel = (gridApi, columnDefs, fileName) => {
    if (!gridApi) {
      console.error("Grid API is not initialized");
      return;
    }
    const rowData = [];
    gridApi.forEachNode((node) => rowData.push(node.data));
    console.log("Row Data:", rowData);

    if (!rowData.length) {
      console.error("No data found in the grid.");
      return;
    }

    const dataToExport = rowData.map((row) => {
      const exportRow = {};
      columnDefs.forEach((colDef) => {
        if (colDef.field) {
          exportRow[colDef.headerName || colDef.field] = row[colDef.field];
        }
      });
      return exportRow;
    });

    console.log("Data to Export:", dataToExport);

    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Data");

    console.log("Downloading Excel File:", `${fileName}.xlsx`);
    XLSX.writeFile(workbook, `${fileName}.xlsx`);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <StyledSnackbar
        open={openA}
        autoHideDuration={5000}
        onClose={handleCloseAlert}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        severity={alertType}
      >
        <StyledAlert onClose={handleCloseAlert} severity={alertType}>
          {message}
        </StyledAlert>
      </StyledSnackbar>
      <Dialog
        open={loading}
        onClose={() => setLoading(false)}
        aria-labelledby="loading-dialog-title"
      >
        <DialogContent style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <CircularProgress color="primary" />
          <Typography variant="h6" style={{ marginTop: "20px" }}>
            Loading, please wait...
          </Typography>
        </DialogContent>
      </Dialog>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="lg" // Large size dialog
        fullWidth // Makes dialog take full width on large screens
        aria-labelledby="loading-dialog-title"
        PaperProps={{
          ...dialogStyles,
        }}
      >
        <DialogTitle sx={{ position: "relative", padding: "0px", zIndex: "1000" }}>
          <MDBox
            variant="gradient"
            borderRadius="lg"
            coloredShadow="info"
            textAlign="center"
            sx={{ ...mdBoxStyle, backgroundColor: MInput.a }}
          >
            <Typography variant="h6" fontWeight="medium" color="#FFFFFF" {...typoStyle}>
              Add Entry
            </Typography>
          </MDBox>
        </DialogTitle>
        <DialogContent {...dialogContentStyle}>
          <EntryDailog handleCloseD={handleClose} />
        </DialogContent>
      </Dialog>
      <TableContainer component={Paper} sx={globalStyles.accountsTable}>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            flexWrap: "wrap",
            gap: "5px",
          }}
        >
          <ButtonBox>
            <StyledButton onClick={downloadTemplate}>
              <Download style={{ marginRight: "8px" }} /> Template
            </StyledButton>
          </ButtonBox>
          <ButtonBox>
            <div style={{ textAlign: "center" }}>
              <input
                type="file"
                accept=".csv"
                onChange={handleFileUpload}
                style={{ display: "none" }}
                id="csv-file-upload"
              />
              <StyledButton
                onClick={() => document.getElementById("csv-file-upload").click()}
                style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
              >
                {isFileUploaded ? (
                  <CheckCircle style={{ marginRight: "8px", color: "#FFD700" }} />
                ) : (
                  <CloudUpload style={{ marginRight: "8px" }} />
                )}
                {isFileUploaded ? "Uploaded" : "Upload CSV"}
              </StyledButton>
            </div>
            <StyledButton variant="contained" color="primary" onClick={handleConfirmUpload}>
              Confirm Upload
            </StyledButton>
            <ViewButton onClick={handleClickOpen}>
              <AddIcon />
            </ViewButton>
          </ButtonBox>
        </div>
        <Grid container spacing={2} padding={2} sx={{ height: "90%" }}>
          <Grid item xs={12} md={3}>
            <div
              style={{
                display: "flex",
                alignItems: "center", // Vertically align the elements
                justifyContent: "space-between", // Space between title and button
                marginBottom: "10px",
              }}
            >
              <StyledTypographyAA>Product Details</StyledTypographyAA>
              <ViewButton
                onClick={() =>
                  downloadExcel(gridApis.business, businessDetailsColumnDefs, "BusinessDetails")
                }
              >
                <Download />
              </ViewButton>
            </div>

            <StyledAgGridContainer component={Paper} className={`ag-theme-alpine`}>
              <AgGridReact
                rowData={combinedData.businessDetails}
                columnDefs={businessDetailsColumnDefs}
                defaultColDef={defaultColDef}
                onGridReady={onGridReadyBusiness}
                paginationPageSize={11}
              />
            </StyledAgGridContainer>
          </Grid>

          <Grid item xs={12} md={3}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center", // Vertical alignment in the center
                justifyContent: "space-between", // Space between the title and button
                marginBottom: "10px",
              }}
            >
              <StyledTypographyAA>Employee Details</StyledTypographyAA>
              <ViewButton
                onClick={() =>
                  downloadExcel(gridApis.employee, employeeDetailsColumnDefs, "EmployeeDetails")
                }
              >
                <Download />
              </ViewButton>
            </Box>

            <StyledAgGridContainer component={Paper} className={`ag-theme-alpine`}>
              <AgGridReact
                rowData={combinedData.employeeDetails}
                columnDefs={employeeDetailsColumnDefs}
                defaultColDef={defaultColDef}
                onGridReady={onGridReadyEmployee}
                paginationPageSize={11}
              />
            </StyledAgGridContainer>
          </Grid>

          <Grid item xs={12} md={3}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between", // Ensures the title and button are spaced apart
                marginBottom: "10px",
              }}
            >
              <StyledTypographyAA>Provider Details</StyledTypographyAA>
              <ViewButton
                onClick={() =>
                  downloadExcel(gridApis.finance, financeDetailsColumnDefs, "FinanceDetails")
                }
              >
                <Download />
              </ViewButton>
            </Box>

            <StyledAgGridContainer
              // sx={{ maxHeight: "400px" }}
              component={Paper}
              className={`ag-theme-alpine`}
            >
              <AgGridReact
                rowData={combinedData.financeDetails}
                columnDefs={financeDetailsColumnDefs}
                defaultColDef={defaultColDef}
                onGridReady={onGridReadyFinance}
                paginationPageSize={11}
              />
            </StyledAgGridContainer>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between", // Ensures the title and button are spaced apart
                marginBottom: "10px",
              }}
            >
              <StyledTypographyAA>DSA Details</StyledTypographyAA>
              <ViewButton
                onClick={() => downloadExcel(gridApis.dsa, dsaDetailsColumnDefs, "dsaDetails")}
              >
                <Download />
              </ViewButton>
            </Box>

            <StyledAgGridContainer
              // sx={{ maxHeight: "400px" }}
              component={Paper}
              className={`ag-theme-alpine`}
            >
              <AgGridReact
                rowData={combinedData.dsaDetails}
                columnDefs={dsaDetailsColumnDefs}
                defaultColDef={defaultColDef}
                onGridReady={onGridReadyDsa}
                paginationPageSize={11}
              />
            </StyledAgGridContainer>
          </Grid>
        </Grid>
      </TableContainer>
    </DashboardLayout>
  );
}
