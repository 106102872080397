import { useState, useEffect, useCallback } from "react";
import URLs from "constants/urls";
import Cookie from "js-cookie";
import { useNavigate } from "react-router-dom";
const initialEntryData = {
  entryId: 0,
  dsaId: 0,
  subdsaId: 0,
  applicationNumber: "",
  businessType: "",
  date: "",
  businessMonthId: 0,
  employeeId: 0,
  dsaEmployeeId: 0,
  businessId: 0,
  customerName: "",
  finananceId: 0,
  appliedAmount: 0,
  disbursedAmount: 0,
  grossPayout: 0,
  dsaPayout: 0,
  grossPayoutAmount: 0,
  dsaPayoutAmount: 0,
  subdsaPayout: 0,
  subDsaPaymentAmount: 0,
  finalGrossPayoutAmount: 0,
  finalDsaGrossPayoutAmount: 0,
  remarks: "",
  businessStatus: "",
  dsaTdsPercent: 0,
  subDsaTdsPercent: 0,
};

const useApprovalEntriesApi = (typeCode = "", entryStatus = "", monthId) => {
  const [nonApprovalEntryData, setNonApprovalEntryData] = useState([]);
  const [pendingPaymentEntries, setPendingPaymentEntries] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [alertType, setAlertType] = useState("success");
  const [businessEntry, setEntry] = useState(initialEntryData);
  const [loading, setLoading] = useState(false);
  const [companyConfig, setCompanyConfig] = useState({});
  const jwt_token = Cookie.get("jwt_token");
  const navigate = useNavigate();

  const showAlert = (msg, type) => {
    setMessage(msg);
    setAlertType(type);
    setOpen(true);
  };

  const handleCloseAlert = () => {
    setOpen(false);
  };

  const fetchNonApprovalEntries = useCallback(async () => {
    setLoading(true);
    try {
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt_token}`, // Include the JWT token here
        },
      };
      const response = await fetch(
        `${URLs.baseUrl}/statusAproval?currentStatus=${entryStatus}&businessType=${typeCode}`,
        options
      );
      const data = await response.json();
      if (response.ok) {
        setNonApprovalEntryData(data);
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      showAlert(`Failed to Fetch: ${error.message}`, "error");
    }
    setLoading(false);
  }, [typeCode]);
  const fetchCompanyConfigs = async () => {
    setLoading(true);
    try {
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt_token}`, // Include the JWT token here
        },
      };
      const response = await fetch(`${URLs.companyURl}`, options);
      const data = await response.json();
      if (response.ok) {
        setCompanyConfig(data[0]);
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      showAlert(`Failed to Fetch: ${error.message}`, "error");
    }
    setLoading(false);
  };

  const fetchEntrieById = useCallback(async (entryId) => {
    setLoading(true);
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt_token}`, // Include the JWT token here
      },
    };
    try {
      const response = await fetch(`${URLs.baseUrl}/entries/${entryId}`, options);
      const data = await response.json();
      if (response.ok) {
        setEntry(data);
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      showAlert(`Failed to Fetch: ${error.message}`, "error");
    }
    setLoading(false);
  }, []); // No dependency on entryId here

  const fetchPendingEntries = useCallback(async () => {
    setLoading(true);
    try {
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt_token}`, // Include the JWT token here
        },
      };
      const response = await fetch(
        `${URLs.baseUrl}/statusAproval?currentStatus=${entryStatus}&businessType=${typeCode}`,
        options
      );
      const data = await response.json();
      if (response.ok) {
        setPendingPaymentEntries(data);
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      showAlert(`Failed to Fetch: ${error.message}`, "error");
    }
    setLoading(false);
  }, [typeCode]);

  const approveEntryApiCall = useCallback(
    async (entryId, data) => {
      const url = `${URLs.baseUrl}/businessEntries/${entryId}`;
      const options = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt_token}`,
        },
        body: JSON.stringify(data),
      };
      setLoading(true); // Set loading state before the request

      try {
        const response = await fetch(url, options);
        if (response.status === 401) {
          Cookie.remove("jwt_token");
          navigate("/login");
          return null;
        }
        if (response.ok) {
          showAlert(`${data.applicationNumber} business is closed`, "success"); // Success message
          await fetchNonApprovalEntries(); // Fetch the updated entries
        } else {
          const errorData = await response.json();
          const errorMessage = errorData.message || "Failed to approve entry"; // Extract error message from response
          showAlert(`${errorMessage}`, "error"); // Display the error message
        }
      } catch (error) {
        showAlert(`Error: ${error.message}`, "error"); // Handle network or other errors
      } finally {
        setLoading(false); // Stop loading state after the request
      }
    },
    [fetchNonApprovalEntries]
  );

  useEffect(() => {
    if (typeCode && entryStatus) {
      fetchNonApprovalEntries();
      fetchCompanyConfigs();
    }
  }, [fetchNonApprovalEntries, typeCode]);

  useEffect(() => {
    if (typeCode && entryStatus) {
      fetchPendingEntries();
      fetchCompanyConfigs();
    }
  }, [fetchPendingEntries, typeCode, entryStatus]);

  return {
    nonApprovalEntryData,
    approveEntryApiCall,
    fetchPendingEntries,
    pendingPaymentEntries,
    fetchEntrieById,
    businessEntry,
    companyConfig,
    open,
    message,
    loading,
    setLoading,
    handleCloseAlert,
    showAlert,
    alertType,
    isEditMode,
    setIsEditMode,
  };
};

export default useApprovalEntriesApi;
