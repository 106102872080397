import { useState, useEffect, useCallback } from "react";
import Tooltip from "@mui/material/Tooltip";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import URLs from "constants/urls";
import Cookie from "js-cookie";
import dayjs from "dayjs";

export default function data() {
  const [loanApplications, setLoanApplications] = useState([]); // State to store loan applications
  const [alert, setAlert] = useState(""); // State to handle alert messages
  const [typeCode, setTypeCode] = useState("BL");
  const [entriesLength, setEntriesLength] = useState(0);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [alertType, setAlertType] = useState("success");
  const [loading, setLoading] = useState(false);
  const [servicesData, setServicesData] = useState([]);
  const jwt_token = Cookie.get("jwt_token");
  const handleCloseAlert = () => {
    setOpen(false);
  };
  const showAlert = (msg, type) => {
    setMessage(msg);
    setAlertType(type);
    setOpen(true);
  };
  const fetchEntriesByMonthId = useCallback(async () => {
    const getCurrentFinancialYearDates = () => {
      const today = dayjs();
      const currentYear = today.year();
      const startYear = today.month() < 3 ? currentYear - 1 : currentYear; // Financial year starts in April
      const endYear = startYear + 1;

      const startDate = dayjs(`${startYear}-04-01`); // April 1st of the start year
      const endDate = dayjs(`${endYear}-03-31`); // March 31st of the end year

      return {
        formattedStartDate: startDate.format("YYYY-MM-DD"),
        formattedEndDate: endDate.format("YYYY-MM-DD"),
      };
    };

    const { formattedStartDate, formattedEndDate } = getCurrentFinancialYearDates();
    console.log(formattedStartDate, formattedEndDate);

    if (formattedStartDate && formattedEndDate) {
      setLoading(true);
      try {
        const options = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwt_token}`,
          },
        };
        const response = await fetch(
          `${URLs.monthlyBusinesssByDateRange}startDate=${formattedStartDate}&endDate=${formattedEndDate}`,
          options
        );
        const data = await response.json();
        if (response.ok) {
          setServicesData(data);
        } else {
          throw new Error("Failed to fetch entries data");
        }
      } catch (error) {
        showAlert(`Failed to fetch data: ${error.message}`, "error");
      } finally {
        setLoading(false);
      }
    } else {
      showAlert(`Failed to calculate financial year dates`, "error");
    }
  }, [jwt_token]); // Include dependencies here.
  useEffect(() => {
    fetchEntriesByMonthId();
  }, [fetchEntriesByMonthId]);

  const theme = useTheme();
  const colors = [
    theme.palette.primary.light,
    theme.palette.secondary.light,
    theme.palette.success.light,
    theme.palette.warning.light,
    theme.palette.error.light,
  ];

  const Company = ({ name, loanId, color }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <button
        style={{
          height: "25px",
          width: "60px",
          borderRadius: "5px",
          borderWidth: "0px",
          backgroundColor: color,
          color: "#FFFFFF",
          fontSize: "10px",
          fontFamily: "sans-serif",
          textTransform: "none",
          fontWeight: "bold",
        }}
      >
        {name}
      </button>
      <MDTypography variant="button" fontWeight="medium" ml={1} lineHeight={1}>
        {loanId}
      </MDTypography>
    </MDBox>
  );

  Company.propTypes = {
    name: PropTypes.string.isRequired,
    loanId: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
  };

  // Map loan applications data into rows
  const rows = loanApplications.map((application, index) => ({
    applicationNumber: (
      <Company
        loanId=""
        name={application.applicatioNumber}
        color={colors[index % colors.length]}
      />
    ),
    loanAmount: (
      <MDTypography variant="caption" color="text" fontWeight="medium">
        ₹{application.appliedAmount.toLocaleString("en-IN")}
      </MDTypography>
    ),
    manager: (
      <MDTypography variant="caption" color="text" fontWeight="medium">
        {application.salesManager}
      </MDTypography>
    ),
    dsa: (
      <MDTypography variant="caption" color="text" fontWeight="medium">
        {application.mainDSA}
      </MDTypography>
    ),
  }));

  return {
    columns: [
      { Header: "Application Number", accessor: "applicationNumber", width: "30%", align: "left" },
      { Header: "Business Amount", accessor: "loanAmount", width: "20%", align: "right" },
      { Header: "Manager Name", accessor: "manager", align: "center" },
      { Header: "DSA Name", accessor: "dsa", align: "center" },
    ],
    rows,
    entriesLength,
    servicesData,
  };
}
