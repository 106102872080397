import React, { useState, useEffect } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS
import "ag-grid-community/styles/ag-theme-alpine.css"; // Alpine theme CSS
import PropTypes from "prop-types";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  DialogContentText,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Grid,
  TextField,
  Typography,
  InputAdornment,
} from "@mui/material";
import MDBox from "components/MDBox";
import useApprovalEntriesApi from "../../../../customApiHooks/approvalApi";
import {
  ViewButton,
  StyledButton,
  StyledAlert,
  StyledSnackbar,
  inputStyle,
  CustomTableCell,
  StyledAgGridContainer,
} from "components/StyledComponents/styledComponents.js";
import { approvalStyles } from "components/Styles/approvalStyles";
import { globalStyles } from "components/Styles/globalStyles";
import {
  dialogStyles,
  typoStyle,
  mdBoxStyle,
  dialogContentStyle,
  containerStyle,
  tableCellStyle,
  subDialogContentStyle,
  dialogContentTextStyle,
} from "components/Styles/approvalStyles";

export default function InsuranceCommencement() {
  const [openPopup, setOpenPopup] = useState(false);
  const [opexPercent, setOpexPercent] = useState(0.1);
  const [grossPayoutAmount, setGrossPayoutAmount] = useState(0); // State for Gross Payout Amount
  const [dsaPayoutAfterTds, setDsaPayoutAfterTds] = useState(0);
  const [dsapayoutAmount, setDsapayoutAmount] = useState(0); // State for DSA Payout Amount
  const [andromedaFinalPayout, setAndromedaFinalPayout] = useState(0);
  const [subDsaPayoutAmount, setSubDsaPayoutAmount] = useState(0);
  const [originalDsaAmount, setOriginalDsaAmount] = useState(0);
  const [tdsAmount, setTdsAmount] = useState(0);
  const [typeCode, setTypeCode] = useState("IN");
  const [entryStatus, setEntryStatus] = useState("N");
  const [balance, setBalance] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [tdsOnDsaAmount, setTdsOnDsaAmount] = useState("");
  const [finalDsaAmountAfterTDS, setFinalDsaAmountAfterTDS] = useState("");
  const [netAmountLeftWithAndromeda, setNetAmountLeftWithAndromeda] = useState("");
  const [profitOrLoss, setProfitOrLoss] = useState("");
  const MInput = inputStyle();
  const [selectedData, setSelectedData] = React.useState({
    disbursedAmount: 0,
  });
  const [payouts, setPayouts] = useState({
    providerPayoutAmount: 0,
    tdsOnProviderPayout: 0,
    finalProviderPayoutAmount: 0,
    dsaPayoutAmountD: 0,
    tdsOnDsaPayout: 0,
    finalDsaPayoutAmount: 0,
    connectorPayout: 0,
    tdsOnConnectorPayout: 0,
    finalConnectorPayoutAmount: 0,
  });
  const {
    nonApprovalEntryData,
    approveEntryApiCall,
    open,
    message,
    fetchEntrieById,
    businessEntry,
    loading,
    setLoading,
    handleCloseAlert,
    companyConfig,
    showAlert,
    alertType,
    isEditMode,
    setIsEditMode,
  } = useApprovalEntriesApi(typeCode, entryStatus);

  const handleDisbursedAmountChange = (event) => {
    const value = event.target.value;
    // Allow empty input to clear the field
    if (selectedData.appliedAmount < selectedData.disbursedAmount) {
      showAlert("Disbursed Amount greater than appplied amount", "error");
    }
    if (value === "") {
      setSelectedData({
        ...selectedData,
        disbursedAmount: "",
      });
      return;
    }
    // Regular expression to allow only numbers with up to two decimal places
    const numericValue = value.replace(/[^0-9.]/g, "");
    if (numericValue) {
      setSelectedData({
        ...selectedData,
        disbursedAmount: numericValue,
      });
    }
  };
  const formattedAmount = selectedData.disbursedAmount;
  // selectedData.disbursedAmount !== ""
  //   ? Number(selectedData.disbursedAmount).toLocaleString("en-IN")
  //   : "";
  const handleViewClick = async (rowData) => {
    if (rowData.entryId) {
      await fetchEntrieById(rowData.entryId); // Directly use rowData.entryId
    }
    setSelectedData(rowData);
    setOpenPopup(true);
  };
  function formatTimestampToDate(timestamp) {
    const date = new Date(timestamp); // Convert timestamp to Date object
    return date.toISOString().slice(0, 10); // Format it as "YYYY-MM-DD"
  }
  const ActionCellRenderer = (props) => {
    const rowIndex = props.node.rowIndex;
    const rowData = props.api.getDisplayedRowAtIndex(rowIndex).data;
    return (
      <ViewButton type="button" onClick={() => handleViewClick(rowData)}>
        <VisibilityIcon />
      </ViewButton>
    );
  };
  const columns = [
    {
      headerName: "Date",
      field: "date",
      minWidth: 120,
      flex: 1,
      filter: true,
      sorted: true,
      valueFormatter: (params) => {
        const date = new Date(params.value); // Convert the timestamp to a Date object
        const day = date.getDate().toString().padStart(2, "0"); // Get day with leading zero
        const month = date.toLocaleString("default", { month: "short" }); // Get month as short name
        const year = date.getFullYear(); // Get full year
        return `${day}-${month}-${year}`; // Return in "dd-MMM-yyyy" format
      },
    },
    {
      headerName: "Appl. No.",
      field: "applicatioNumber",
      filter: true,
      sorted: true,
      minWidth: 120,
      flex: 1,
    },
    {
      headerName: "Insurance Premium",
      field: "appliedAmount",
      filter: true,
      sortable: true,
      minWidth: 150,
      flex: 1,
      cellStyle: { textAlign: "right", fontWeight: "500" }, // Aligns the text to the right
      valueFormatter: (params) =>
        `₹ ${params.value.toLocaleString("en-IN", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`, // Formats and rounds to two decimals
      headerClass: "header-center",
    },
    {
      headerName: "DSA Name",
      field: "mainDSA",
      filter: true,
      sortable: true,
      minWidth: 120,
      flex: 1.5,
    },
    {
      headerName: "Provider Name",
      field: "financialInstitutionName",
      filter: true,
      sortable: true,
      minWidth: 150,
      flex: 2,
    },
    {
      headerName: "Manager",
      field: "salesManager",
      filter: true,
      sortable: true,
      minWidth: 150,
      flex: 1.5,
    },
    {
      headerName: "View",
      cellRenderer: ActionCellRenderer,
      width: 80,
    },
  ];

  const calculatePayoutAmounts = (disbursedAmount, grossPayout, dsapayout, subdsaPayout) => {
    const roundToTwoDecimals = (value) => Math.round(value * 100) / 100;
    const providerPayoutAmount = roundToTwoDecimals((disbursedAmount * grossPayout) / 100);
    const tdsOnProviderPayout = roundToTwoDecimals(
      (providerPayoutAmount * companyConfig.companyTDSPercent) / 100
    );
    const finalProviderPayoutAmount = roundToTwoDecimals(
      providerPayoutAmount - tdsOnProviderPayout
    );

    const dsaPayoutAmountD = roundToTwoDecimals((providerPayoutAmount * dsapayout) / 100);
    const tdsOnDsaPayout = roundToTwoDecimals(
      (dsaPayoutAmountD * companyConfig.companyTDSPercent) / 100
    );
    const finalDsaPayoutAmount = roundToTwoDecimals(dsaPayoutAmountD - tdsOnDsaPayout);

    const connectorPayout = roundToTwoDecimals((finalDsaPayoutAmount * subdsaPayout) / 100);
    const tdsOnConnectorPayout = roundToTwoDecimals(
      (connectorPayout * companyConfig.companyTDSPercent) / 100
    );
    const finalConnectorPayoutAmount = roundToTwoDecimals(connectorPayout - tdsOnConnectorPayout);
    return {
      providerPayoutAmount,
      tdsOnProviderPayout,
      finalProviderPayoutAmount,
      dsaPayoutAmountD,
      tdsOnDsaPayout,
      finalDsaPayoutAmount,
      connectorPayout,
      tdsOnConnectorPayout,
      finalConnectorPayoutAmount,
    };
  };

  useEffect(() => {
    if (selectedData) {
      const {
        providerPayoutAmount,
        tdsOnProviderPayout,
        finalProviderPayoutAmount,
        dsaPayoutAmountD,
        tdsOnDsaPayout,
        finalDsaPayoutAmount,
        connectorPayout,
        tdsOnConnectorPayout,
        finalConnectorPayoutAmount,
      } = calculatePayoutAmounts(
        selectedData.disbursedAmount,
        selectedData.grossPayoutPercent,
        selectedData.dsaPayoutPercent,
        selectedData.subDsaPayoutPercent
      );
      setPayouts({
        providerPayoutAmount,
        tdsOnProviderPayout,
        finalProviderPayoutAmount,
        dsaPayoutAmountD,
        tdsOnDsaPayout,
        finalDsaPayoutAmount,
        connectorPayout,
        tdsOnConnectorPayout,
        finalConnectorPayoutAmount,
      });
    }
  }, [selectedData, opexPercent]); // Add opexPercent as a dependency

  const handleClose = () => {
    setOpenPopup(false);
  };
  // Function to open the dialog
  const handleOpenDialog = () => {
    // Check if the disbursed amount is zero
    if (selectedData.disbursedAmount == 0) {
      showAlert("Enter Disbursed amount", "error"); // Show alert with an error message
      return; // Exit the function to prevent the API call
    }
    setOpenDialog(true);
  };

  // Function to close the dialog
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  // Function to handle "Yes" confirmation
  const handleConfirmClick = async () => {
    const data = {
      ...businessEntry,
      businessStatus: "D".charAt(0),
      disbursedAmount: selectedData.disbursedAmount,
      grossPayoutAmount: grossPayoutAmount,
      finalGrossPayoutAmount: grossPayoutAmount,
      // finalGrossPayoutAmount: andromedaFinalPayout,
      dsaPayoutAmount: originalDsaAmount,
      finalDsaGrossPayoutAmount: originalDsaAmount,
      // finalDsaGrossPayoutAmount: dsaPayoutAfterTds,
      subDsaPaymentAmount: subDsaPayoutAmount,
      finalSubDSAGrossPayoutAmount: subDsaPayoutAmount,
    };
    await approveEntryApiCall(selectedData.entryId, data);
    setOpenDialog(false);
    setOpenPopup(false);
    setSelectedData({
      disbursedAmount: 0,
    });
  };

  return (
    <>
      <StyledSnackbar
        open={open}
        autoHideDuration={5000}
        onClose={handleCloseAlert}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        severity={alertType}
      >
        <StyledAlert onClose={handleCloseAlert} severity={alertType}>
          {message}
        </StyledAlert>
      </StyledSnackbar>
      <TableContainer component={Paper} sx={globalStyles.mainTable}>
        <StyledAgGridContainer component={Paper} className={`ag-theme-alpine`}>
          <AgGridReact
            rowData={nonApprovalEntryData}
            columnDefs={columns}
            pagination={true}
            paginationPageSize={15}
          />
        </StyledAgGridContainer>
      </TableContainer>
      <Dialog
        open={loading}
        onClose={() => setLoading(false)}
        aria-labelledby="loading-dialog-title"
      >
        <DialogContent style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <CircularProgress color="primary" />
          <Typography variant="h6" style={{ marginTop: "20px" }}>
            Loading, please wait...
          </Typography>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openPopup}
        onClose={handleClose}
        PaperProps={{
          ...dialogStyles,
        }}
        fullScreen
        sx={{
          ".MuiDialog-paper": {
            width: "90%", // 10% less than full width
            height: "90%", // 10% less than full height
            maxWidth: "none", // Remove the default max-width limit
            margin: "auto", // Center the dialog
            minWidth: "300px",
            borderRadius: "7px",
          },
        }}
      >
        <DialogTitle sx={{ position: "relative", padding: "0px", zIndex: "1000" }}>
          <MDBox
            variant="gradient"
            borderRadius="lg"
            coloredShadow="info"
            textAlign="center"
            sx={{ ...mdBoxStyle, backgroundColor: MInput.a }}
          >
            <Typography variant="h6" fontWeight="medium" color="#FFFFFF" {...typoStyle}>
              Insurance Entry Details
            </Typography>
          </MDBox>
        </DialogTitle>
        <DialogContent {...dialogContentStyle}>
          <Grid container spacing={2} pt={2}>
            <Grid item xs={12} md={1}>
              <TextField
                label="Insurance Id"
                fullWidth
                value={selectedData.entryId}
                InputProps={{
                  readOnly: true,
                }}
                sx={MInput.b}
                InputLabelProps={{
                  sx: {
                    color: MInput.a,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={1}>
              <TextField
                label="Date"
                fullWidth
                value={
                  selectedData?.date
                    ? formatTimestampToDate(selectedData.date)
                    : new Date().toLocaleDateString()
                }
                InputProps={{
                  readOnly: true,
                }}
                sx={MInput.b}
                InputLabelProps={{
                  sx: {
                    color: MInput.a,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                label="Application Number"
                fullWidth
                value={selectedData.applicatioNumber}
                InputProps={{
                  readOnly: true,
                }}
                sx={MInput.b}
                InputLabelProps={{
                  sx: {
                    color: MInput.a,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                label="DSA Name"
                fullWidth
                value={selectedData.mainDSA || "Unknown DSA"}
                InputProps={{
                  readOnly: true,
                }}
                sx={MInput.b}
                InputLabelProps={{
                  sx: {
                    color: MInput.a,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                label="Sub DSA"
                fullWidth
                value={selectedData.subDSA || "N/A"}
                InputProps={{
                  readOnly: true,
                }}
                sx={MInput.b}
                InputLabelProps={{
                  sx: {
                    color: MInput.a,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                label="Andromeda Manager"
                fullWidth
                value={selectedData.salesManager || "Unknown Manager"}
                InputProps={{
                  readOnly: true,
                }}
                sx={MInput.b}
                InputLabelProps={{
                  sx: {
                    color: MInput.a,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                label="Dsa Sales Manager"
                fullWidth
                value={selectedData.dsaSalesManager || "N/A"}
                InputProps={{
                  readOnly: true,
                }}
                sx={MInput.b}
                InputLabelProps={{
                  sx: {
                    color: MInput.a,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                label="Policy Name"
                fullWidth
                value={selectedData.businessName || "Unknown Policy"}
                InputProps={{
                  readOnly: true,
                }}
                sx={MInput.b}
                InputLabelProps={{
                  sx: {
                    color: MInput.a,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                label="Customer Name"
                fullWidth
                value={selectedData.customerName}
                InputProps={{
                  readOnly: true,
                }}
                sx={MInput.b}
                InputLabelProps={{
                  sx: {
                    color: MInput.a,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                label="Provider Name"
                fullWidth
                value={selectedData.financialInstitutionName || "Unknown Bank"}
                InputProps={{
                  readOnly: true,
                }}
                sx={MInput.b}
                InputLabelProps={{
                  sx: {
                    color: MInput.a,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={1.5}>
              <TextField
                label="Premium Amount"
                fullWidth
                value={`${selectedData?.appliedAmount?.toLocaleString("en-IN", {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}`}
                InputProps={{
                  startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                  readOnly: true,
                }}
                sx={MInput.b}
                InputLabelProps={{
                  sx: {
                    color: MInput.a,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={1.5}>
              <TextField
                label="Premium Commenced Amount"
                fullWidth
                value={formattedAmount || ""}
                onChange={handleDisbursedAmountChange}
                InputLabelProps={{
                  className:
                    selectedData.disbursedAmount || selectedData.disbursedAmount === 0
                      ? "inputLabelShrink"
                      : "inputLabel",
                  sx: {
                    color: MInput.a,
                  },
                }}
                InputProps={{
                  startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                }}
                error={selectedData.disbursedAmount === ""} // Error state if required and value is empty
                helperText={selectedData.disbursedAmount === "" ? "This field is required" : ""}
                sx={MInput.b}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                label="Remarks"
                multiline
                rows={1}
                fullWidth
                value={selectedData.remarks}
                InputProps={{
                  readOnly: true,
                }}
                sx={MInput.b}
                InputLabelProps={{
                  sx: {
                    color: MInput.a,
                  },
                }}
              />
            </Grid>
          </Grid>
          <TableContainer component={Paper} {...containerStyle}>
            <Table>
              <TableBody>
                <TableRow
                  sx={{
                    height: "30px", // Set a smaller height
                    "& .MuiTableCell-root": {
                      padding: "4px 10px", // Reduce padding for cells within the row
                    },
                  }}
                >
                  <CustomTableCell minWidth="100px" flexGrow={1} fontWeight="normal">
                    Banker Payout @ {Number(selectedData.grossPayoutPercent).toFixed(2)} %
                  </CustomTableCell>
                  <CustomTableCell minWidth="100px" flexGrow={1} fontWeight="normal" align="right">
                    ₹
                    {` ${(Number(payouts.providerPayoutAmount) || 0).toLocaleString("en-IN", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}`}
                  </CustomTableCell>
                  <CustomTableCell
                    minWidth="100px"
                    flexGrow={1}
                    fontWeight="normal"
                  ></CustomTableCell>
                </TableRow>
                <TableRow
                  sx={{
                    height: "30px", // Set a smaller height
                    "& .MuiTableCell-root": {
                      padding: "4px 10px", // Reduce padding for cells within the row
                    },
                  }}
                >
                  <CustomTableCell minWidth="100px" flexGrow={1} fontWeight="normal">
                    Less : TDS @ {companyConfig.companyTDSPercent} %
                  </CustomTableCell>
                  <CustomTableCell minWidth="100px" flexGrow={1} fontWeight="normal" align="right">
                    ₹
                    {`${(Number(payouts.tdsOnProviderPayout) || 0).toLocaleString("en-IN", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}`}
                  </CustomTableCell>
                  <CustomTableCell
                    minWidth="100px"
                    flexGrow={1}
                    fontWeight="normal"
                  ></CustomTableCell>
                </TableRow>
                <TableRow sx={{ backgroundColor: "#EE82EE" }}>
                  <CustomTableCell minWidth="100px" colSpan={2} flexGrow={1} fontWeight="bold">
                    Net Banker Payout
                  </CustomTableCell>
                  <CustomTableCell minWidth="100px" flexGrow={1} fontWeight="bold" align="right">
                    ₹
                    {` ${(Number(payouts.finalProviderPayoutAmount) || 0).toLocaleString("en-IN", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}`}
                  </CustomTableCell>
                </TableRow>
                <TableRow
                  sx={{
                    height: "30px", // Set a smaller height
                    "& .MuiTableCell-root": {
                      padding: "4px 10px", // Reduce padding for cells within the row
                    },
                  }}
                >
                  <CustomTableCell minWidth="100px" flexGrow={1} fontWeight="normal">
                    DSA Payout @ {Number(selectedData.dsaPayoutPercent).toFixed(2)} %
                  </CustomTableCell>
                  <CustomTableCell minWidth="100px" flexGrow={1} fontWeight="normal" align="right">
                    ₹
                    {` ${(Number(payouts.dsaPayoutAmountD) || 0).toLocaleString("en-IN", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}`}
                  </CustomTableCell>
                  <CustomTableCell
                    minWidth="100px"
                    flexGrow={1}
                    fontWeight="normal"
                  ></CustomTableCell>
                </TableRow>
                <TableRow
                  sx={{
                    height: "30px", // Set a smaller height
                    "& .MuiTableCell-root": {
                      padding: "4px 10px", // Reduce padding for cells within the row
                    },
                  }}
                >
                  <CustomTableCell minWidth="100px" flexGrow={1} fontWeight="normal">
                    Less : TDS @ {companyConfig.companyTDSPercent} %
                  </CustomTableCell>
                  <CustomTableCell minWidth="100px" flexGrow={1} fontWeight="normal" align="right">
                    ₹
                    {` ${(Number(payouts.tdsOnDsaPayout) || 0).toLocaleString("en-IN", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}`}
                  </CustomTableCell>
                  <CustomTableCell
                    minWidth="100px"
                    flexGrow={1}
                    fontWeight="normal"
                  ></CustomTableCell>
                </TableRow>
                <TableRow sx={{ backgroundColor: "#EE82EE" }}>
                  <CustomTableCell minWidth="100px" colSpan={2} flexGrow={1} fontWeight="bold">
                    Net DSA Payout
                  </CustomTableCell>
                  <CustomTableCell minWidth="100px" flexGrow={1} fontWeight="bold" align="right">
                    ₹
                    {` ${(Number(payouts.finalDsaPayoutAmount) || 0).toLocaleString("en-IN", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}`}
                  </CustomTableCell>
                </TableRow>
                <TableRow></TableRow>
                <TableRow
                  sx={{
                    height: "30px", // Set a smaller height
                    "& .MuiTableCell-root": {
                      padding: "4px 10px", // Reduce padding for cells within the row
                    },
                  }}
                >
                  <CustomTableCell minWidth="100px" flexGrow={1} fontWeight="normal">
                    Connector Payout @ {Number(selectedData.subDsaPayoutPercent).toFixed(2)} %
                  </CustomTableCell>
                  <CustomTableCell minWidth="100px" flexGrow={1} fontWeight="normal" align="right">
                    ₹
                    {` ${(Number(payouts.connectorPayout) || 0).toLocaleString("en-IN", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}`}
                  </CustomTableCell>
                  <CustomTableCell
                    minWidth="100px"
                    flexGrow={1}
                    fontWeight="normal"
                  ></CustomTableCell>
                </TableRow>
                <TableRow
                  sx={{
                    height: "30px", // Set a smaller height
                    "& .MuiTableCell-root": {
                      padding: "4px 10px", // Reduce padding for cells within the row
                    },
                  }}
                >
                  <CustomTableCell minWidth="100px" flexGrow={1} fontWeight="normal">
                    Less : TDS @ {companyConfig.companyTDSPercent} %
                  </CustomTableCell>
                  <CustomTableCell minWidth="100px" flexGrow={1} fontWeight="normal" align="right">
                    ₹
                    {` ${(Number(payouts.tdsOnConnectorPayout) || 0).toLocaleString("en-IN", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}`}
                  </CustomTableCell>
                  <CustomTableCell
                    minWidth="100px"
                    flexGrow={1}
                    fontWeight="normal"
                  ></CustomTableCell>
                </TableRow>
                <TableRow sx={{ backgroundColor: "#EE82EE" }}>
                  <CustomTableCell minWidth="100px" colSpan={2} flexGrow={1} fontWeight="bold">
                    Net Connector Payout
                  </CustomTableCell>
                  <CustomTableCell minWidth="100px" flexGrow={1} fontWeight="bold" align="right">
                    ₹
                    {` ${(Number(payouts.finalConnectorPayoutAmount) || 0).toLocaleString("en-IN", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}`}
                  </CustomTableCell>
                </TableRow>
                <TableRow
                  sx={{
                    backgroundColor:
                      Number(payouts.finalDsaPayoutAmount - payouts.finalConnectorPayoutAmount) >= 0
                        ? "purple"
                        : "red", // Set purple for profit, red for loss
                  }}
                >
                  <CustomTableCell
                    sx={{ color: "#ffffff" }}
                    minWidth="100px"
                    colSpan={2}
                    flexGrow={1}
                    fontWeight="bold"
                  >
                    {Number(payouts.finalDsaPayoutAmount - payouts.finalConnectorPayoutAmount) > 0
                      ? "Net DSA Profit / Loss"
                      : "Net DSA Loss"}{" "}
                    {/* Conditionally display 'Profit' or 'Loss' */}
                  </CustomTableCell>
                  <CustomTableCell
                    sx={{ color: "#ffffff" }}
                    minWidth="100px"
                    flexGrow={1}
                    fontWeight="bold"
                    align="right"
                  >
                    ₹
                    {` ${(
                      Number(payouts.finalDsaPayoutAmount - payouts.finalConnectorPayoutAmount) || 0
                    ).toLocaleString("en-IN", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}`}
                  </CustomTableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <DialogContent sx={subDialogContentStyle}>
            <DialogContentText sx={dialogContentTextStyle}>
              {`Are you sure you want to close this business with ${selectedData.disbursedAmount} disbursed Amount?`}
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={approvalStyles.dialogActions}>
            <StyledButton onClick={handleConfirmClick}>Yes</StyledButton>
            <StyledButton onClick={handleCloseDialog} variant="outlined">
              No
            </StyledButton>
          </DialogActions>
        </Dialog>
        <DialogActions>
          <StyledButton onClick={handleOpenDialog}>Confirm</StyledButton>
          <StyledButton onClick={handleClose}>Close</StyledButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
InsuranceCommencement.propTypes = {
  data: PropTypes.object.isRequired,
  node: PropTypes.shape({
    rowIndex: PropTypes.number.isRequired,
  }).isRequired,
  api: PropTypes.shape({
    getDisplayedRowAtIndex: PropTypes.func.isRequired,
  }).isRequired,
};
