import React, { useState, useEffect } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import PropTypes from "prop-types";
import AddTaskIcon from "@mui/icons-material/AddTask";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import useMasterApiCall from "../../../../customApiHooks/useMasterApiCall";
import { subDialogContentStyle, dialogContentTextStyle } from "components/Styles/approvalStyles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Grid,
  TextField,
  Alert,
  Tooltip,
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
  CircularProgress,
  Typography,
  Autocomplete,
} from "@mui/material";
import {
  ViewButton,
  DeleteButton,
  StyledTypography,
  StyledButton,
  StyledButtonC,
  CustomTableCell,
  StyledAlert,
  StyledSnackbar,
  inputStyle,
  StyledAgGridContainer,
} from "components/StyledComponents/styledComponents.js";
import useStyles from "components/Styles/bankerMasterStyles";
import { selectScrollStyle, CustomPaper } from "components/Styles/globalStyles";
import { masterStyles } from "components/Styles/bankerMasterStyles";
import { tableShadow, globalStyles } from "components/Styles/globalStyles";
export default function InsuranceMaster() {
  const [typeCode, setType] = useState("IN");
  const [selectedProviderName, setSelectedProviderName] = useState("");
  const [selectedInsuranceType, setSelectedInsuranceType] = useState("");
  const [finainacialInstitutionCode, setfinainacialInstitutionCode] = useState("");
  const [grossPayout, setGrossPayout] = useState("");
  const [tdsDeduction, setTdsDeduction] = useState("");
  const [dsaPercentOnPayout, setDsaPercentOnPayout] = useState("");
  const [selectedStreet, setSelectedStreet] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState("");
  const [selectedEmailID, setSelectedEmailID] = useState("");
  const [selectedContactPerson, setSelectedContactPerson] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [policyData, setPolicyData] = useState([]);
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [providerId, setProviderId] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteInsuranceProviderId, setDeleteInsuranceProviderId] = useState(0);
  const [deleteInsuranceProviderName, setDeleteInsuranceProviderName] = useState("");
  const MInput = inputStyle();
  const resetForm = () => {
    setSelectedProviderName("");
    setSelectedInsuranceType("");
    setGrossPayout("");
    setTdsDeduction("");
    setDsaPercentOnPayout("");
    setSelectedStreet("");
    setSelectedCity("");
    setSelectedState("");
    setSelectedPhoneNumber("");
    setSelectedEmailID("");
    setSelectedContactPerson("");
    setAlertMessage("");
    setSelectedCity("");
    setSelectedStreet("");
    setSelectedPhoneNumber("");
    setSelectedState("");
    setSelectedEmailID("");
    setSelectedContactPerson("");
    setAlertMessage("");
    setfinainacialInstitutionCode("");
    setPolicyData([]);
  };
  useEffect(() => {
    const selected = bussinessMasterData.find((item) => item.businessID === selectedInsuranceType);
    setSelectedInsuranceType(selected ? selected.businessID : ""); // Set the selected loan type based on selectedLoanType
  }, [selectedInsuranceType]);

  const {
    tableData,
    bussinessMasterData,
    isEditMode,
    open,
    alertType,
    message,
    loading,
    setLoading,
    setAlert,
    handleClose,
    setIsEditMode,
    fetchFinancesDataApiCall,
    postFinanceDataApiCall,
    deleteFinanceApiCall,
    putFinanceDataApiCall,
    getFinanceBusinessData,
  } = useMasterApiCall(typeCode, resetForm);

  useEffect(() => {
    fetchFinancesDataApiCall();
    getFinanceBusinessData();
  }, [fetchFinancesDataApiCall, getFinanceBusinessData]);

  const editInsuranceProviderData = (rowData) => {
    setIsEditMode(true);
    setSelectedProviderName(rowData.finainacialInstitutionName);
    setSelectedStreet(rowData.address.street);
    setSelectedCity(rowData.address.city);
    setSelectedState(rowData.address.state);
    setSelectedPhoneNumber(rowData.phoneNumber);
    setSelectedEmailID(rowData.emailId);
    setSelectedContactPerson(rowData.contactPerson);
    setType(rowData.type);
    setPolicyData(rowData.businessConfigs);
    setProviderId(rowData.finananceId);
    setfinainacialInstitutionCode(rowData.financialInsitutionCode);
  };

  const postBankerDataApiCall = () => {
    const financeData = {
      finananceId: parseInt(0),
      finainacialInstitutionName: selectedProviderName,
      financialInsitutionCode: finainacialInstitutionCode,
      type: typeCode,
      contactPerson: selectedContactPerson,
      phoneNumber: selectedPhoneNumber,
      emailId: selectedEmailID,
      address: {
        street: selectedStreet,
        city: selectedCity,
        state: selectedState,
      },
      businessConfigs: policyData.map((loan) => ({
        configId: parseInt(0),
        businessId: parseInt(loan.businessId),
        grossPayOut: parseFloat(loan.grossPayOut),
        tdsdeductionPercent: parseFloat(loan.tdsdeductionPercent),
        dsaPayOut: parseFloat(loan.dsaPayOut),
      })),
    };
    postFinanceDataApiCall(financeData);
  };

  const putBankerDataApiCall = async (bankerFinanceId) => {
    const financeData = {
      finananceId: parseInt(bankerFinanceId),
      finainacialInstitutionName: selectedProviderName,
      financialInsitutionCode: finainacialInstitutionCode,
      type: typeCode,
      contactPerson: selectedContactPerson,
      phoneNumber: selectedPhoneNumber,
      emailId: selectedEmailID,
      address: {
        street: selectedStreet,
        city: selectedCity,
        state: selectedState,
      },
      businessConfigs: policyData.map((loan) => ({
        configId: parseInt(loan.configId),
        businessId: parseFloat(loan.businessId),
        grossPayOut: parseFloat(loan.grossPayOut),
        tdsdeductionPercent: parseFloat(loan.tdsdeductionPercent),
        dsaPayOut: parseFloat(loan.dsaPayOut),
      })),
    };
    putFinanceDataApiCall(bankerFinanceId, financeData);
  };
  const handlePolicyData = () => {
    if (dsaPercentOnPayout && tdsDeduction && grossPayout && selectedInsuranceType) {
      const newPolicyData = {
        dsaPayOut: dsaPercentOnPayout,
        tdsdeductionPercent: tdsDeduction,
        grossPayOut: grossPayout,
        businessId: selectedInsuranceType,
      };
      const loanTypeExists = policyData.some((loan) => loan.businessId === selectedInsuranceType);
      if (loanTypeExists) {
        setAlert("Policy already exists.", "error");
      } else {
        setPolicyData([newPolicyData, ...policyData]);
        setDsaPercentOnPayout("");
        setSelectedInsuranceType("");
        setGrossPayout("");
        setTdsDeduction("");
      }
    } else {
      setAlert("Please fill in all fields", "error");
    }
  };

  const menuProps = {
    PaperProps: {
      sx: {
        ...selectScrollStyle,
      },
    },
  };

  const handleSave = (event) => {
    event.preventDefault();
    if (!selectedProviderName.trim() || policyData.length === 0) {
      setAlertMessage("Banker Name required, and there must be at least one policy entry.");
      return;
    }

    if (isEditMode) {
      putBankerDataApiCall(providerId);
    } else {
      postBankerDataApiCall();
    }
  };

  const handleDeletePolicyRow = (event, rowIndex) => {
    event.preventDefault();
    event.stopPropagation();
    const updatedPolicyData = policyData.filter((_, index) => index !== rowIndex);
    setPolicyData(updatedPolicyData);
  };

  const ActionCellRenderer = (props) => {
    const rowIndex = props.node.rowIndex;
    const rowData = props.api.getDisplayedRowAtIndex(rowIndex).data;
    const handleEdit = () => {
      editInsuranceProviderData(rowData);
    };
    const handleDelete = () => {
      setOpenDialog(true);
      setDeleteInsuranceProviderId(rowData.finananceId);
      setDeleteInsuranceProviderName(rowData.finainacialInstitutionName);
    };
    return (
      <div>
        <ViewButton type="button" onClick={handleEdit}>
          <EditIcon />
        </ViewButton>
        <DeleteButton type="button" onClick={handleDelete}>
          <DeleteIcon />
        </DeleteButton>
      </div>
    );
  };

  const columnDefs = [
    {
      headerName: "Provider Name",
      field: "finainacialInstitutionName",
      filter: true,
      sortable: true,
      minWidth: 150,
      flex: 2,
    },
    {
      headerName: "Policy Types",
      field: "businessConfigs",
      minWidth: 150,
      flex: 3,
      filter: true,
      sortable: true,
      cellRenderer: (params) => {
        // Extract the businessConfigs array from the row data
        const businessConfigs = params.value || [];

        // Map businessConfigs to business names
        const loanTypes = businessConfigs
          .map((config) => {
            // Find the corresponding business data based on businessId
            const business = bussinessMasterData.find(
              (item) => item.businessID === config.businessId
            );
            return business ? business.businessName : "Unknown Business";
          })
          .join(", "); // Join the names with commas

        // Render the loan types as a span element
        return <span>{loanTypes}</span>;
      },
    },
    {
      headerName: "Actions",
      cellRenderer: ActionCellRenderer, // Assuming you have this renderer
      minWidth: 100,
      flex: 1,
    },
  ];

  const resetFormState = () => {
    resetForm();
    setIsEditMode(false);
  };
  const formatInputValue = (value) => {
    return value.replace(/^\s+|\s+$/g, " ").replace(/\s+/g, " ");
  };

  function handleConfirmDelete() {
    deleteFinanceApiCall(deleteInsuranceProviderId); // Only delete if entryId exists
    setTimeout(() => {
      setOpenDialog(false);
    }, 1000);
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <TableContainer component={Paper} sx={globalStyles.mainTable}>
        <Grid container spacing={2}>
          <Dialog open={openDialog}>
            <DialogContent sx={subDialogContentStyle}>
              <DialogContentText sx={dialogContentTextStyle}>
                {`Are you sure you want to delete ${deleteInsuranceProviderName}`}
              </DialogContentText>
            </DialogContent>
            <DialogActions sx={masterStyles.dialogActions}>
              <StyledButton onClick={handleConfirmDelete}>Yes</StyledButton>
              <StyledButton onClick={() => setOpenDialog(false)} variant="outlined">
                No
              </StyledButton>
            </DialogActions>
          </Dialog>
          <Dialog
            open={loading}
            onClose={() => setLoading(false)}
            aria-labelledby="loading-dialog-title"
          >
            <DialogContent
              style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
            >
              <CircularProgress color="primary" />
              <Typography variant="h6" style={{ marginTop: "20px" }}>
                Loading, please wait...
              </Typography>
            </DialogContent>
          </Dialog>
          <Grid item xs={12} md={7}>
            <StyledSnackbar
              open={open}
              autoHideDuration={5000}
              onClose={handleClose}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              severity={alertType}
            >
              <StyledAlert onClose={handleClose} severity={alertType}>
                {message}
              </StyledAlert>
            </StyledSnackbar>
            <TableContainer component={Paper} sx={{ ...tableShadow, ...masterStyles.marginStyle }}>
              <div style={masterStyles.divStyle}>
                <StyledTypography>
                  {!isEditMode
                    ? "Insurance Provider Entry Form"
                    : "Edit Insurance Provider Details"}
                </StyledTypography>
                {isEditMode && (
                  <Tooltip title="Reset">
                    <StyledButtonC onClick={resetFormState}>
                      <RestartAltIcon />
                    </StyledButtonC>
                  </Tooltip>
                )}
              </div>
              <Grid sx={masterStyles.root}>
                {alertMessage && (
                  <Alert severity="error" onClose={() => setAlertMessage("")}>
                    {alertMessage}
                  </Alert>
                )}
                <form onSubmit={handleSave} autoComplete="off">
                  <Grid container spacing={2} pt={0}>
                    <Grid item xs={12} md={5}>
                      <TextField
                        label="Provider Name"
                        required
                        fullWidth
                        value={selectedProviderName}
                        onChange={(e) => setSelectedProviderName(formatInputValue(e.target.value))}
                        sx={MInput.b}
                        InputLabelProps={{
                          sx: {
                            color: MInput.a,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        label="Andromeda Code"
                        required
                        fullWidth
                        value={finainacialInstitutionCode}
                        onChange={(e) => setfinainacialInstitutionCode(e.target.value.trim())}
                        sx={MInput.b}
                        InputLabelProps={{
                          sx: {
                            color: MInput.a,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        label="Email ID"
                        fullWidth
                        type="email"
                        value={selectedEmailID}
                        onChange={(e) => setSelectedEmailID(formatInputValue(e.target.value))}
                        sx={MInput.b}
                        InputLabelProps={{
                          sx: {
                            color: MInput.a,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        label="Street"
                        fullWidth
                        value={selectedStreet}
                        onChange={(e) => setSelectedStreet(formatInputValue(e.target.value))}
                        sx={MInput.b}
                        InputLabelProps={{
                          sx: {
                            color: MInput.a,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        label="City"
                        fullWidth
                        value={selectedCity}
                        onChange={(e) => setSelectedCity(formatInputValue(e.target.value))}
                        sx={MInput.b}
                        InputLabelProps={{
                          sx: {
                            color: MInput.a,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        label="State"
                        fullWidth
                        value={selectedState}
                        onChange={(e) => setSelectedState(formatInputValue(e.target.value))}
                        sx={MInput.b}
                        InputLabelProps={{
                          sx: {
                            color: MInput.a,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        label="Phone Number"
                        fullWidth
                        type="tel"
                        onChange={(e) => {
                          const phoneNumberPattern = /^[1-9]\d{9}$/; // Example for Indian numbers
                          const value = e.target.value;
                          setSelectedPhoneNumber(value);
                          setPhoneNumberError(!phoneNumberPattern.test(value));
                        }}
                        value={selectedPhoneNumber}
                        error={phoneNumberError} // Display error if phone number is invalid
                        helperText={phoneNumberError ? "Please enter a valid phone number" : ""}
                        inputProps={{
                          maxLength: 10, // Restrict to 10 digits for phone numbers
                          pattern: "[1-9]{1}[0-9]{9}",
                        }}
                        sx={MInput.b}
                        InputLabelProps={{
                          sx: {
                            color: MInput.a,
                          },
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} md={3}>
                      <TextField
                        label="Email ID"
                        fullWidth
                        type="email"
                        value={selectedEmailID}
                        onChange={(e) => setSelectedEmailID(formatInputValue(e.target.value))}
                        sx={MInput.b}
                        InputLabelProps={{
                          sx: {
                            color: MInput.a,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        label="Contact Person Name"
                        fullWidth
                        type="text"
                        onChange={(e) => setSelectedContactPerson(formatInputValue(e.target.value))}
                        value={selectedContactPerson}
                        sx={MInput.b}
                        InputLabelProps={{
                          sx: {
                            color: MInput.a,
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                  <TableContainer sx={masterStyles.tableContainer}>
                    <Table sx={masterStyles.tableIStyle}>
                      <TableBody>
                        <TableRow>
                          <CustomTableCell minWidth="220px" fontWeight="bold">
                            Select policy
                          </CustomTableCell>
                          <CustomTableCell minWidth="70px" fontWeight="bold">
                            Gross Payout %
                          </CustomTableCell>
                          <CustomTableCell minWidth="70px" fontWeight="bold">
                            TDS Deduction %
                          </CustomTableCell>
                          <CustomTableCell minWidth="70px" fontWeight="bold">
                            DSA Payout %
                          </CustomTableCell>
                          <CustomTableCell
                            minWidth="60px"
                            flexGrow={0}
                            fontWeight="bold"
                          ></CustomTableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <Grid item xs={12} md={12}>
                              <Autocomplete
                                options={bussinessMasterData}
                                getOptionLabel={(option) => option.businessName}
                                value={
                                  bussinessMasterData.find(
                                    (item) => item.businessID === selectedInsuranceType
                                  ) || null
                                }
                                onChange={(event, value) => {
                                  setSelectedInsuranceType(value ? value.businessID : "");
                                }}
                                filterOptions={(options, state) => {
                                  const input = state.inputValue.toLowerCase();
                                  // Separate the options into those that start with the input and those that contain it
                                  const startsWithInput = options.filter((option) =>
                                    option.businessName.toLowerCase().startsWith(input)
                                  );
                                  const containsInput = options.filter(
                                    (option) =>
                                      !option.businessName.toLowerCase().startsWith(input) &&
                                      option.businessName.toLowerCase().includes(input)
                                  );
                                  // Combine the options with those starting with the input first, then the rest
                                  return [...startsWithInput, ...containsInput];
                                }}
                                sx={masterStyles.selectClass}
                                PaperComponent={CustomPaper}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label=""
                                    placeholder="Insurance Type"
                                    variant="outlined"
                                    sx={{
                                      height: 43,
                                      ...MInput.b,
                                      "& .MuiInputBase-root": {
                                        height: "100%",
                                        minHeight: 43,
                                      },
                                    }}
                                    InputLabelProps={{
                                      sx: {
                                        color: MInput.a, // Ensure this applies to the label
                                        "&.Mui-focused": {
                                          color: "red", // Focused label color
                                        },
                                      },
                                    }}
                                  />
                                )}
                              />
                            </Grid>
                          </TableCell>
                          <TableCell>
                            <TextField
                              label=""
                              type="text" // Use "text" to handle custom validation for the format
                              fullWidth
                              value={grossPayout}
                              onChange={(e) => {
                                const value = e.target.value;
                                // Regex to match the format X.yyyy where X < 9 and yyyy can be 1-3 digits
                                if (/^\d{0,3}(\.\d{0,3})?$/.test(value)) {
                                  setGrossPayout(value);
                                }
                              }}
                              inputProps={{ maxLength: 5 }} // Limit the total length to 5 characters (1 digit before the decimal and up to 4 after)
                              placeholder="000.00" // Example format
                              sx={MInput.b}
                              InputLabelProps={{
                                sx: {
                                  color: MInput.a,
                                },
                              }}
                            />
                          </TableCell>
                          <TableCell>
                            <TextField
                              label=""
                              type="text" // Use "text" to handle custom validation for the format
                              fullWidth
                              value={tdsDeduction}
                              onChange={(e) => {
                                const value = e.target.value;
                                // Regex to match the format X.yyyy where X < 9 and yyyy can be 1-3 digits
                                if (/^\d{0,3}(\.\d{0,3})?$/.test(value)) {
                                  setTdsDeduction(value);
                                }
                              }}
                              inputProps={{ maxLength: 5 }} // Limit the total length to 5 characters
                              placeholder="000.00" // Example format
                              sx={MInput.b}
                              InputLabelProps={{
                                sx: {
                                  color: MInput.a,
                                },
                              }}
                            />
                          </TableCell>

                          <TableCell width="150px">
                            <TextField
                              label=""
                              type="text" // Use "text" to handle custom validation for the format
                              fullWidth
                              value={dsaPercentOnPayout}
                              onChange={(e) => {
                                const value = e.target.value;
                                // Regex to match the format xx.xxx (up to 2 digits before and 3 after the decimal)
                                if (/^\d{0,3}(\.\d{0,3})?$/.test(value)) {
                                  setDsaPercentOnPayout(value);
                                }
                              }}
                              inputProps={{ maxLength: 6 }} // Limit the total length to 6 characters, considering decimal and digits
                              placeholder="000.00" // Example format
                              sx={MInput.b}
                              InputLabelProps={{
                                sx: {
                                  color: MInput.a,
                                },
                              }}
                            />
                          </TableCell>
                          <TableCell>
                            <ViewButton onClick={() => handlePolicyData()} type="button">
                              <AddTaskIcon />
                            </ViewButton>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TableContainer sx={masterStyles.tableContainerT2}>
                    <Table sx={masterStyles.tableIStyleT2}>
                      <TableBody>
                        <TableRow sx={masterStyles.tableHead}>
                          <CustomTableCell minWidth="100px" flexGrow={1} fontWeight="bold">
                            Policy Type
                          </CustomTableCell>
                          <CustomTableCell minWidth="100px" flexGrow={1} fontWeight="bold">
                            Gross Payout %
                          </CustomTableCell>
                          <CustomTableCell minWidth="100px" flexGrow={1} fontWeight="bold">
                            TDS Deduction %
                          </CustomTableCell>
                          <CustomTableCell minWidth="100px" flexGrow={1} fontWeight="bold">
                            DSA Payout %
                          </CustomTableCell>
                          <CustomTableCell
                            minWidth="60px"
                            flexGrow={0}
                            fontWeight="bold"
                          ></CustomTableCell>
                        </TableRow>
                        {Array.isArray(policyData) && policyData.length > 0 ? (
                          policyData.map((item, index) => (
                            <TableRow key={index}>
                              <CustomTableCell minWidth="100px" flexGrow={1} fontWeight="normal">
                                {bussinessMasterData.find(
                                  (eachItem) => eachItem.businessID == item.businessId
                                )?.businessName || "Unknown Business"}
                              </CustomTableCell>
                              <CustomTableCell minWidth="100px" flexGrow={1} fontWeight="normal">
                                {item.grossPayOut}
                              </CustomTableCell>
                              <CustomTableCell minWidth="100px" flexGrow={1} fontWeight="normal">
                                {item.tdsdeductionPercent}
                              </CustomTableCell>
                              <CustomTableCell minWidth="100px" flexGrow={1} fontWeight="normal">
                                {item.dsaPayOut}
                              </CustomTableCell>
                              <CustomTableCell minWidth="60px" flexGrow={1} fontWeight="normal">
                                <DeleteButton
                                  type="button"
                                  onClick={(event) => handleDeletePolicyRow(event, index)}
                                >
                                  <DeleteIcon />
                                </DeleteButton>
                              </CustomTableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <CustomTableCell colSpan={5} align="center">
                              No data available
                            </CustomTableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Grid sx={masterStyles.buttonDivStyle}>
                    <StyledButton type="submit"> Save</StyledButton>
                  </Grid>
                </form>
              </Grid>
            </TableContainer>
          </Grid>
          <Grid item xs={12} md={5}>
            <StyledAgGridContainer component={Paper} className={`ag-theme-alpine`}>
              <AgGridReact
                rowData={tableData}
                columnDefs={columnDefs}
                pagination={true}
                paginationPageSize={11}
              />
            </StyledAgGridContainer>
          </Grid>
        </Grid>
      </TableContainer>
    </DashboardLayout>
  );
}
InsuranceMaster.propTypes = {
  data: PropTypes.object.isRequired,
  node: PropTypes.shape({
    rowIndex: PropTypes.number.isRequired,
  }).isRequired,
  uuid: PropTypes.string.isRequired, // Add uuid to propTypes
  api: PropTypes.shape({
    getDisplayedRowAtIndex: PropTypes.func.isRequired,
  }).isRequired,
};
