import { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import data from "layouts/dashboard/components/Projects/data";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import {
  TableContainer,
  Paper,
  Box,
  Dialog,
  DialogContent,
  CircularProgress,
  Typography,
  Autocomplete,
  TextField,
} from "@mui/material";
import {
  StyledButton,
  StyledAlert,
  StyledSnackbar,
  StyledAgGridContainer,
  inputStyle,
} from "components/StyledComponents/styledComponents.js";
import { Height } from "@mui/icons-material";
import { StyledAgGridContainerForDashBoard } from "components/StyledComponents/styledComponents";

function Projects() {
  const { columns, rows, entriesLength, servicesData } = data();
  const [menu, setMenu] = useState(null);
  const openMenu = ({ currentTarget }) => setMenu(currentTarget);
  const closeMenu = () => setMenu(null);

  const renderMenu = (
    <Menu
      id="simple-menu"
      anchorEl={menu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(menu)}
      onClose={closeMenu}
    >
      <MenuItem onClick={closeMenu}>Action</MenuItem>
      <MenuItem onClick={closeMenu}>Another action</MenuItem>
      <MenuItem onClick={closeMenu}>Something else</MenuItem>
    </Menu>
  );
  const columnsAg = [
    {
      headerName: "Date",
      field: "date",
      sortable: true, // Enable sorting
      sort: "asc", // Default sort order (recent dates first)
      minWidth: 100,
      flex: 1,
      comparator: (valueA, valueB) => {
        // Parse the string date into a Date object
        const dateA = new Date(valueA);
        const dateB = new Date(valueB);

        // Sort in descending order (recent dates first)
        return dateA > dateB ? -1 : dateA < dateB ? 1 : 0;
      },
    },
    {
      headerName: "Applicarion Number",
      field: "applicationNumber",
      filter: true,
      sorted: true,
      minWidth: 200,
      flex: 1,
    },
    {
      headerName: "Loan Amount",
      field: "loanAmount",
      filter: true,
      sortable: true,
      minWidth: 170,
      flex: 1.5,
      cellStyle: { textAlign: "right", fontWeight: "500" },
      valueFormatter: (params) => {
        const value = params.value ?? 0; // Use nullish coalescing to default to 0
        return `₹ ${value.toLocaleString("en-IN", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`;
      },
    },
    {
      headerName: "BANK NAME",
      field: "bankName",
      filter: true,
      sortable: true,
      minWidth: 200,
      flex: 2,
    },
  ];

  return (
    <Card>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <MDBox>
          <MDTypography variant="h6" gutterBottom>
            Loans
          </MDTypography>
          <MDBox display="flex" alignItems="center" lineHeight={0}>
            <Icon
              sx={{
                fontWeight: "bold",
                color: ({ palette: { info } }) => info.main,
                mt: -0.5,
              }}
            >
              done
            </Icon>
            <MDTypography variant="button" fontWeight="regular" color="text">
              &nbsp;<strong> Loans Processed</strong> this month
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox color="text" px={2}>
          {/* <Icon sx={{ cursor: "pointer", fontWeight: "bold" }} fontSize="small" onClick={openMenu}>
            more_vert
          </Icon> */}
        </MDBox>
        {renderMenu}
      </MDBox>
      <MDBox>
        <StyledAgGridContainerForDashBoard
          component={Paper}
          className={`ag-theme-alpine`}
          sx={{ height: "50vh", maxHeight: "500px" }}
        >
          <AgGridReact
            rowData={servicesData}
            columnDefs={columnsAg}
            pagination={true}
            paginationPageSize={10}
          />
        </StyledAgGridContainerForDashBoard>
      </MDBox>
    </Card>
  );
}

export default Projects;
