import React, { useState, useEffect } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PropTypes from "prop-types";
import VisibilityIcon from "@mui/icons-material/Visibility";
import MDBox from "components/MDBox";
import {
  ViewButton,
  StyledButtonRec,
  StyledButton,
  StyledAlert,
  StyledSnackbar,
  inputStyle,
  CustomTableCell,
  StyledAgGridContainer,
} from "components/StyledComponents/styledComponents.js";
import { approvalStyles } from "components/Styles/approvalStyles";
import { globalStyles } from "components/Styles/globalStyles";
import { Box, Paper, TableContainer, Button } from "@mui/material";
import InsuranceCommencement from "components/Forms/Business Approval/InsuranceCommencement";
import MutualFundCommencement from "components/Forms/Business Approval/MutualFundCommencement";
import LoanDisbursement from "components/Forms/Business Approval/LoanDisbursement";

export default function Approvals() {
  const [approvalState, setApprovalState] = useState("BL");
  const approvalTypes = {
    "Loan Disbursal": "BL",
    "Insurance Disbursal": "IN",
    "Mutual Fund Disbursal": "MF",
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <TableContainer component={Paper} sx={globalStyles.accountsTable}>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: 2, // Adds spacing between buttons
            justifyContent: "space-around", // Centers the buttons inside the box
            alignItems: "center", // Vertically centers the content
            margin: "0 auto", // Centers the box within its parent container
            maxWidth: "600px", // Restricts the box to a maximum width
            width: "100%", // Sets the width to 50% of the parent container
          }}
        >
          {Object.keys(approvalTypes).map((key) => (
            <StyledButtonRec
              key={key}
              isSelected={approvalState === approvalTypes[key]} // Pass selected state
              onClick={() => setApprovalState(approvalTypes[key])}
            >
              {key}
            </StyledButtonRec>
          ))}
        </Box>
        {approvalState === "BL" && <LoanDisbursement />}
        {approvalState === "IN" && <InsuranceCommencement />}
        {approvalState === "MF" && <MutualFundCommencement />}
      </TableContainer>
    </DashboardLayout>
  );
}
