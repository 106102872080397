import React, { useState, useEffect } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { AgGridReact } from "ag-grid-react";
import EditIcon from "@mui/icons-material/Edit";
import PropTypes from "prop-types";
import DeleteIcon from "@mui/icons-material/Delete";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import "ag-grid-community/styles/ag-grid.css"; // AG Grid styles
import "ag-grid-community/styles/ag-theme-alpine.css";
import { subDialogContentStyle, dialogContentTextStyle } from "components/Styles/approvalStyles";
import { tableShadow, globalStyles } from "components/Styles/globalStyles";
import {
  TableContainer,
  Paper,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  TextField,
  Typography,
  Tooltip,
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
  CircularProgress,
} from "@mui/material";
import {
  ViewButton,
  DeleteButton,
  StyledTypography,
  StyledButton,
  StyledButtonC,
  StyledAlert,
  StyledSnackbar,
  inputStyle,
  StyledAgGridContainer,
} from "components/StyledComponents/styledComponents.js";
import { dsaMasterStyles } from "components/Styles/dsaMasterStyles";
import URLs from "constants/urls";
import Cookie from "js-cookie";

export default function DsaMaster() {
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedDSACode, setSelectedDSACode] = useState("");
  const [selectedDSAName, setSelectedDSAName] = useState("");
  const [selectedDSAStatus, setSelectedDSAStatus] = useState("");
  const [accountId, setAccountId] = useState(0);
  const [selectedStreet, setSelectedStreet] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState("");
  const [selectedEmailID, setSelectedEmailID] = useState("");
  const [selectedContactPerson, setSelectedContactPerson] = useState("");
  const [selectedDSAType, setSelectedDSAType] = useState("");
  const [tableData, setTableData] = useState([]);
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [dsaIdNum, setDsaIdNum] = useState("");
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [alertType, setAlertType] = useState("success");
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteDsaById, setDeleteDsaById] = useState(0);
  const [loading, setLoading] = useState(false);
  const [deleteDsaName, setDeleteDsaName] = useState("");
  const MInput = inputStyle();
  const jwt_token = Cookie.get("jwt_token");

  useEffect(() => {
    fetchDsaMasterData();
  }, []);
  const fetchDsaMasterData = async () => {
    const url = URLs.dsaMasterGetUrl;
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt_token}`,
      },
    };
    setLoading(true);
    try {
      const response = await fetch(url, options);
      const data = await response.json();
      const filteredDsaData = data.data.filter(
        (item) => item.dsaName.toLowerCase() !== "andromeda"
      );
      setTableData(filteredDsaData);
    } catch (error) {
      setAlert("Failed to fetch data", "error");
    }
    setLoading(false);
  };
  const postDsaMasterApiCall = async (newDsa) => {
    const url = URLs.dsaMasterGetUrl;
    // Define the `newDsa` object using the selected fields
    newDsa = {
      dsaId: 0,
      dsaType: selectedDSAType,
      dsaCode: selectedDSACode,
      dsaName: selectedDSAName,
      dsaStatus: selectedDSAStatus,
      contactpersonName: selectedContactPerson,
      address: {
        street: selectedStreet,
        city: selectedCity,
        state: selectedState,
      },
      phoneNumber: selectedPhoneNumber,
      emailId: selectedEmailID,
    };

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt_token}`,
      },
      body: JSON.stringify(newDsa),
    };

    setLoading(true); // Start loading

    try {
      const response = await fetch(url, options);
      const responseData = await response.json();

      if (response.ok) {
        fetchDsaMasterData(); // Refresh the DSA master list
        setAlert(responseData.message || "Posted successfully", "success"); // Show server message
        setIsEditMode(false); // Exit edit mode
        resetFormFields(); // Reset form fields if available
      } else {
        const errorMessage = responseData.message || "Failed to post DSA data";
        throw new Error(errorMessage); // Throw error with message from server
      }
    } catch (error) {
      setAlert("Failed to Post: " + error.message, "error"); // Show error message
    }
    setLoading(false);
  };

  const deleteDsaMasterApiCall = async (id) => {
    const url = `${URLs.dsaMasterGetUrl}/${id}`;
    const options = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt_token}`,
      },
    };
    setLoading(true);
    try {
      const response = await fetch(url, options);
      if (response.ok) {
        fetchDsaMasterData();
        setAlert("Deleted Successfully", "success");
        setIsEditMode(false);
        resetFormFields();
      } else {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }
    } catch (error) {
      setAlert("Failed to Delete: " + error.message, "error");
    }
    setLoading(false);
  };
  const putDsaMasterApiCall = async () => {
    const url = `${URLs.dsaMasterGetUrl}/${dsaIdNum}`;
    const updatedDsa = {
      dsaId: dsaIdNum,
      accountId,
      dsaType: selectedDSAType,
      dsaCode: selectedDSACode,
      dsaName: selectedDSAName,
      dsaStatus: selectedDSAStatus,
      contactpersonName: selectedContactPerson,
      address: {
        street: selectedStreet,
        city: selectedCity,
        state: selectedState,
      },
      phoneNumber: selectedPhoneNumber,
      emailId: selectedEmailID,
    };
    const options = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt_token}`,
      },
      body: JSON.stringify(updatedDsa),
    };
    setLoading(true);
    try {
      const response = await fetch(url, options);
      if (response.ok) {
        fetchDsaMasterData();
        setAlert("Updated Successfully", "success");
        setIsEditMode(false);
        resetFormFields();
      } else {
        const errData = await response.json(); // Parse response body
        const errorMessage = errData?.message || "Failed to Update";
        throw new Error(errorMessage);
      }
    } catch (error) {
      setAlert("Failed to Update: " + error.message, "error");
    }
    setLoading(false);
  };
  const setAlert = (msg, type) => {
    setMessage(msg);
    setAlertType(type);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const formatInputValue = (value) => {
    return value.replace(/^\s+|\s+$/g, " ").replace(/\s+/g, " ");
  };
  const handleViewClick = (rowData) => {
    setSelectedDSACode(rowData.dsaCode || "");
    setSelectedDSAName(rowData.dsaName || "");
    setSelectedDSAStatus(rowData.dsaStatus || "");
    setSelectedStreet(rowData.address.street || "");
    setSelectedCity(rowData.address.city || "");
    setSelectedState(rowData.address.state || "");
    setSelectedPhoneNumber(rowData.phoneNumber || "");
    setSelectedEmailID(rowData.emailId || "");
    setSelectedContactPerson(rowData.contactpersonName || "");
    setSelectedDSAType(rowData.dsaType || "");
    setDsaIdNum(rowData.dsaId);
    setAccountId(rowData.accountId);
    setIsEditMode(true);
  };

  const resetFormFields = () => {
    setSelectedDSACode("");
    setSelectedDSAName("");
    setSelectedDSAStatus("");
    setSelectedStreet("");
    setSelectedCity("");
    setSelectedState("");
    setSelectedPhoneNumber("");
    setSelectedEmailID("");
    setSelectedContactPerson("");
    setSelectedDSAType("");
    setIsEditMode(false);
  };

  const handleSave = (event) => {
    event.preventDefault();
    if (!selectedDSAType || !selectedDSAStatus) {
      setAlert("DSA Type and DSA Status are required", "error");
      return;
    }
    if (!isEditMode) {
      postDsaMasterApiCall();
    } else {
      putDsaMasterApiCall();
    }
  };

  const ActionCellRenderer = (props) => {
    const rowIndex = props.node.rowIndex;
    const rowData = props.api.getDisplayedRowAtIndex(rowIndex).data;
    const handleEdit = () => {
      handleViewClick(rowData);
    };

    const handleDelete = () => {
      setOpenDialog(true);
      setDeleteDsaById(rowData.dsaId);
      setDeleteDsaName(rowData.dsaName);
    };

    return (
      <div>
        <ViewButton onClick={handleEdit}>
          <EditIcon />
        </ViewButton>
        <DeleteButton onClick={handleDelete}>
          <DeleteIcon />
        </DeleteButton>
      </div>
    );
  };
  const columnDefs = [
    { headerName: "DSA Name", field: "dsaName", sortable: true, filter: true, flex: 2 },
    { headerName: "DSA Code", field: "dsaCode", sortable: true, filter: true, flex: 1 },
    { headerName: "DSA Type", field: "dsaType", sortable: true, filter: true, flex: 1 },
    {
      headerName: "Actions",
      field: "actions",
      cellRenderer: ActionCellRenderer,
      width: 100,
    },
  ];
  const resetFormState = () => {
    resetFormFields();
    setIsEditMode(false);
  };

  function handleConfirmDelete() {
    deleteDsaMasterApiCall(deleteDsaById); // Only delete if entryId exists
    setTimeout(() => {
      setOpenDialog(false);
    }, 1000);
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <TableContainer component={Paper} sx={globalStyles.mainTable}>
        <Dialog open={openDialog}>
          <DialogContent sx={subDialogContentStyle}>
            <DialogContentText sx={dialogContentTextStyle}>
              {`Are you sure you want to delete ${deleteDsaName}`}
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={dsaMasterStyles.dialogActions}>
            <StyledButton onClick={handleConfirmDelete}>Yes</StyledButton>
            <StyledButton onClick={() => setOpenDialog(false)} variant="outlined">
              No
            </StyledButton>
          </DialogActions>
        </Dialog>
        <Dialog
          open={loading}
          onClose={() => setLoading(false)}
          aria-labelledby="loading-dialog-title"
        >
          <DialogContent style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <CircularProgress color="primary" />
            <Typography variant="h6" style={{ marginTop: "20px" }}>
              Loading, please wait...
            </Typography>
          </DialogContent>
        </Dialog>
        <Grid container spacing={2}>
          <Grid item xs={12} md={7}>
            <TableContainer component={Paper} sx={{ ...tableShadow, position: "relative" }}>
              <div className={dsaMasterStyles.divStyle}>
                <StyledTypography>
                  {!isEditMode ? "Entry Form" : "Edit Entry Details"}
                </StyledTypography>
                {isEditMode && (
                  <StyledButtonC onClick={resetFormState}>
                    <RestartAltIcon />
                  </StyledButtonC>
                )}
              </div>
              <Grid sx={dsaMasterStyles.root}>
                <form
                  onSubmit={handleSave}
                  className={dsaMasterStyles.formStyle}
                  autoComplete="off"
                >
                  <StyledSnackbar
                    open={open}
                    autoHideDuration={5000}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    severity={alertType}
                  >
                    <StyledAlert onClose={handleClose} severity={alertType}>
                      {message}
                    </StyledAlert>
                  </StyledSnackbar>
                  <Grid pt={1} container spacing={2}>
                    <Grid container sx={{ ml: 3 }}>
                      <Grid item xs={12} md={6} container alignItems="center">
                        <label style={{ color: MInput.a, ...dsaMasterStyles.radioClass }}>
                          DSA Status:
                        </label>
                        <FormControl fullWidth>
                          <RadioGroup
                            label="DSA Status"
                            required
                            aria-label="DSA Status"
                            name="DSAStatus"
                            value={selectedDSAStatus}
                            onChange={(e) => setSelectedDSAStatus(e.target.value)}
                            row
                            sx={{
                              ...MInput.b, // Apply the same styling to the RadioGroup
                            }}
                          >
                            <FormControlLabel
                              value="S"
                              control={
                                <Radio
                                  defaultChecked
                                  sx={{
                                    color: MInput.a,
                                    ...dsaMasterStyles.radioStyle,
                                  }}
                                />
                              }
                              label="Self"
                            />
                            <FormControlLabel
                              value="O"
                              control={<Radio defaultChecked sx={dsaMasterStyles.radioStyle} />}
                              label="Others"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={6} container alignItems="center">
                        <label style={{ color: MInput.a, ...dsaMasterStyles.radioClass }}>
                          DSA Type:
                        </label>
                        <FormControl fullWidth>
                          <RadioGroup
                            label="DSA Type"
                            required
                            aria-label="DSA Type"
                            name="DSAType"
                            value={selectedDSAType}
                            onChange={(e) => setSelectedDSAType(e.target.value)}
                            row
                          >
                            <FormControlLabel
                              value="DMA"
                              control={<Radio defaultChecked sx={dsaMasterStyles.radioStyle} />}
                              label="DMA"
                            />
                            <FormControlLabel
                              value="DSA"
                              control={<Radio defaultChecked sx={dsaMasterStyles.radioStyle} />}
                              label="DSA"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth>
                        <TextField
                          label="DSA Code"
                          required
                          value={selectedDSACode}
                          onChange={(e) => setSelectedDSACode(e.target.value.trim())}
                          fullWidth
                          variant="outlined"
                          sx={MInput.b}
                          InputLabelProps={{
                            sx: {
                              color: MInput.a,
                            },
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth>
                        <TextField
                          label="DSA Name"
                          required
                          value={selectedDSAName}
                          onChange={(e) => setSelectedDSAName(formatInputValue(e.target.value))}
                          fullWidth
                          variant="outlined"
                          sx={MInput.b}
                          InputLabelProps={{
                            sx: {
                              color: MInput.a,
                            },
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth>
                        <TextField
                          label="Street"
                          value={selectedStreet}
                          onChange={(e) => setSelectedStreet(formatInputValue(e.target.value))}
                          fullWidth
                          variant="outlined"
                          sx={MInput.b}
                          InputLabelProps={{
                            sx: {
                              color: MInput.a,
                            },
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth>
                        <TextField
                          label="City"
                          value={selectedCity}
                          onChange={(e) => setSelectedCity(formatInputValue(e.target.value))}
                          fullWidth
                          variant="outlined"
                          sx={MInput.b}
                          InputLabelProps={{
                            sx: {
                              color: MInput.a,
                            },
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth>
                        <TextField
                          label="State"
                          value={selectedState}
                          onChange={(e) => setSelectedState(formatInputValue(e.target.value))}
                          fullWidth
                          variant="outlined"
                          sx={MInput.b}
                          InputLabelProps={{
                            sx: {
                              color: MInput.a,
                            },
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth>
                        <TextField
                          label="Phone Number"
                          fullWidth
                          type="tel"
                          onChange={(e) => {
                            const phoneNumberPattern = /^[1-9]\d{9}$/; // Example for Indian numbers
                            const value = e.target.value;
                            setSelectedPhoneNumber(value);
                            setPhoneNumberError(!phoneNumberPattern.test(value));
                          }}
                          value={selectedPhoneNumber}
                          error={phoneNumberError} // Display error if phone number is invalid
                          helperText={phoneNumberError ? "Please enter a valid phone number" : ""}
                          inputProps={{
                            maxLength: 10, // Restrict to 10 digits for phone numbers
                            pattern: "[6-9]{1}[0-9]{9}",
                          }}
                          sx={MInput.b}
                          InputLabelProps={{
                            sx: {
                              color: MInput.a,
                            },
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth>
                        <TextField
                          type="email"
                          label="Email ID"
                          value={selectedEmailID}
                          onChange={(e) => setSelectedEmailID(formatInputValue(e.target.value))}
                          fullWidth
                          variant="outlined"
                          sx={MInput.b}
                          InputLabelProps={{
                            sx: {
                              color: MInput.a,
                            },
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth>
                        <TextField
                          label="Contact Person Name"
                          fullWidth
                          type="text"
                          onChange={(e) =>
                            setSelectedContactPerson(formatInputValue(e.target.value))
                          }
                          value={selectedContactPerson}
                          sx={MInput.b}
                          InputLabelProps={{
                            sx: {
                              color: MInput.a,
                            },
                          }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid sx={dsaMasterStyles.girdClass} mt={3} mb={3}>
                    <StyledButton type="submit">Save</StyledButton>
                  </Grid>
                </form>
              </Grid>
            </TableContainer>
          </Grid>
          <Grid item xs={12} md={5}>
            <StyledAgGridContainer component={Paper} className={`ag-theme-alpine`}>
              <AgGridReact
                rowData={tableData} // Pass the table data
                columnDefs={columnDefs} // Define columns
                pagination={true} // Enable pagination
                paginationPageSize={11}
              />
            </StyledAgGridContainer>
          </Grid>
        </Grid>
      </TableContainer>
    </DashboardLayout>
  );
}

DsaMaster.propTypes = {
  data: PropTypes.object.isRequired,
  node: PropTypes.shape({
    rowIndex: PropTypes.number.isRequired,
  }).isRequired,
  api: PropTypes.shape({
    getDisplayedRowAtIndex: PropTypes.func.isRequired, // Validating that api.getDisplayedRowAtIndex is a required function
  }).isRequired, // api itself is required
};
