import { useState, useCallback } from "react";
import URLs from "constants/urls";
import Cookie from "js-cookie";
import { useNavigate } from "react-router-dom";

const useMasterApiCall = (typeCode, resetForm) => {
  const [tableData, setTableData] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [alertType, setAlertType] = useState("success");
  const [loading, setLoading] = useState(false);
  const [bussinessMasterData, setBusinessMasterData] = useState([]);
  const jwt_token = Cookie.get("jwt_token");
  const navigate = useNavigate();
  const setAlert = (msg, type) => {
    setMessage(msg);
    setAlertType(type);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const fetchFinancesDataApiCall = useCallback(async () => {
    const url = `${URLs.finanaceGetUrlByTypeCodeUrl}/${typeCode}`;
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt_token}`, // Include the JWT token here
      },
    };
    setLoading(true);
    try {
      const response = await fetch(url, options);
      const data = await response.json();
      setTableData(data);
    } catch (error) {
      setAlert(`Data not fetched: ${error.message}`, "error");
    }
    setLoading(false);
  }, [typeCode]);

  const getFinanceBusinessData = useCallback(async () => {
    const url = `${URLs.financeBusinessGetUrl}/${typeCode}`;
    setLoading(true);
    try {
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt_token}`, // Include the JWT token here
        },
      };
      const response = await fetch(url, options);
      if (response.status === 401) {
        Cookie.remove("jwt_token");
        navigate("/login");
        return null;
      }

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const data = await response.json();
      setBusinessMasterData(data);
    } catch (error) {
      setAlert(`Data not fetched: ${error.message}`, "error");
    }
    setLoading(false);
  }, [typeCode]);

  const deleteFinanceApiCall = useCallback(
    async (bId) => {
      const url = `${URLs.financeUrl}/${bId}`;
      const options = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt_token}`,
        },
      };
      setLoading(true);
      try {
        const response = await fetch(url, options);
        if (response.status === 401) {
          Cookie.remove("jwt_token");
          navigate("/login");
          return null;
        }
        if (response.ok) {
          setAlert("Deleted successfully");
          fetchFinancesDataApiCall();
          setIsEditMode(false);
          if (resetForm) resetForm();
        } else {
          throw new Error("Failed to delete");
        }
      } catch (error) {
        setAlert(`Failed to delete: ${error.message}`, "error");
      }
      setLoading(false);
    },
    [fetchFinancesDataApiCall, resetForm]
  );

  const postFinanceDataApiCall = useCallback(
    async (financeData) => {
      const url = URLs.financeUrl;
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt_token}`,
        },
        body: JSON.stringify(financeData),
      };

      setLoading(true); // Start loading

      try {
        const response = await fetch(url, options);
        if (response.status === 401) {
          Cookie.remove("jwt_token");
          navigate("/login");
          return null;
        }
        const responseData = await response.json(); // Parse the response JSON

        // Check if the response is OK and if the status is "OK"
        if (response.ok) {
          fetchFinancesDataApiCall(); // Refresh the finance data list
          setAlert(responseData.message || "Posted successfully", "success"); // Show server message
          if (resetForm) resetForm(); // Reset form fields if available
          setIsEditMode(false); // Exit edit mode
        } else {
          const errorMessage = responseData.message || "Failed to post finance data";
          throw new Error(errorMessage); // Throw error with message from server
        }
      } catch (error) {
        setAlert(`${error.message}`, "error"); // Show error message
      } finally {
        setLoading(false); // Stop loading
      }
    },
    [fetchFinancesDataApiCall, resetForm]
  );

  const putFinanceDataApiCall = useCallback(
    async (financeId, financeData) => {
      const url = `${URLs.financeUrl}/${financeId}`;
      const options = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt_token}`,
        },
        body: JSON.stringify(financeData),
      };
      setLoading(true);
      try {
        const response = await fetch(url, options);
        if (response.status === 401) {
          Cookie.remove("jwt_token");
          navigate("/login");
          return null;
        }
        if (response.ok) {
          setAlert("Updated successfully");
          setIsEditMode(false);
          fetchFinancesDataApiCall();
          if (resetForm) resetForm();
        } else {
          const errData = await response.json(); // Parse response body
          const errorMessage = errData?.message || "Failed to Update";
          throw new Error(errorMessage);
        }
      } catch (error) {
        setAlert(`${error.message}`, "error");
      }
      setLoading(false);
    },
    [fetchFinancesDataApiCall, resetForm]
  );

  return {
    tableData,
    bussinessMasterData,
    open,
    alertType,
    message,
    setAlert,
    isEditMode,
    setLoading,
    loading,
    handleClose,
    setIsEditMode,
    fetchFinancesDataApiCall,
    getFinanceBusinessData,
    deleteFinanceApiCall,
    postFinanceDataApiCall,
    putFinanceDataApiCall,
  };
};

export default useMasterApiCall;
