import { useState, useEffect, useCallback } from "react";
import Cookie from "js-cookie"; // Assuming Cookie is used for managing JWT
import { useNavigate } from "react-router-dom";
import URLs from "constants/urls";

const useCombinedDetails = () => {
  const [combinedData, setCombinedData] = useState({
    businessDetails: null,
    employeeDetails: null,
    financeDetails: null,
    dsaDetails: null,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [openA, setOpenA] = useState(false);
  const [message, setMessage] = useState("");
  const [alertType, setAlertType] = useState("success");
  const jwt_token = Cookie.get("jwt_token");
  const navigate = useNavigate();
  const showAlert = (msg, type) => {
    setMessage(msg);
    setAlertType(type);
    setOpenA(true);
  };

  const handleCloseAlert = () => {
    setOpenA(false);
  };

  const fetchCombinedDetails = useCallback(async () => {
    setLoading(true);
    setError(null);

    const urls = [
      URLs.financeBusinessGetUrl,
      URLs.employeeMasterGetUrl,
      URLs.financeUrl,
      URLs.dsaMasterGetUrl,
    ];

    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt_token}`,
      },
    };

    try {
      const responses = await Promise.all(urls.map((url) => fetch(url, options)));
      // Extract JSON data
      const data = await Promise.all(responses.map((response) => response.json()));
      console.log("Dataa", data);

      // Map responses to keys
      setCombinedData({
        businessDetails: data[0],
        employeeDetails: data[1],
        financeDetails: data[2],
        dsaDetails: data[3].data,
      });
    } catch (err) {
      setError(err.message);
      showAlert(`Failed to fetch details: ${err.message}`, "error");
    } finally {
      setLoading(false);
    }
  }, [jwt_token, navigate]);

  useEffect(() => {
    fetchCombinedDetails();
  }, [fetchCombinedDetails]);

  return {
    combinedData,
    openA,
    message,
    loading,
    setLoading,
    handleCloseAlert,
    showAlert,
    alertType,
    refetch: fetchCombinedDetails,
  };
};

export default useCombinedDetails;
