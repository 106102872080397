import { useState, useEffect, useCallback } from "react";
import URLs from "constants/urls";
import Cookie from "js-cookie";
import { useNavigate } from "react-router-dom";

// Define the custom hook
const useDsaAndEmployeeBusinessApi = (code) => {
  const [loading, setLoading] = useState(false);
  const [dsaData, setDsaData] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);
  const [message, setMessage] = useState("");
  const [alertType, setAlertType] = useState("success");
  const [open, setOpen] = useState(false);
  const [servicesData, setServicesData] = useState([]);
  const [subDsaEntries, setSubDsaEntries] = useState([]);
  const [entry, setEntry] = useState([]);
  const [accountsData, setAccountsData] = useState([]);
  const jwt_token = Cookie.get("jwt_token");
  const navigate = useNavigate();

  const setAlert = (msg, type) => {
    setMessage(msg);
    setAlertType(type);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const fetchDsaMasterData = async () => {
    const url = URLs.dsaMasterGetUrl;
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt_token}`, // Include the JWT token here
      },
    };
    setLoading(true);
    try {
      const response = await fetch(url, options);
      const data = await response.json();
      setDsaData(data.data);
    } catch (error) {
      setAlert("Failed to fetch data", "error");
    }
    setLoading(false);
  };
  const fetchEmployeeData = async () => {
    setLoading(true);
    const url = `${URLs.baseUrl}/employeesByRoleId/${2}`;
    try {
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt_token}`, // Include the JWT token here
        },
      };
      const response = await fetch(url, options);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      const emData = data.filter((item) => item.companyId == 35);
      setEmployeeData(emData);
    } catch (error) {
      setAlert("Data not Fetched: " + error.message, "error");
    }
    setLoading(false);
  };
  const fetchEntriesByMonthId = async (monthId, sourceId) => {
    setLoading(true);
    try {
      // Fetch entries by monthId
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt_token}`, // Include the JWT token here
        },
      };
      const response = await fetch(
        `${URLs.baseUrl}/dsaMonthlyReports?businessMonthId=${monthId}&dsa_Id=${sourceId}`,
        options
      );
      const data = await response.json();
      if (response.ok) {
        setServicesData(data);
      } else {
        throw new Error("Failed to fetch entries data");
      }
    } catch (error) {
      setAlert(`Failed to fetch data: ${error.message}`, "error");
    } finally {
      setLoading(false);
    }
  };
  const fetchEmployeeBusinessByMonthId = async (monthId, sourceId) => {
    setLoading(true);
    try {
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt_token}`, // Include the JWT token here
        },
      };
      // Fetch entries by monthId
      const response = await fetch(
        `${URLs.baseUrl}/employeeMonthlyReports?businessMonthId=${monthId}&employeeId=${sourceId}`,
        options
      );
      const data = await response.json();
      if (response.ok) {
        setServicesData(data);
      } else {
        throw new Error("Failed to fetch entries data");
      }
    } catch (error) {
      setAlert(`Failed to fetch data: ${error.message}`, "error");
    } finally {
      setLoading(false);
    }
  };
  const fetchSubDsaMasterData = async () => {
    const url = URLs.dsaMasterGetUrl;
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt_token}`, // Include the JWT token here
      },
    };
    setLoading(true);
    try {
      const response = await fetch(url, options);
      const data = await response.json();
      const filteredData = data.data.filter((item) => item.dsaStatus === "S");
      setDsaData(filteredData);
    } catch (error) {
      setAlert("Failed to fetch data", "error");
    }
    setLoading(false);
  };
  const fetchBusinessEntriesBySelf = async (sourceId) => {
    setLoading(true);
    try {
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt_token}`, // Include the JWT token here
        },
      };
      const response = await fetch(`${URLs.baseUrl}/selfDsaEntries?dsaId=${sourceId}`, options);
      const data = await response.json();
      if (response.ok) {
        setSubDsaEntries(data);
      } else {
        throw new Error("Failed to fetch entries data");
      }
    } catch (error) {
      setAlert(`Failed to fetch data: ${error.message}`, "error");
    } finally {
      setLoading(false);
    }
  };
  const postAdvanceDetails = async (postData, resetAdvanceForm, monthId, dsaId) => {
    setLoading(true); // Optional: if you want to show a loading indicator

    try {
      const response = await fetch(`${URLs.baseUrl}/advanceDetails`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt_token}`,
        },
        body: JSON.stringify(postData), // Convert your data to JSON format
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message);
      }
      setAlert(`${data.message}`, "success"); // Show success message or process data
      fetchSelfDsaEntriesData(monthId, dsaId);
      resetAdvanceForm();
    } catch (error) {
      setAlert(`Failed to submit data: ${error.message}`, "error"); // Show error message
    } finally {
      setLoading(false); // Optional: stop the loading indicator
    }
  };
  const updateAdvanceDetails = async (paymentId, putData, resetAdvanceForm, monthId, dsaId) => {
    setLoading(true); // Optional: if you want to show a loading indicator
    try {
      const response = await fetch(`${URLs.baseUrl}/advanceDetails/${paymentId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt_token}`,
        },
        body: JSON.stringify(putData), // Convert the data to JSON format
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message);
      }
      setAlert(`${data.message}`, "success");
      fetchSelfDsaEntriesData(monthId, dsaId);
      resetAdvanceForm();
    } catch (error) {
      setAlert(`Failed to update data: ${error.message}`, "error"); // Show error message
    } finally {
      setLoading(false); // Optional: stop the loading indicator
    }
  };
  const fetchSelfDsaEntriesData = async (monthId, sourceId) => {
    setLoading(true);
    try {
      // Fetch entries by monthId
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt_token}`, // Include the JWT token here
        },
      };
      const response = await fetch(
        `${URLs.baseUrl}/selfDsaEntries?dsaId=${sourceId}&businessMonthID=${monthId}&dsaStatus=S`,
        options
      );
      const data = await response.json();
      if (response.ok) {
        setServicesData(data);
      } else {
        throw new Error("Failed to fetch entries data");
      }
    } catch (error) {
      setAlert(`Failed to fetch data: ${error.message}`, "error");
    } finally {
      setLoading(false);
    }
  };
  const fetchCashBankAccounts = async () => {
    setLoading(true);
    try {
      // Fetch entries by monthId
      const response = await fetch(`${URLs.accountsUrl}`);
      const data = await response.json();
      if (response.ok) {
        setAccountsData(data);
      } else {
        throw new Error("");
      }
    } catch (error) {
      setAlert(`Failed to fetch Accounts data: ${data.message || ""}`, "error");
    } finally {
      setLoading(false);
    }
  };
  const fetchEntrieById = useCallback(async (entryId) => {
    setLoading(true);
    try {
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt_token}`, // Include the JWT token here
        },
      };
      const response = await fetch(`${URLs.baseUrl}/entries/${entryId}`, options);
      const data = await response.json();
      if (response.ok) {
        setEntry(data);
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      setAlert(`Failed to Fetch: ${error.message}`, "error");
    }
    setLoading(false);
  }, []);
  const approveEntryApiCall = useCallback(async (entryId, data) => {
    const url = `${URLs.baseUrl}/businessEntries/${entryId}`;
    const options = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt_token}`,
      },
      body: JSON.stringify(data),
    };
    setLoading(true); // Set loading state before the request

    try {
      const response = await fetch(url, options);

      if (response.ok) {
        fetchBusinessEntriesBySelf(0, 0);
        setAlert(`${data.applicationNumber} business is closed`, "success"); // Success message
      } else {
        const errorData = await response.json();
        const errorMessage = errorData.message || "Failed to approve entry"; // Extract error message from response
        setAlert(`${errorMessage}`, "error"); // Display the error message
      }
    } catch (error) {
      setAlert(`Error: ${error.message}`, "error"); // Handle network or other errors
    } finally {
      setLoading(false); // Stop loading state after the request
    }
  }, []);

  useEffect(() => {
    if (code === "dsa") {
      fetchDsaMasterData();
    } else if (code === "employee") {
      fetchEmployeeData();
    } else if (code === "subDsa") {
      fetchSubDsaMasterData();
    }
  }, [code]);
  return {
    dsaData,
    loading,
    message,
    alertType,
    servicesData,
    employeeData,
    subDsaEntries,
    open,
    entry,
    accountsData,
    fetchCashBankAccounts,
    handleClose,
    approveEntryApiCall,
    fetchEntrieById,
    updateAdvanceDetails,
    postAdvanceDetails,
    fetchEntriesByMonthId,
    fetchEmployeeBusinessByMonthId,
    fetchBusinessEntriesBySelf,
    fetchSelfDsaEntriesData,
    setLoading,
    setAlert,
    setOpen,
    setMessage,
  };
};

export default useDsaAndEmployeeBusinessApi;
