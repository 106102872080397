import React, { useState, useEffect } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SubDsaPayoutReceipt from "components/Forms/Business Approval/SubDsaPayoutReceipt";
export default function CR() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SubDsaPayoutReceipt />
    </DashboardLayout>
  );
}
