import React, { useState, useEffect } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PropTypes from "prop-types";
import VisibilityIcon from "@mui/icons-material/Visibility";
import MDBox from "components/MDBox";
import {
  ViewButton,
  StyledButtonRec,
  StyledButton,
  StyledAlert,
  StyledSnackbar,
  inputStyle,
  CustomTableCell,
  StyledAgGridContainer,
} from "components/StyledComponents/styledComponents.js";
import { approvalStyles } from "components/Styles/approvalStyles";
import { globalStyles } from "components/Styles/globalStyles";
import { Box, Paper, TableContainer, Button } from "@mui/material";
import BankerPayoutReceipt from "components/Forms/Business Approval/BankerPayoutReceipt";
import DsaPayoutReceipt from "components/Forms/Business Approval/DsaPayoutReceipt";
import SubDsaPayoutReceipt from "components/Forms/Business Approval/SubDsaPayoutReceipt";

export default function PayoutReceipts() {
  const [payoutReceipt, setPayoutReceipt] = useState("BR");
  const receiptsTypes = {
    "Banker Payout Receipt": "BR",
    "Dsa Payout Receipt": "DR",
    "Connector Payout Receipt": "CR",
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <TableContainer component={Paper} sx={globalStyles.accountsTable}>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: 2, // Adds spacing between buttons
            justifyContent: "space-around", // Centers the buttons inside the box
            alignItems: "center", // Vertically centers the content
            margin: "0 auto", // Centers the box within its parent container
            maxWidth: "600px", // Restricts the box to a maximum width
            width: "100%", // Sets the width to 50% of the parent container
          }}
        >
          {Object.keys(receiptsTypes).map((key) => (
            <StyledButtonRec
              key={key}
              isSelected={payoutReceipt === receiptsTypes[key]} // Pass selected state
              onClick={() => setPayoutReceipt(receiptsTypes[key])}
            >
              {key}
            </StyledButtonRec>
          ))}
        </Box>
        {payoutReceipt === "BR" && <BankerPayoutReceipt />}
        {payoutReceipt === "DR" && <DsaPayoutReceipt />}
        {payoutReceipt === "CR" && <SubDsaPayoutReceipt />}
      </TableContainer>
    </DashboardLayout>
  );
}
