import React, { useState, useEffect } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import LoanDisbursement from "components/Forms/Business Approval/LoanDisbursement";
export default function BL() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <LoanDisbursement />
    </DashboardLayout>
  );
}
