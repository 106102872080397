import { styled } from "@mui/material/styles";
import {
  Typography,
  TableContainer,
  Button,
  TableCell,
  Snackbar,
  Alert,
  Switch,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import colors from "assets/theme/base/colors";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { useMaterialUIController } from "context";

// Function to determine dynamic color
export const getBackgroundColor = ({ transparentSidenav, whiteSidenav, darkMode }) => {
  if (transparentSidenav) return colors.success.main;
  if (whiteSidenav) return colors.info.main;
  if (darkMode) return colors.info.focus;
  return colors.transparent.terinary;
};
export const CustomSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-thumb": {
    backgroundColor: "red", // Thumb color when unchecked
    border: "2px solid white", // White border around the thumb
  },
  "& .Mui-checked .MuiSwitch-thumb": {
    backgroundColor: "orange", // Thumb color when checked
    border: "2px solid white", // White border around the thumb when checked
  },
  "& .MuiSwitch-track": {
    backgroundColor: "transparent", // Always transparent track
    opacity: 1, // Ensures track remains visible if background is transparent
  },
  "& .Mui-checked + .MuiSwitch-track": {
    backgroundColor: "pink", // Track color when checked
    opacity: 1, // Ensures the track color is visible
  },
}));

const getDynamicTheme = ({ transparentSidenav, whiteSidenav, darkMode }) => {
  const bgColor = getBackgroundColor({ transparentSidenav, whiteSidenav, darkMode });
  return {
    "--ag-foreground-color": bgColor,
    "--ag-borders-color": bgColor,
    // "--ag-background-color": bgColor,
    // "--ag-header-background-color": bgColor,
    "--ag-row-hover-color": colors.success.light,
    "--ag-row-font-weight": "bold",
    fontWeight: "500",
    "--ag-scrollbar-background-color": darkMode ? "#333" : "#f0f0f0", // Scrollbar track
    "--ag-scrollbar-thumb-color": bgColor,
    "--ag-scrollbar-size": "8px",
  };
};

export const StyledAgGridContainer = styled(TableContainer)(
  ({ theme, transparentSidenav, whiteSidenav, darkMode }) => {
    const [controller] = useMaterialUIController();
    const dynamicTheme = getDynamicTheme(controller);
    const bgColor = getBackgroundColor({ transparentSidenav, whiteSidenav, darkMode });
    return {
      ...dynamicTheme,
      height: "100%", // Default height
      minHeight: "200px",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      borderRadius: "0px",
      flexGrow: 1,
      overflow: "auto",
      borderRadius: "10px",

      // Apply rounded borders and ensure content stays inside
      "& .ag-root-wrapper": {
        borderRadius: "10px",
        overflow: "hidden",
      },

      // Custom scrollbar styles
      "& ::-webkit-scrollbar": {
        width: "0px",
        height: "0px",
      },
      "& ::-webkit-scrollbar-thumb": {
        backgroundColor: bgColor,
        borderRadius: "2px",
        transition: "background-color 0.3s ease, border-radius 0.3s ease",
      },
      "& ::-webkit-scrollbar-thumb:hover": {
        backgroundColor: "#555",
      },
      "& ::-webkit-scrollbar-track": {
        backgroundColor: "#f1f1f1",
        transition: "background-color 0.3s ease",
      },
      "& .ag-paging-panel": {
        display: "flex",
        justifyContent: "center", // Centers horizontally
        alignItems: "center", // Centers vertically
        width: "100%",
        fontSize: "12px", // Default font size
        padding: "10px", // Default padding
        margin: "0 auto",
      },
      // Hover effect to increase scrollbar width
      "&:hover": {
        "& ::-webkit-scrollbar": {
          width: "6px", // Increase width on hover
          height: "6px", // Increase height on hover
          transition: "width 0.3s ease, height 0.3s ease",
        },
        "& ::-webkit-scrollbar-thumb": {
          backgroundColor: bgColor,
          borderRadius: "4px", // Adjust radius for better appearance
          transition: "background-color 0.3s ease, border-radius 0.3s ease",
        },
        "& ::-webkit-scrollbar-track": {
          backgroundColor: "#e0e0e0", // Slightly darker track color
          transition: "background-color 0.3s ease",
        },
      },
      scrollbarWidth: "thin",
      [theme.breakpoints.down(1700)]: {
        height: "100%", // Adjust for smaller screens
        "& .ag-paging-panel": {
          fontSize: "10px", // Adjust font size
          padding: "10px", // Add padding
        },
      },
      [theme.breakpoints.down(1500)]: {
        height: "100%", // Adjust for smaller screens
        "& .ag-paging-panel": {
          fontSize: "10px", // Adjust font size
          padding: "10px", // Add padding
        },
      },
      // Media queries for height adjustments
      [theme.breakpoints.down("xl")]: {
        height: "100%", // Adjust for smaller screens
        "& .ag-paging-panel": {
          fontSize: "10px", // Adjust font size
          padding: "10px", // Add padding
        },
      },
      [theme.breakpoints.down("lg")]: {
        height: "100%", // Further adjustment
        "& .ag-paging-panel": {
          fontSize: "9px", // Adjust font size
          padding: "10px", // Add padding
        },
      },
      [theme.breakpoints.down("md")]: {
        height: "100%", // Mobile screens
        minHeight: "600px",
        "& .ag-paging-panel": {
          fontSize: "10px", // Adjust font size
          padding: "10px", // Add padding
        },
        marginBottom: "10px",
      },
      [theme.breakpoints.down("sm")]: {
        height: "60%",
        minHeight: "600px",
        "& .ag-paging-panel": {
          fontSize: "10px", // Adjust font size
          padding: "10px", // Adjust padding
        },
        "& .ag-paging-panel .ag-paging-page-size": {
          display: "none", // Hide page size dropdown
        },
        "& .ag-paging-panel .ag-paging-row-summary-panel": {
          display: "none", // Hide row summary panel if present
        },
      },
    };
  }
);
export const StyledAgGridContainerForDashBoard = styled(TableContainer)(
  ({ theme, transparentSidenav, whiteSidenav, darkMode }) => {
    const [controller] = useMaterialUIController();
    const dynamicTheme = getDynamicTheme(controller);
    const bgColor = getBackgroundColor({ transparentSidenav, whiteSidenav, darkMode });
    return {
      ...dynamicTheme,
      height: "auto",
      minHeight: "200px",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      borderRadius: "0px",
      flexGrow: 1,
      overflow: "auto",
      borderRadius: "10px",

      // Apply rounded borders and ensure content stays inside
      "& .ag-root-wrapper": {
        borderRadius: "10px",
        overflow: "hidden",
      },

      // Custom scrollbar styles
      "& ::-webkit-scrollbar": {
        width: "0px",
        height: "0px",
      },
      "& ::-webkit-scrollbar-thumb": {
        backgroundColor: bgColor,
        borderRadius: "2px",
        transition: "background-color 0.3s ease, border-radius 0.3s ease",
      },
      "& ::-webkit-scrollbar-thumb:hover": {
        backgroundColor: "#555",
      },
      "& ::-webkit-scrollbar-track": {
        backgroundColor: "#f1f1f1",
        transition: "background-color 0.3s ease",
      },
      "& .ag-paging-panel": {
        display: "flex",
        justifyContent: "center", // Centers horizontally
        alignItems: "center", // Centers vertically
        width: "100%",
        fontSize: "12px", // Default font size
        padding: "10px", // Default padding
        margin: "0 auto",
      },
      // Hover effect to increase scrollbar width
      "&:hover": {
        "& ::-webkit-scrollbar": {
          width: "6px", // Increase width on hover
          height: "6px", // Increase height on hover
          transition: "width 0.3s ease, height 0.3s ease",
        },
        "& ::-webkit-scrollbar-thumb": {
          backgroundColor: bgColor,
          borderRadius: "4px", // Adjust radius for better appearance
          transition: "background-color 0.3s ease, border-radius 0.3s ease",
        },
        "& ::-webkit-scrollbar-track": {
          backgroundColor: "#e0e0e0", // Slightly darker track color
          transition: "background-color 0.3s ease",
        },
      },
      scrollbarWidth: "thin",
      [theme.breakpoints.down(1700)]: {
        height: "90vh", // Further reduce height for small screens
        minHeight: "300px", // Maintain minimum height
        "& .ag-paging-panel": {
          fontSize: "10px", // Adjust font size
          padding: "10px", // Add padding
        },
      },
      [theme.breakpoints.down(1500)]: {
        height: "90vh", // Further reduce height for small screens
        minHeight: "300px", // Maintain minimum height
        "& .ag-paging-panel": {
          fontSize: "10px", // Adjust font size
          padding: "10px", // Add padding
        },
      },
      // Media queries for height adjustments
      [theme.breakpoints.down("xl")]: {
        height: "90vh", // Further reduce height for small screens
        minHeight: "300px", // Maintain minimum height
        "& .ag-paging-panel": {
          fontSize: "10px", // Adjust font size
          padding: "10px", // Add padding
        },
      },
      [theme.breakpoints.down("lg")]: {
        height: "90vh", // Further reduce height for small screens
        minHeight: "300px", // Maintain minimum height
        "& .ag-paging-panel": {
          fontSize: "9px", // Adjust font size
          padding: "10px", // Add padding
        },
      },
      [theme.breakpoints.down("md")]: {
        height: "90vh", // Further reduce height for small screens
        minHeight: "300px", // Maintain minimum height
        "& .ag-paging-panel": {
          fontSize: "10px", // Adjust font size
          padding: "10px", // Add padding
        },
        marginBottom: "10px",
      },
      [theme.breakpoints.down("sm")]: {
        height: "90vh", // Further reduce height for small screens
        minHeight: "300px", // Maintain minimum height,
        "& .ag-paging-panel": {
          fontSize: "10px", // Adjust font size
          padding: "10px", // Adjust padding
        },
        "& .ag-paging-panel .ag-paging-page-size": {
          display: "none", // Hide page size dropdown
        },
        "& .ag-paging-panel .ag-paging-row-summary-panel": {
          display: "none", // Hide row summary panel if present
        },
      },
    };
  }
);

export const StyledTypography = styled(Typography)(({ theme }) => {
  const [controller] = useMaterialUIController();
  const bColor = getBackgroundColor(controller);

  return {
    fontWeight: "bold",
    color: bColor,
    fontSize: "1rem",
    width: "100%",
    marginTop: "10px",
    textAlign: "center",
    whiteSpace: "nowrap",
    boxShadow: "0 1px 0px rgba(0, 0, 0, 0.4)",
    paddingBottom: "10px",
    paddingTop: "3px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.875rem",
      padding: "8px",
    },
  };
});
export const StyledTypographyA = styled(Typography)(({ theme }) => {
  const [controller] = useMaterialUIController();
  const bColor = getBackgroundColor(controller);

  return {
    fontWeight: "bold",
    color: bColor,
    fontSize: "1rem",
    width: "100%",
    textAlign: "center",
    whiteSpace: "nowrap",
    boxShadow: "0 1px 0px rgba(0, 0, 0, 0.4)",
    marginBottom: "5px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.875rem",
      padding: "0px",
    },
  };
});
export const StyledTypographyAA = styled(Typography)(({ theme }) => {
  const [controller] = useMaterialUIController();
  const bColor = getBackgroundColor(controller);

  return {
    fontWeight: "bold",
    color: bColor,
    fontSize: "1rem",
    width: "90%",
    textAlign: "center",
    whiteSpace: "nowrap",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.875rem",
      padding: "0px",
    },
  };
});
export const StyledDialogBoxTitle = styled(Typography)(({ theme }) => {
  const [controller] = useMaterialUIController();
  const bColor = getBackgroundColor(controller);

  return {
    fontWeight: "bold",
    color: "white", // Text color set to white
    fontSize: "0.9rem",
    width: "fit-content", // Adjust width dynamically based on content
    maxWidth: "500px", // Limit max width for better control
    textAlign: "center",
    whiteSpace: "nowrap", // Prevent text wrapping
    overflow: "hidden", // Hide overflow text
    textOverflow: "ellipsis", // Add ellipsis if text overflows
    backgroundColor: bColor, // Background color set to bColor
    borderRadius: "8px", // Added border radius
    padding: "10px 15px", // Additional padding
    [theme.breakpoints.down("md")]: {
      fontSize: "0.9rem",
      maxWidth: "300px", // Adjust max width for smaller screens
      padding: "5px 10px", // Reduced padding for smaller screens
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.8rem",
      maxWidth: "200px", // Further reduce max width for extra small screens
      padding: "5px 10px", // Reduced padding for smaller screens
    },
  };
});

export const StyledButton = styled("button")(({ theme }) => {
  const [controller] = useMaterialUIController();
  const bColor = getBackgroundColor(controller);

  return {
    height: "40px",
    minWidth: "80px",
    maxWidth: "auto",
    paddingLeft: "10px",
    paddingRight: "10px",
    backgroundColor: bColor,
    color: "#FFFFFF",
    fontWeight: "bold",
    borderRadius: "10px",
    boxShadow: "1px 4px 4px rgba(0, 0, 0, 0.1)",
    transition: "all 0.3s ease", // Ensure smooth transitions
    border: "none",
    cursor: "pointer", // Add pointer cursor for better UX
    "&:hover": {
      backgroundColor: "#FFFFFF",
      color: bColor,
      border: `1px solid ${bColor}`,
    },
    "&:active": {
      transform: "scale(0.95)", // Slightly reduce scale on click
      color: "#FFFFFF",
    },
    "&:focus": {
      outline: "none",
    },
  };
});
export const StyledButtonRec = styled("button")(({ theme, isSelected }) => {
  const [controller] = useMaterialUIController();
  const bColor = getBackgroundColor(controller);
  const selectedBgColor = "#4CAF50"; // Green accent for selection
  const selectedTextColor = "#FFFFFF"; // White text for selected buttons

  return {
    position: "relative", // Enable pseudo-elements like the arrow
    height: "36px",
    minWidth: "70px",
    display: "block",
    maxWidth: "200px",
    padding: "8px 12px",
    backgroundColor: isSelected ? selectedBgColor : bColor,
    color: isSelected ? selectedTextColor : "#FFFFFF",
    fontWeight: "bold",
    fontSize: "14px",
    borderRadius: "8px",
    boxShadow: "1px 4px 4px rgba(0, 0, 0, 0.1)",
    border: isSelected ? `2px solid ${selectedBgColor}` : "none",
    cursor: "pointer",
    transition: "all 0.3s ease",
    whiteSpace: "nowrap", // Prevent text wrapping
    // overflow: "hidden", // Ensure no overflow issues
    // textOverflow: "ellipsis", // Show ellipsis if the text exceeds button width
    transition: "all 0.3s ease",
    "&:hover": {
      backgroundColor: isSelected ? selectedBgColor : "#FFFFFF",
      color: isSelected ? selectedTextColor : bColor,
      border: `1px solid ${bColor}`,
      transform: "scale(0.95)", // Scale effect
      transformOrigin: "center", // Prevent shifting during scale
      willChange: "transform", // Optimize performance for transform
    },
    "&:active": {
      transform: "scale(0.92)", // Slightly more scale on click
      transformOrigin: "center",
    },
    "&:focus": {
      outline: "none",
    },
    // Arrow indicator for the selected button
    "&::after": {
      content: isSelected ? '""' : "none",
      position: "absolute",
      bottom: "-10px", // Position below the button
      left: "50%",
      transform: "translateX(-50%)",
      width: "0",
      height: "0",
      borderLeft: "8px solid transparent",
      borderRight: "8px solid transparent",
      borderTop: `10px solid ${selectedBgColor}`, // Arrow color matches selected button
    },
    // Responsive styles
    [theme.breakpoints.down("md")]: {
      height: "32px",
      minWidth: "60px",
      padding: "6px 10px",
      fontSize: "12px",
      borderRadius: "6px",
    },
    [theme.breakpoints.down("sm")]: {
      height: "28px",
      minWidth: "50px",
      padding: "4px 8px",
      fontSize: "10px",
      borderRadius: "4px",
    },
  };
});

export const StyledButtonLc = styled("button")(({ theme }) => {
  const [controller] = useMaterialUIController();
  const bColor = getBackgroundColor(controller);

  return {
    height: "40px",
    width: "40px",
    borderRadius: "50%", // Ensures the button is circular
    backgroundColor: bColor,
    marginLeft: "5px",
    color: "#FFFFFF",
    fontWeight: "bold",
    boxShadow: "1px 10px 4px rgba(0, 0, 0, 0.1)",
    transition: "all 0.3s ease", // Smooth transitions
    border: "none",
    cursor: "pointer", // Pointer cursor for better UX
    display: "flex", // Ensures the button content is centered
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "#FFFFFF",
      color: bColor,
      border: `1px solid ${bColor}`,
    },
    "&:active": {
      transform: "scale(0.95)", // Slightly reduce scale on click
      color: "#FFFFFF",
    },
    "&:focus": {
      outline: "none",
    },
  };
});

export const StyledButtonL = styled("button")(({ theme }) => {
  const [controller] = useMaterialUIController();
  const bColor = getBackgroundColor(controller);

  return {
    height: "30px",
    width: "90px",
    backgroundColor: bColor,
    color: "#FFFFFF",
    fontWeight: "bold",
    borderRadius: "8px",
    boxShadow: "1px 10px 4px rgba(0, 0, 0, 0.09)",
    transition: "all 0.3s ease", // Ensure smooth transitions
    border: "none",
    cursor: "pointer", // Add pointer cursor for better UX
    "&:hover": {
      backgroundColor: "#FFFFFF",
      color: bColor,
      border: `1px solid ${bColor}`,
    },
    "&:active": {
      transform: "scale(0.95)", // Slightly reduce scale on click
      color: "#FFFFFF",
    },
    "&:focus": {
      outline: "none",
    },
  };
});

export const StyledButtonC = styled("button")`
  ${({ theme }) => {
    const [controller] = useMaterialUIController();
    const bColor = getBackgroundColor(controller);

    return `
      background-color: ${bColor};
      color: #ffffff;
      cursor: pointer;
      border: none;
      padding: 5px 10px;
      border-radius: 5px;
      margin-right: 3px;
      transition: transform 0.3s ease, background-color 0.3s ease;
      position: absolute;
      will-change: transform;
      top: 5px;
      right: 10px;
      z-index: 10;
      &:hover {
        transform: scale(1.1);
      }

      &:active {
        transform: scale(0.95); // Slightly reduce scale on click
      }

      &:focus {
        outline: none; // Remove focus outline
      }
    `;
  }}
`;
export const StyledButtonAbort = styled("button")`
  ${({ theme }) => {
    const [controller] = useMaterialUIController();
    const bColor = getBackgroundColor(controller);

    return `
      background-color: #E07572;
      color: #ffffff;
      cursor: pointer;
      font-weight: 550;
      border: none;
      padding: 5px 10px;
      border-radius: 5px;
      margin-right: 3px;
      transition: transform 0.3s ease, background-color 0.3s ease;
      position: absolute;
      will-change: transform;
      top: 5px;
      right: 55px;
      z-index: 10;
      &:hover {
        transform: scale(1.1);
      }

      &:active {
        transform: scale(0.95); // Slightly reduce scale on click
      }

      &:focus {
        outline: none; // Remove focus outline
      }
    `;
  }}
`;
export const StyledButtonCF = styled("button")`
  ${({ theme }) => {
    const [controller] = useMaterialUIController();
    const bColor = getBackgroundColor(controller);

    return `
      background-color: ${bColor};
      color: #ffffff;
      cursor: pointer;
      border: none;
      padding: 5px 10px;
      border-radius: 5px;
      margin-right: 3px;
      transition: transform 0.3s ease, background-color 0.3s ease;
      position: absolute;
      will-change: transform;
      top: 14px;
      right: 10px;
      z-index: 10;
      &:hover {
        transform: scale(1.1);
      }

      &:active {
        transform: scale(0.95); // Slightly reduce scale on click
      }

      &:focus {
        outline: none; // Remove focus outline
      }
    `;
  }}
`;
export const StyledButtonAnchor = styled("button")`
  ${({ theme }) => {
    const [controller] = useMaterialUIController();
    const bColor = getBackgroundColor(controller);

    return `
      background-color: ${bColor};
      color: #ffffff;
      cursor: pointer;
      font-weight: 550;
      border: none;
      padding: 5px 10px;
      border-radius: 5px;
      margin-right: 3px;
      transition: transform 0.3s ease, background-color 0.3s ease;
      will-change: transform;
      &:hover {
        transform: scale(1.1);
      }

      &:active {
        transform: scale(0.95); // Slightly reduce scale on click
      }

      &:focus {
        outline: none; // Remove focus outline
      }
    `;
  }}
`;

// View and Delete Buttons with specific styles
export const ViewButton = styled("button")(() => {
  const [controller] = useMaterialUIController();
  const bColor = getBackgroundColor(controller);

  return `
    background-color: ${bColor};
    color: #ffffff;
    cursor: pointer;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    transform: scale(1); /* Default scale */
    transition: transform 0.3s ease, background-color 0.3s ease;
    will-change: transform;
    &:hover {
      transform: scale(1.1); /* Scale up on hover */
    }
    &:active {
      transform: scale(0.95); /* Slightly reduce scale on click */
    }
    &:focus {
      outline: none; // Remove focus outline
    }
  `;
});

export const DeleteButton = styled("button")(() => {
  const [controller] = useMaterialUIController();
  const bColor = getBackgroundColor(controller);

  return {
    backgroundColor: "#FFFFFF",
    color: bColor,
    border: `2px solid ${bColor}`,
    padding: "3px 10px",
    borderRadius: "5px",
    marginLeft: "5px",
    transition: "transform 0.3s ease",
    willChange: "transform",
    "&:hover": {
      transform: "scale(1.1)",
    },
  };
});
export const ButtonBox = styled("div")({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  height: "70px",
  // width: "45%", // To give some space between the boxes
  padding: "10px",
  gap: "10px",
  borderRadius: "10px",
  // border: "1px solid #ccc", // Light border around the boxes
  // boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
});

// TableCell styles with responsive font size
export const StyledTableCell = styled(TableCell)(({ theme, boxShadow }) => {
  const [controller] = useMaterialUIController();
  const bColor = getBackgroundColor(controller);

  return {
    fontSize: boxShadow ? "16px" : "13px", // Default font size
    fontWeight: "normal",
    color: bColor,
  };
});

export const CustomTableCell = styled(TableCell)(
  ({ theme, minWidth, maxWidth, flexGrow, fontWeight }) => {
    const [controller] = useMaterialUIController();
    const bColor = getBackgroundColor(controller);

    return {
      fontSize: theme.breakpoints.down("xs") ? "14px" : "0.95rem",
      fontWeight: fontWeight || "500", // Default fontWeight if none is provided
      minWidth: minWidth || "auto", // Use provided minWidth or auto as default
      flexGrow: flexGrow || 0, // Use provided flexGrow or 0 as default
      color: bColor, // Dynamic color from controller
    };
  }
);

// Styled Alert component
export const StyledAlert = styled(Alert)(() => {
  const [controller] = useMaterialUIController();
  const bColor = getBackgroundColor(controller);

  return {
    backgroundColor: bColor,
    color: "#ffffff",
    "& .MuiAlert-icon": {
      color: "#ffffff",
      fontSize: "1.5rem",
    },
  };
});

// Styled Snackbar component
export const StyledSnackbar = styled(Snackbar)(({ theme, severity }) => {
  const [controller] = useMaterialUIController();
  const bColor = getBackgroundColor(controller);

  return {
    marginTop: theme.spacing(5),
    "& .MuiPaper-root": {
      backgroundColor: severity === "error" ? theme.palette.error.dark : bColor,
      color: "#ffffff",
      fontWeight: "bold",
      borderRadius: "10px",
    },
  };
});
// IconButton with custom color for icons
export const StyledIconButton = styled(IconButton)(({ color }) => {
  const [controller] = useMaterialUIController();
  const bColor = getBackgroundColor(controller);

  return {
    padding: "10px",
    marginLeft: "0px",
    marginRight: "5px",
    background: "transparent",
    border: "none",
    cursor: "pointer",
    fontSize: "18px",
    svg: {
      color: color || bColor,
    },
  };
});

export const inputStyle = () => {
  const [controller] = useMaterialUIController();
  const bColor = getBackgroundColor(controller);

  return {
    a: bColor,
    b: {
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderWidth: "1px",
          borderColor: bColor, // Default border color
        },
        "&:hover fieldset": {
          borderColor: "green", // Border color on hover
        },
        "&.Mui-focused fieldset": {
          borderColor: "#20c997", // Border color when focused
        },
        "& input": {
          color: bColor, // Text color
        },
        "& .MuiInputLabel-root": {
          color: bColor, // Label color
          "&.Mui-focused": {
            color: "#20c997", // Label color when focused
          },
        },
        "&.Mui-disabled": {
          "& fieldset": {
            borderColor: bColor, // Border color when disabled
          },
          "& input": {
            color: "gray", // Text color when disabled
          },
          "& .MuiInputLabel-root": {
            color: "gray", // Label color when disabled
          },
        },
        "& input[readonly]": {
          color: "#808080", // Text color when read-only
          pointerEvents: "none", // Prevent user interaction
        },
        "&[readonly] fieldset": {
          borderColor: "#808080", // Border color for read-only
          borderStyle: "dashed", // Optional: Distinct style for read-only
        },
        "& input[readonly] + .MuiInputLabel-root": {
          color: "#808080", // Label color for read-only
        },
      },
    },
  };
};
