import React, { useEffect, useState } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import URLs from "constants/urls";
import Cookie from "js-cookie";
import {
  Box,
  Table,
  TableBody,
  TextField,
  TableContainer,
  TableRow,
  Paper,
  Grid,
  Dialog,
  DialogContentText,
  DialogContent,
  DialogActions,
  CircularProgress,
  Typography,
  Autocomplete,
} from "@mui/material";
import {
  StyledButton,
  StyledAlert,
  StyledSnackbar,
  CustomTableCell,
  inputStyle,
} from "components/StyledComponents/styledComponents.js";
import { monthEndStyles } from "components/Styles/monthEndStyles";
import { CustomPaper, autoSelectStyle, globalStyles } from "components/Styles/globalStyles";
import useBusinessMonthYear from "components/GlobalHooks/useMonthlyBusiness";
import { subDialogContentStyle, dialogContentTextStyle } from "components/Styles/approvalStyles";
import { useNavigate } from "react-router-dom";

export default function MonthEnd() {
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [businessMonthID, setBusinessMonthID] = useState(0);
  const today = new Date();
  const formattedDate = today.toISOString().split("T")[0];
  const MInput = inputStyle();
  const navigate = useNavigate();
  const jwt_token = Cookie.get("jwt_token");

  const [monthEndDetails, setMonthEndDetails] = useState({
    businessMonthId: 0,
    businessDate: "0", // Assuming date as string
    grossPayoutOnLoan: 0,
    grossPayoutOnInsurance: 0,
    grossPayoutOnMutualFund: 0,
    dsaPayoutAmountOnInsurance: 0,
    dsaPayoutAmountOnMutualFund: 0,
    dsaPayoutAmountOnLoan: 0,
    userName: "0", // Assuming username as string
    status: "0", // Assuming status as string
    tds: 0,
    netPayoutAfterTDS: 0,
    netRevenue: 0,
    salary: 0,
    netRevenueAfterSalary: 0,
    opex: 0,
    netRevenueAfterOPEX: 0,
    retentionPercent: 0,
    dmapayout: 0,
  });

  const handleCloseDailog = () => {
    setOpenDialog(false); // Close the dialog when 'No' is clicked
  };

  const { monthIdData, loadingBId, message, alertType, setAlert, open, handleClose, fetchData } =
    useBusinessMonthYear();
  const [totalGrossPayout, setTotalGrossPayout] = useState(0);
  const [totalDsaPayouts, setTotalDsaPayouts] = useState(0);
  const [netProfit, setNetProfit] = useState(0);

  const getMonthEndDetails = async () => {
    setLoading(true);
    try {
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt_token}`, // Include the JWT token here
        },
      };
      const response = await fetch(`${URLs.monthEndUrl}/${businessMonthID}`, options);
      if (response.status === 401) {
        Cookie.remove("jwt_token");
        navigate("/login");
        return null;
      }
      if (response.ok) {
        const data = await response.json();
        setMonthEndDetails(data.data);
        setBusinessMonthID(data.data.businessMonthId || data.businessMonthId);
      } else {
        // Handle the case when the response is not OK
        const errorData = await response.json();
        setAlert(errorData.message || "Failed to fetch data", "error");
      }
    } catch (error) {
      setAlert("An error occurred while fetching data", "error");
    }
    setLoading(false);
  };

  const saveMonthEndDetails = async () => {
    const url = URLs.monthEndUrl;
    // Extract date from businessDate
    const dateObj = new Date(monthEndDetails.businessDate);

    // Extract full month name
    const month = dateObj.toLocaleString("default", { month: "long" });

    // Extract year
    const year = dateObj.getFullYear();

    // Get current date as monthEndDate
    const currentDate = new Date();
    const monthEndPayload = {
      businessMonthId: monthEndDetails.businessMonthId,
      businessDate: monthEndDetails.businessDate,
      grossPayoutOnLoan: monthEndDetails.grossPayoutOnLoan,
      grossPayoutOnInsurance: monthEndDetails.grossPayoutOnInsurance,
      grossPayoutOnMutualFund: monthEndDetails.grossPayoutOnMutualFund,
      dsaPayoutAmountOnInsurance: monthEndDetails.dsaPayoutAmountOnInsurance,
      dsaPayoutAmountOnMutualFund: monthEndDetails.dsaPayoutAmountOnMutualFund,
      dsaPayoutAmountOnLoan: monthEndDetails.dsaPayoutAmountOnLoan,
      userName: "admin",
      status: monthEndDetails.status,
      tds: monthEndDetails.tds,
      netPayoutAfterTDS: monthEndDetails.netPayoutAfterTDS,
      netRevenue: monthEndDetails.netRevenue,
      salary: monthEndDetails.salary,
      netRevenueAfterSalary: monthEndDetails.netRevenueAfterSalary,
      opex: monthEndDetails.opex,
      netRevenueAfterOPEX: monthEndDetails.netRevenueAfterOPEX,
      retentionPercent: monthEndDetails.retentionPercent,
      dmapayout: monthEndDetails.dmapayout, // Ensure spelling matches
    };
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt_token}`,
      },
      body: JSON.stringify(monthEndPayload), // Send monthEndDetails as the payload
    };
    setLoading(true);
    try {
      const response = await fetch(url, options);
      if (response.status === 401) {
        Cookie.remove("jwt_token");
        navigate("/login");
        return null;
      }
      if (response.ok) {
        const data = await response.json();
        setAlert("Data saved successfully", "success"); // Show success alert
        await fetchData();
      } else {
        const errorData = await response.json();
        setAlert(errorData.message || "Failed to save data", "error"); // Show error alert with specific message
      }
    } catch (error) {
      setAlert("An error occurred while saving data", "error"); // Show error alert on exception
    }
    setLoading(false);
    setOpenDialog(false);
  };
  const handleGetClick = () => {
    if (businessMonthID) {
      getMonthEndDetails();
    }
  };

  const calculateTotals = () => {
    const totalGross =
      monthEndDetails.grossPayoutOnLoan +
      monthEndDetails.grossPayoutOnInsurance +
      monthEndDetails.grossPayoutOnMutualFund;

    const totalDsa =
      monthEndDetails.dsaPayoutAmountOnLoan +
      monthEndDetails.dsaPayoutAmountOnMutualFund +
      monthEndDetails.dsaPayoutAmountOnInsurance;

    const profit = totalGross - totalDsa;

    setTotalGrossPayout(totalGross);
    setTotalDsaPayouts(totalDsa);
    setNetProfit(profit);
  };

  useEffect(() => {
    if (businessMonthID) {
      getMonthEndDetails();
    }
  }, []);

  useEffect(() => {
    calculateTotals();
  }, [monthEndDetails]);

  const handleSave = () => {
    saveMonthEndDetails();
  };
  const handleConfirmSave = () => {
    handleSave();
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Dialog
        open={loading}
        onClose={() => setLoading(false)}
        aria-labelledby="loading-dialog-title"
      >
        <DialogContent style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <CircularProgress color="primary" />
          <Typography variant="h6" style={{ marginTop: "20px" }}>
            Loading, please wait...
          </Typography>
        </DialogContent>
      </Dialog>
      <StyledSnackbar
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        severity={alertType}
      >
        <StyledAlert onClose={handleClose} severity={alertType}>
          {message}
        </StyledAlert>
      </StyledSnackbar>
      <TableContainer component={Paper} sx={monthEndStyles.tableContainer1}>
        <Box sx={monthEndStyles.formContainer1}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "16px", // Add gap between child elements
              flex: 1, // Ensure both boxes take equal space if needed
            }}
          >
            <Autocomplete
              options={monthIdData}
              getOptionLabel={(option) => option.date || ""}
              value={
                businessMonthID
                  ? monthIdData.find((item) => item.businessMonthID === businessMonthID)
                  : null
              }
              onChange={(event, newValue) => {
                setBusinessMonthID(newValue ? newValue.businessMonthID : "");
              }}
              PaperComponent={CustomPaper}
              renderInput={(params) => (
                <TextField
                  fullWidth
                  {...params}
                  label="Date"
                  variant="outlined"
                  required
                  sx={{
                    height: 43,
                    width: "160px",
                    ...MInput.b,
                    "& .MuiInputBase-root": {
                      height: "100%",
                      minHeight: 43,
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      color: MInput.a,
                      "&.Mui-focused": {
                        color: "red",
                      },
                    },
                  }}
                />
              )}
            />
            <StyledButton onClick={handleGetClick}>Get</StyledButton>
          </Box>
        </Box>
        <Table sx={monthEndStyles.table}>
          <TableBody>
            <TableRow sx={monthEndStyles.tableRow}>
              <CustomTableCell minWidth="100px" flexGrow={1}>
                <strong>Total Revenue</strong>
              </CustomTableCell>
              <CustomTableCell align="right" minWidth="100px" flexGrow={1} colSpan={1}>
                {`₹ ${Math.round(totalGrossPayout).toLocaleString("en-IN", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}`}
              </CustomTableCell>
            </TableRow>
            <TableRow sx={monthEndStyles.tableRow}>
              <CustomTableCell minWidth="100px" flexGrow={1} colSpan={1}>
                <strong>2 % TDS Amount</strong>
              </CustomTableCell>
              <CustomTableCell align="right" minWidth="100px" flexGrow={1}>
                {`₹ ${Math.round(monthEndDetails.tds).toLocaleString("en-IN", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}`}
              </CustomTableCell>
            </TableRow>
            <TableRow sx={monthEndStyles.tableRow}>
              <CustomTableCell minWidth="100px" flexGrow={1} colSpan={1}>
                <strong>Net Revenue</strong>
              </CustomTableCell>
              <CustomTableCell align="right" minWidth="100px" flexGrow={1}>
                {`₹ ${Math.round(monthEndDetails.netPayoutAfterTDS).toLocaleString("en-IN", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}`}
              </CustomTableCell>
            </TableRow>
            <TableRow sx={monthEndStyles.tableRow}>
              <CustomTableCell minWidth="100px" flexGrow={1}>
                <strong>Dsa Payouts</strong>
              </CustomTableCell>
              <CustomTableCell align="right" minWidth="100px" flexGrow={1}>
                {`₹ ${Math.round(totalDsaPayouts).toLocaleString("en-IN", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}`}
              </CustomTableCell>
            </TableRow>
            <TableRow sx={monthEndStyles.tableRow}>
              <CustomTableCell minWidth="100px" flexGrow={1} colSpan={1}>
                <strong>net Revenue</strong>
              </CustomTableCell>
              <CustomTableCell align="right" minWidth="100px" flexGrow={1}>
                {`₹ ${Math.round(monthEndDetails.netRevenue).toLocaleString("en-IN", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}`}
              </CustomTableCell>
            </TableRow>
            <TableRow sx={monthEndStyles.tableRow}>
              <CustomTableCell minWidth="100px" flexGrow={1} colSpan={1}>
                <strong>salary</strong>
              </CustomTableCell>
              <CustomTableCell align="right" minWidth="100px" flexGrow={1}>
                {`₹ ${Math.round(monthEndDetails.salary).toLocaleString("en-IN", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}`}
              </CustomTableCell>
            </TableRow>
            <TableRow sx={monthEndStyles.tableRow}>
              <CustomTableCell minWidth="100px" flexGrow={1} colSpan={1}>
                <strong>net Revenue After Salary</strong>
              </CustomTableCell>
              <CustomTableCell align="right" minWidth="100px" flexGrow={1}>
                {`₹ ${Math.round(monthEndDetails.netRevenueAfterSalary).toLocaleString("en-IN", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}`}
              </CustomTableCell>
            </TableRow>
            <TableRow sx={monthEndStyles.tableRow}>
              <CustomTableCell minWidth="100px" flexGrow={1} colSpan={1}>
                <strong>Opex & Cost</strong>
              </CustomTableCell>
              <CustomTableCell align="right" minWidth="100px" flexGrow={1}>
                {`₹ ${Math.round(monthEndDetails.opex).toLocaleString("en-IN", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}`}
              </CustomTableCell>
            </TableRow>
            <TableRow sx={monthEndStyles.tableRow}>
              <CustomTableCell minWidth="100px" flexGrow={1} colSpan={1}>
                <strong>P & L</strong>
              </CustomTableCell>
              <CustomTableCell align="right" minWidth="100px" flexGrow={1}>
                {`₹ ${Math.round(
                  monthEndDetails.netRevenueAfterSalary - monthEndDetails.opex
                ).toLocaleString("en-IN", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}`}
              </CustomTableCell>
            </TableRow>
            <TableRow sx={monthEndStyles.tableRow}>
              <CustomTableCell minWidth="100px" flexGrow={1} colSpan={1}>
                <strong>retention Percent</strong>
              </CustomTableCell>
              <CustomTableCell align="right" minWidth="100px" flexGrow={1}>
                {Number.isNaN(
                  (monthEndDetails.netRevenueAfterSalary - monthEndDetails.opex) / totalGrossPayout
                )
                  ? "0.00"
                  : (
                      ((monthEndDetails.netRevenueAfterSalary - monthEndDetails.opex) /
                        totalGrossPayout) *
                      100
                    ).toLocaleString("en-IN", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                %
              </CustomTableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Dialog open={openDialog} onClose={handleCloseDailog}>
          <DialogContent sx={subDialogContentStyle}>
            <DialogContentText sx={dialogContentTextStyle}>
              This action will finalize all business transactions for the month. Are you sure you
              want to proceed?
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={monthEndStyles.dialogActions}>
            <StyledButton onClick={handleConfirmSave}>Yes</StyledButton>
            <StyledButton onClick={handleCloseDailog} variant="outlined">
              No
            </StyledButton>
          </DialogActions>
        </Dialog>
        <div style={monthEndStyles.buttonContainer}>
          <StyledButton onClick={() => setOpenDialog(true)}>Save</StyledButton>
        </div>
      </TableContainer>
    </DashboardLayout>
  );
}
