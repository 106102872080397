import React, { useState, useEffect, useCallback } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useTheme } from "@mui/material/styles";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS
import "ag-grid-community/styles/ag-theme-alpine.css"; // Alpine theme CSS
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import PropTypes from "prop-types";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import useBusinessEntryApiCall from "../../../../customApiHooks/useBusinessEntryApiCall";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import PendingIcon from "@mui/icons-material/Pending";
import PaymentIcon from "@mui/icons-material/Payment";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { Tooltip } from "@mui/material";
import {
  Grid,
  MenuItem,
  TableContainer,
  Paper,
  TextField,
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
  CircularProgress,
  Typography,
  Autocomplete,
  InputAdornment,
  Popover,
  Box,
  Button,
  Switch,
  FormControlLabel,
} from "@mui/material";
import MDBox from "components/MDBox";
import {
  ViewButton,
  DeleteButton,
  StyledTypography,
  StyledTypographyA,
  StyledButton,
  StyledButtonC,
  StyledAlert,
  StyledSnackbar,
  StyledIconButton,
  StyledButtonAbort,
  StyledButtonAnchor,
  inputStyle,
  StyledAgGridContainer,
  CustomSwitch,
} from "components/StyledComponents/styledComponents.js";
import { subDialogContentStyle, dialogContentTextStyle } from "components/Styles/approvalStyles";
import { selectScrollStyle } from "components/Styles/globalStyles";
import { tableShadow, CustomPaper, globalStyles } from "components/Styles/globalStyles";
import useBusinessMonthYear from "components/GlobalHooks/useMonthlyBusiness";
import { entryStyles } from "components/Styles/loanEntryStyles";

export default function InsuranceEntry() {
  const [selectedGroup, setSelectedGroup] = useState("");
  const [selectedManager, setSelectedManager] = useState("");
  const [selectedDSA, setSelectedDSA] = useState("");
  const [selectedSubDSA, setSelectedSubDSA] = useState(0);
  const [selectedInsuranceType, setSelectedInsuranceType] = useState("");
  const [selectedAppliedTo, setSelectedAppliedTo] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [providerPayout, setProviderPayout] = useState("");
  const [dsaPayout, setDSAPayout] = useState("");
  const [selectedDsaSalesManager, setSelectedDsaSalesManager] = useState("");
  const [applicationNumber, setApplicationNumber] = useState("");
  const [date, setDate] = useState(new Date().toISOString().slice(0, 10)); // Set to current date by default
  const [firstPremium, setFirstPremium] = useState("");
  const [remarks, setRemarks] = useState("");
  const [entryId, setEntryId] = useState("");
  const [serviceEntryId, SetServiceEntryId] = useState("");
  const [subDsaPayout, setSubDsaPayout] = useState(0);
  const [subDsaId, setSubDsaId] = useState(0);
  const [serviceTypeCode, setServiceTypeCode] = useState("IN");
  const [grossPayoutAmount, setGrossPayoutAmount] = useState(0);
  const [dsaPayoutAmount, setDsaPayoutAmount] = useState(0);
  const [dsaStatus, setDsaStatus] = useState(false);
  const [businessMonthId, setBusinessMonthId] = useState(0);
  const [filteredSubDsaManagers, setFilteredSubDsaManagers] = useState([]);
  const [businessStatus, setBusinessStatus] = useState("N");
  const [openDailogAbortBox, setOpenDailogAbortBox] = useState(false);
  const [unAbortbox, setUnAbortBox] = useState(false);
  const [dsaTdsPercent, setDsaTdsPercent] = useState("");
  const [subDsaTdsPercent, setSubDsaTdsPercent] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [businessStatusFilter, setBusinessStatusFilter] = useState("All");
  const [businessMonthID, setBusinessMonthID] = useState(0);
  const [disbursedAmount, setDisbursedAmount] = useState("");
  const [loginDate, setLoginDate] = useState(new Date().toISOString().slice(0, 10));
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedEntryData, setSelectedEntryData] = useState({});
  const [connectorFormData, setConnectorFormData] = useState({
    connectorId: "",
    connectorName: "",
  });
  const [connectorData, setConnectoreData] = useState([]);
  const [localConnectorData, setLocalConnectorData] = useState(connectorData || []);
  const [actualDsaPayout, setActualDsaPayout] = useState("");
  const [disbursedState, setDisbursedState] = useState(false);
  const [payouts, setPayouts] = useState({
    providerPayoutAmount: 0,
    tdsOnProviderPayout: 0,
    finalProviderPayoutAmount: 0,
    dsaPayoutAmountD: 0,
    tdsOnDsaPayout: 0,
    finalDsaPayoutAmount: 0,
    connectorPayout: 0,
    tdsOnConnectorPayout: 0,
    finalConnectorPayoutAmount: 0,
  });
  const handleSwitchChange = (event) => {
    if (firstPremium && providerPayout && dsaPayout) {
      setDisbursedState(event.target.checked); // Use checked instead of value
      calculatePayouts();
      console.log(`Switch is ${event.target.checked ? "ON" : "OFF"}`);
    } else {
      setAlert("Please fill the from to set in disbursed state", "error");
    }
  };
  const handleConnectorChange = (e) => {
    const { name, value } = e.target;
    setConnectorFormData({ ...connectorFormData, [name]: value });
  };
  const handleOpenPopover = (event) => setAnchorEl(event.currentTarget);
  const handleClosePopover = () => setAnchorEl(null);
  const handleConnectorSave = async () => {
    if (connectorFormData.connectorId && connectorFormData.connectorName) {
      const newConnector = { ...connectorFormData };
      setLocalConnectorData((prev) => [...prev, newConnector]);
      setConnectorFormData({ connectorId: "", connectorName: "" });
      await postDsaMasterApiCall(connectorFormData);
      handleClosePopover();
    } else {
      setAlert("Please fill in both fields.", "error");
    }
  };
  const openC = Boolean(anchorEl);
  const id = openC ? "connector-popover" : undefined;

  const MInput = inputStyle();
  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    if (!/^\d*\.?\d*$/.test(inputValue)) {
      setAlert("Please enter a valid number", "error"); // Custom alert function
      return;
    }
    setFirstPremium(inputValue);
  };
  const handleDisbursedInputChange = (e) => {
    const inputValue = e.target.value;
    if (!/^\d*\.?\d*$/.test(inputValue)) {
      setAlert("Please enter a valid number", "error"); // Custom alert function
      return;
    }
    setDisbursedAmount(inputValue);
  };
  const { monthIdData, loadingBId, fetchData } = useBusinessMonthYear();
  const handleStatusChange = (event, value) => {
    setBusinessStatusFilter(value ? value.value : "");
  };
  const handleReset = () => {
    setSelectedGroup("");
    setSelectedManager("");
    setSelectedDSA("");
    setSelectedSubDSA(""); // Empty string to match type
    setSelectedInsuranceType("");
    setSelectedAppliedTo("");
    setCustomerName("");
    setProviderPayout("");
    setDSAPayout("");
    setSelectedDsaSalesManager("");
    setApplicationNumber("");
    setFirstPremium("");
    setRemarks("");
    setSubDsaPayout("");
    setEditStatus(false);
    setDate(new Date().toISOString().slice(0, 10)); // Reset to current date if needed
    setEntryId("");
    setGrossPayoutAmount(0);
    setDsaPayoutAmount(0);
    setDsaStatus(false);
    setBusinessStatus("N");
    setDsaTdsPercent("");
    setSubDsaTdsPercent("");
    setLoginDate(new Date().toISOString().slice(0, 10));
    setActualDsaPayout("");
    setDisbursedState(false);
    setPayouts({
      providerPayoutAmount: 0,
      tdsOnProviderPayout: 0,
      finalProviderPayoutAmount: 0,
      dsaPayoutAmountD: 0,
      tdsOnDsaPayout: 0,
      finalDsaPayoutAmount: 0,
      connectorPayout: 0,
      tdsOnConnectorPayout: 0,
      finalConnectorPayoutAmount: 0,
    });
  };
  const inputStatus =
    businessStatus == "C" ||
    businessStatus == "R" ||
    businessStatus == "S" ||
    businessStatus == "A";
  const switchStatus =
    businessStatus == "C" ||
    businessStatus == "R" ||
    businessStatus == "S" ||
    businessStatus == "A" ||
    businessStatus == "D";
  const {
    businessMasterData,
    providerData,
    dsaData,
    entryData,
    subDsaData,
    managersData,
    dsaManagersData,
    finalFinanceData,
    message,
    alertType,
    open,
    editStatus,
    loading,
    companyConfig,
    setLoading,
    setEditStatus,
    fetchEntriesFeildsData,
    fetchEntriesByTypeCode,
    fetchFilteredFinanceDetails,
    fetchBusinessEntriesApiCall,
    fetchFinanceDetails,
    postEntry,
    putEntry,
    deleteEntry,
    setAlert,
    handleClose,
  } = useBusinessEntryApiCall(serviceTypeCode, handleReset);

  // Effect for fetching finance details based on selectedInsuranceType
  useEffect(() => {
    if (selectedInsuranceType) {
      fetchFinanceDetails(selectedInsuranceType);
    }
  }, [selectedInsuranceType]);

  // Effect for handling DSA selection
  useEffect(() => {
    if (selectedDSA) {
      const selectedDsa = dsaData.find((dsa) => dsa.dsaId === parseInt(selectedDSA));
      if (selectedDsa) {
        setDsaStatus(selectedDsa.dsaStatus == "S"); // Set to true if status is 's'
      } else {
        setDsaStatus(false); // Reset if no DSA is found
        setSelectedDsaSalesManager(0);
        setDSAPayout(0);
        setSubDsaId(0);
      }
    } else {
      setDsaStatus(false); // Reset if no DSA is selected
    }
  }, [selectedDSA]); // Use dsaData as a dependency if it can change

  // Function to filter managers based on selectedSubDSA
  const filteredDsaMngrFunction = useCallback(() => {
    const defaultManager = {
      employeeFirstName: "No",
      employeeLastName: "Manager",
      employeeId: 0,
    };
    if (selectedSubDSA) {
      return [
        defaultManager,
        ...dsaManagersData.filter((item) => item.companyId === selectedSubDSA),
      ];
    }
    return [defaultManager];
  }, [selectedSubDSA, dsaManagersData]);

  // Effect to update filtered managers when selectedSubDSA changes
  useEffect(() => {
    const filteredData = filteredDsaMngrFunction();
    setFilteredSubDsaManagers(filteredData); // Update filtered managers state
  }, [filteredDsaMngrFunction, selectedSubDSA]);

  useEffect(() => {
    if (!editStatus && finalFinanceData.length > 0) {
      const selectedFinance = finalFinanceData.find(
        (eachEntry) => eachEntry.finananceId == selectedAppliedTo
      );

      if (selectedFinance) {
        setProviderPayout(selectedFinance.grossPayOut);
        setActualDsaPayout(selectedFinance.dsaPayOut);
        calculateMarginal(selectedFinance.grossPayOut, selectedFinance.dsaPayOut);
      }
    }
  }, [selectedAppliedTo, finalFinanceData, editStatus]);
  useEffect(() => {
    if (!dsaStatus) {
      setSelectedSubDSA(0);
      setSelectedDsaSalesManager(0);
      setSubDsaPayout("");
    }
  }, [dsaStatus]);
  useEffect(() => {
    if (companyConfig) {
      setSubDsaTdsPercent(companyConfig.companyTDSPercent);
      setDsaTdsPercent(companyConfig.companyTDSPercent);
    }
  }, [companyConfig]);

  const handleFilter = useCallback(() => {
    if (businessStatusFilter == "All") {
      fetchBusinessEntriesApiCall();
    } else {
      fetchFilteredFinanceDetails(serviceTypeCode, businessStatusFilter, businessMonthID);
    }
  }, [businessStatusFilter]);
  const calculatePayouts = () => {
    const roundToTwoDecimals = (value) => Math.round(value * 100) / 100;
    const providerPayoutAmount = roundToTwoDecimals((LoanAmount * providerPayout) / 100);
    const tdsOnProviderPayout = roundToTwoDecimals(
      (providerPayoutAmount * companyConfig.companyTDSPercent) / 100
    );
    const finalProviderPayoutAmount = roundToTwoDecimals(
      providerPayoutAmount - tdsOnProviderPayout
    );

    const dsaPayoutAmountD = roundToTwoDecimals((providerPayoutAmount * dsaPayout) / 100);
    const tdsOnDsaPayout = roundToTwoDecimals(
      (dsaPayoutAmountD * companyConfig.companyTDSPercent) / 100
    );
    const finalDsaPayoutAmount = roundToTwoDecimals(dsaPayoutAmountD - tdsOnDsaPayout);

    const connectorPayout = roundToTwoDecimals((finalDsaPayoutAmount * subDsaPayout) / 100);
    const tdsOnConnectorPayout = roundToTwoDecimals(
      (connectorPayout * companyConfig.companyTDSPercent) / 100
    );
    const finalConnectorPayoutAmount = roundToTwoDecimals(connectorPayout - tdsOnConnectorPayout);

    setPayouts({
      providerPayoutAmount,
      tdsOnProviderPayout,
      finalProviderPayoutAmount,
      dsaPayoutAmountD,
      tdsOnDsaPayout,
      finalDsaPayoutAmount,
      connectorPayout,
      tdsOnConnectorPayout,
      finalConnectorPayoutAmount,
    });
  };
  const postInsuranceEntryApiCall = () => {
    const data = {
      entryId: 0,
      dsaId: parseInt(selectedDSA),
      subdsaId: parseInt(selectedSubDSA) || 0,
      applicationNumber: applicationNumber,
      businessType: serviceTypeCode,
      date: date,
      dsaEmployeeId: selectedDsaSalesManager || 0,
      businessMonthId: parseInt(businessMonthId),
      employeeId: parseInt(selectedManager),
      businessId: parseInt(selectedInsuranceType),
      customerName: customerName,
      finananceId: parseInt(selectedAppliedTo), // Corrected key
      appliedAmount: parseFloat(firstPremium),
      disbursedAmount: parseFloat(firstPremium),
      grossPayout: parseFloat(providerPayout),
      dsaPayout: parseFloat(dsaPayout),
      subdsaPayout: parseFloat(subDsaPayout) || 0,
      grossPayoutAmount: parseFloat(payouts.providerPayoutAmount),
      finalGrossPayoutAmount: parseFloat(payouts.finalProviderPayoutAmount),
      dsaPayoutAmount: parseFloat(payouts.dsaPayoutAmountD),
      finalDsaGrossPayoutAmount: parseFloat(payouts.finalDsaPayoutAmount),
      subDsaPaymentAmount: parseFloat(payouts.connectorPayout),
      finalSubDSAGrossPayoutAmount: parseFloat(payouts.finalConnectorPayoutAmount),
      remarks: remarks,
      businessStatus: disbursedState ? "D".charAt(0) : businessStatus.charAt(0),
      disbursedAmount: parseFloat(0),
      dsaTdsPercent: parseFloat(dsaTdsPercent) || 0,
      subDsaTdsPercent: parseFloat(subDsaTdsPercent) || 0,
      loginDate: loginDate,
      actualDsaPayoutPercentage: actualDsaPayout,
    };
    postEntry(data);
  };
  const putInsuranceEntryApiCall = (entryStatus) => {
    const data = {
      ...selectedEntryData,
      entryId: parseInt(entryId),
      dsaId: parseInt(selectedDSA),
      subdsaId: parseInt(selectedSubDSA) || 0,
      applicationNumber: applicationNumber,
      businessType: serviceTypeCode,
      date: date,
      loginDate: loginDate,
      dsaEmployeeId: selectedDsaSalesManager || 0,
      businessMonthId: parseInt(businessMonthId),
      employeeId: parseInt(selectedManager),
      businessId: parseInt(selectedInsuranceType),
      customerName: customerName,
      finananceId: parseInt(selectedAppliedTo),
      appliedAmount: parseFloat(firstPremium),
      grossPayout: parseFloat(providerPayout),
      dsaPayout: parseFloat(dsaPayout),
      subdsaPayout: parseFloat(subDsaPayout) || 0,
      remarks: remarks,
      dsaTdsPercent: parseFloat(dsaTdsPercent) || 0,
      subDsaTdsPercent: parseFloat(subDsaTdsPercent) || 0,
      actualDsaPayoutPercentage: actualDsaPayout,
      ...(disbursedState &&
        businessStatus == "N" && {
          disbursedAmount: parseFloat(firstPremium),
          grossPayoutAmount: parseFloat(payouts.providerPayoutAmount),
          finalGrossPayoutAmount: parseFloat(payouts.finalProviderPayoutAmount),
          dsaPayoutAmount: parseFloat(payouts.dsaPayoutAmountD),
          finalDsaGrossPayoutAmount: parseFloat(payouts.finalDsaPayoutAmount),
          subDsaPaymentAmount: parseFloat(payouts.connectorPayout),
          finalSubDSAGrossPayoutAmount: parseFloat(payouts.finalConnectorPayoutAmount),
          businessStatus: disbursedState && "D".charAt(0),
        }),
    };
    if (entryStatus == "A") {
      putEntry(entryId, { ...data, businessStatus: "A".charAt(0) });
    } else if (entryStatus == "Initaite") {
      putEntry(entryId, { ...data, businessStatus: "N".charAt(0) });
    } else {
      putEntry(entryId, data);
    }
  };
  const deleteInsuranceEntryApiCall = (lEntryId) => {
    deleteEntry(lEntryId);
  };
  const handleEditInsuranceRow = (rowData) => {
    setSelectedEntryData(rowData);
    fetchFinanceDetails(rowData.businessId);
    setEditStatus(true);
    setApplicationNumber(rowData.applicationNumber);
    setFirstPremium(rowData.appliedAmount);
    setSelectedInsuranceType(rowData.businessId);
    setCustomerName(rowData.customerName);
    setDate(formatTimestampToDate(rowData.date));
    setSelectedDSA(rowData.dsaId);
    setDSAPayout(rowData.dsaPayout);
    setSelectedManager(rowData.employeeId);
    setSelectedDsaSalesManager(rowData.dsaEmployeeId || 0);
    setEntryId(rowData.entryId);
    setSelectedAppliedTo(rowData.finananceId);
    setProviderPayout(rowData.grossPayout);
    setRemarks(rowData.remarks);
    setSelectedSubDSA(rowData.subdsaId);
    setSubDsaPayout(rowData.subdsaPayout);
    setBusinessStatus(rowData.businessStatus);
    setSelectedGroup("1");
    setBusinessMonthId(rowData.businessMonthId);
    setBusinessMonthID(rowData.businessMonthId);
    setSubDsaTdsPercent(rowData.subDsaTdsPercent);
    setDsaTdsPercent(rowData.dsaTdsPercent);
    setDisbursedAmount(rowData.disbursedAmount);
    setActualDsaPayout(rowData.actualDsaPayoutPercentage);
  };
  const calculateMarginal = (provider, dsa) => {
    const providerValue = parseFloat(provider) || 0;
    const dsaValue = parseFloat(dsa) || 0;

    if (providerValue > 0) {
      const marginal = Math.round((dsaValue / providerValue) * 100 * 100) / 100; // Numeric rounding to 2 decimals
      setDSAPayout(marginal);
    } else {
      setDSAPayout(0); // Default to 0 if provider value is zero
    }
  };

  const handleSave = (e) => {
    e.preventDefault();
    if (editStatus) {
      putInsuranceEntryApiCall();
    } else {
      postInsuranceEntryApiCall();
    }
  };

  const menuProps = {
    PaperProps: {
      sx: {
        ...selectScrollStyle,
      },
    },
  };
  const resetFormState = () => {
    handleReset();
    setEditStatus(false);
  };
  function formatTimestampToDate(timestamp) {
    const date = new Date(timestamp); // Convert timestamp to Date object
    return date.toISOString().slice(0, 10); // Format it as "YYYY-MM-DD"
  }
  function openDailogDeleteBox(fEntryId) {
    setOpenDialog(true);
    SetServiceEntryId(fEntryId);
  }
  function handleConfirmDelete() {
    deleteInsuranceEntryApiCall(serviceEntryId); // Only delete if entryId exists
    setTimeout(() => {
      setOpenDialog(false);
    }, 1000);
  }

  const ActionCellRenderer = (props) => {
    const rowIndex = props.node.rowIndex;
    let rowData = null;

    if (props.api && props.api.getDisplayedRowAtIndex(rowIndex)) {
      rowData = props.api.getDisplayedRowAtIndex(rowIndex).data;
    } else {
      console.error("API or row data is not available.");
    }

    const handleEditClick = () => {
      if (rowData) {
        handleEditInsuranceRow(rowData); // Use the row data only if it exists
        const alertStatus =
          rowData.businessStatus === "C" ||
          rowData.businessStatus === "R" ||
          rowData.businessStatus === "S" ||
          rowData.businessStatus === "A";
        if (alertStatus) {
          setAlert("Closed business cannot be edited.", "error");
        }
      } else {
        console.warn("Row data is not available for editing.");
      }
    };

    const handleDeleteClick = () => {
      if (rowData && rowData.entryId) {
        openDailogDeleteBox(rowData.entryId);
      } else {
        console.warn("Row data or entry ID is not available for deletion.");
      }
    };
    // Move text declaration inside the renderStatusIcon function
    const renderStatusIcon = () => {
      if (!rowData) return null;

      let iconColor; // Define a variable for icon color
      let icon; // Define a variable for the icon
      let statusText; // Define a variable for status text

      switch (rowData.businessStatus) {
        case "N":
          icon = <PendingIcon />; // Replace with the pending icon you want to use
          iconColor = "#FFA500"; // Orange for Pending
          statusText = "New";
          break;
        case "D":
          icon = <CheckCircleIcon />;
          iconColor = "#4CAF50"; // Green for Approved
          statusText = "Disbursed";
          break;
        case "R":
          icon = <PaymentIcon />;
          iconColor = "#1da1f2"; // Blue for Money Received
          statusText = "Payout Received";
          break;
        case "C":
          icon = <PaymentIcon />;
          iconColor = "#ff4d4d"; // Red for Commission Paid
          statusText = "Paid to DSA";
          break;
        case "S":
          icon = <PaymentIcon />;
          iconColor = "#ff9800";
          statusText = "Paid to  Sub DSA";
          break;
        case "A":
          icon = <CancelIcon />;
          iconColor = "red";
          statusText = "Aborted";
          break;
        default:
          icon = null; // Or a default icon if needed
          iconColor = "#000"; // Default color if no status matches
          statusText = "Unknown Status"; // Optional: default text
          break;
      }
      return {
        icon: (
          <StyledIconButton type="button" color={iconColor}>
            {icon}
          </StyledIconButton>
        ),
        text: statusText, // Return the text along with the icon
      };
    };
    const { icon, text } = renderStatusIcon(); // Destructure icon and text
    return (
      <div>
        <Tooltip title={text}>{icon}</Tooltip>
        <ViewButton type="button" onClick={handleEditClick}>
          <EditIcon />
        </ViewButton>
        <DeleteButton
          type="button"
          onClick={() => {
            if (rowData && rowData.businessStatus !== "N" && rowData.businessStatus !== "D") {
              setAlert(
                `${rowData.applicationNumber} business is closed. You can not delete`,
                "error"
              );
            } else {
              handleDeleteClick();
            }
          }}
        >
          <DeleteIcon />
        </DeleteButton>
      </div>
    );
  };

  const columns = [
    {
      headerName: "Date",
      field: "date",
      filter: true,
      sorted: true,
      minWidth: 50,
      flex: 1,
      valueFormatter: (params) => {
        const date = new Date(params.value); // Convert the timestamp to a Date object
        const day = date.getDate().toString().padStart(2, "0"); // Get day with leading zero
        const month = date.toLocaleString("default", { month: "short" }); // Get month as short name
        const year = date.getFullYear(); // Get full year
        return `${day}-${month}-${year}`; // Return in "dd-MMM-yyyy" format
      },
    },
    {
      headerName: "Appl. No.",
      field: "applicationNumber",
      filter: true,
      sorted: true,
      minWidth: 70,
      flex: 1,
    },
    {
      headerName: "Loan Amount",
      field: "appliedAmount",
      filter: true,
      sortable: true,
      minWidth: 100,
      flex: 1.1,
      cellStyle: { textAlign: "right", fontWeight: "500" }, // Aligns the text to the right
      valueFormatter: (params) =>
        `₹ ${params.value.toLocaleString("en-IN", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`, // Formats and rounds to two decimals
    },
    { headerName: "Actions", cellRenderer: ActionCellRenderer, minWidth: 150, flex: 1 },
  ];

  const handleAbortClick = () => {
    if (businessStatus == "A") {
      setUnAbortBox(true);
      setOpenDailogAbortBox(true);
      setAlert("The application is aborted.", "error");
    } else {
      setOpenDailogAbortBox(true);
    }
  };
  const handleConfirmAbort = () => {
    putInsuranceEntryApiCall(unAbortbox ? "Initaite" : "A"); // "N" for Unabort, "A" for Abort
    setOpenDailogAbortBox(false);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <TableContainer component={Paper} sx={globalStyles.mainTable}>
        <Dialog
          open={loading}
          onClose={() => setLoading(false)}
          aria-labelledby="loading-dialog-title"
        >
          <DialogContent style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <CircularProgress color="primary" />
            <Typography variant="h6" style={{ marginTop: "20px" }}>
              Loading, please wait...
            </Typography>
          </DialogContent>
        </Dialog>
        <Dialog open={openDialog}>
          <DialogContent sx={subDialogContentStyle}>
            <DialogContentText sx={dialogContentTextStyle}>
              {`Are you sure you want to delete`}
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={entryStyles.dialogActions}>
            <StyledButton onClick={handleConfirmDelete}>Yes</StyledButton>
            <StyledButton onClick={() => setOpenDialog(false)} variant="outlined">
              No
            </StyledButton>
          </DialogActions>
        </Dialog>
        <Dialog open={openDailogAbortBox}>
          <DialogContent sx={subDialogContentStyle}>
            <DialogContentText sx={dialogContentTextStyle}>
              {unAbortbox
                ? `Are you sure you want to Initiate application number ${applicationNumber} for ${customerName}?`
                : `Are you sure you want to abort application number ${applicationNumber} for ${customerName}?`}
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={entryStyles.dialogActions}>
            <StyledButton onClick={handleConfirmAbort}>Yes</StyledButton>
            <StyledButton onClick={() => setOpenDailogAbortBox(false)} variant="outlined">
              No
            </StyledButton>
          </DialogActions>
        </Dialog>
        <Grid container spacing={2}>
          <Grid item xs={12} md={7}>
            <TableContainer component={Paper} sx={{ ...tableShadow, ...entryStyles.marginStyle }}>
              <div style={entryStyles.divStyle}>
                <StyledTypography>
                  {!editStatus ? "Insurance Entry Form" : "Edit Insurance Entry Details"}
                </StyledTypography>
                {editStatus && (
                  <>
                    <Tooltip title="Reset">
                      <StyledButtonC onClick={resetFormState}>
                        <RestartAltIcon />
                      </StyledButtonC>
                    </Tooltip>
                    <Tooltip title="">
                      <StyledButtonAbort onClick={handleAbortClick}>
                        {businessStatus == "A" ? "Resume" : "Abort"}
                      </StyledButtonAbort>
                    </Tooltip>
                  </>
                )}
              </div>
              <StyledSnackbar
                open={open}
                autoHideDuration={5000}
                onClose={handleClose}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                severity={alertType}
              >
                <StyledAlert onClose={handleClose} severity={alertType}>
                  {message}
                </StyledAlert>
              </StyledSnackbar>
              <form onSubmit={handleSave} autoComplete="off">
                <MDBox pt={3} pb={3} px={3}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={3}>
                      <TextField
                        label="Business Date"
                        type="date"
                        fullWidth
                        required
                        value={date || new Date().toISOString().split("T")[0]} // Set default value to today's date
                        onChange={(e) => setDate(e.target.value)}
                        InputProps={{
                          readOnly: inputStatus,
                        }}
                        sx={MInput.b}
                        InputLabelProps={{
                          shrink: true,
                          sx: {
                            color: MInput.a,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        label="Login Date"
                        type="date"
                        fullWidth
                        required
                        value={loginDate || new Date().toISOString().split("T")[0]} // Set default value to today's date
                        onChange={(e) => setLoginDate(e.target.value)}
                        InputProps={{
                          readOnly: inputStatus,
                        }}
                        sx={MInput.b}
                        InputLabelProps={{
                          shrink: true,
                          sx: {
                            color: MInput.a,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        label="Customer Name"
                        fullWidth
                        required
                        value={customerName}
                        onChange={(e) => setCustomerName(e.target.value)}
                        InputProps={{ readOnly: inputStatus }}
                        sx={MInput.b}
                        InputLabelProps={{
                          sx: {
                            color: MInput.a,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        label="Application Number"
                        fullWidth
                        required
                        value={applicationNumber}
                        onChange={(e) => setApplicationNumber(e.target.value.trim())}
                        InputProps={{ readOnly: inputStatus }}
                        sx={MInput.b}
                        InputLabelProps={{
                          sx: {
                            color: MInput.a,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        label="First Premium"
                        fullWidth
                        required
                        value={firstPremium}
                        onChange={handleInputChange}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                          inputProps: {
                            min: 0, // ensures only positive numbers can be entered
                            inputMode: "decimal", // allows numeric input with decimal on mobile
                          },
                          readOnly: inputStatus,
                        }}
                        sx={MInput.b}
                        InputLabelProps={{
                          sx: {
                            color: MInput.a,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Autocomplete
                        options={managersData}
                        getOptionLabel={(option) =>
                          option.employeeFirstName + " " + option.employeeLastName
                        }
                        value={
                          managersData.some((item) => item.employeeId === selectedManager)
                            ? managersData.find((item) => item.employeeId === selectedManager)
                            : null
                        }
                        onChange={(event, value) =>
                          setSelectedManager(value ? value.employeeId : "")
                        }
                        disableClearable={false}
                        filterOptions={(options, state) => {
                          const input = state.inputValue.toLowerCase();

                          // Separate options into those that start with the input and those that contain it
                          const startsWithInput = options.filter((option) =>
                            (option.employeeFirstName + " " + option.employeeLastName)
                              .toLowerCase()
                              .startsWith(input)
                          );

                          const containsInput = options.filter(
                            (option) =>
                              !(option.employeeFirstName + " " + option.employeeLastName)
                                .toLowerCase()
                                .startsWith(input) &&
                              (option.employeeFirstName + " " + option.employeeLastName)
                                .toLowerCase()
                                .includes(input)
                          );

                          // Combine the options with those starting with the input first, then the rest
                          return [...startsWithInput, ...containsInput];
                        }}
                        PaperComponent={CustomPaper}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select Andromeda Manager"
                            variant="outlined"
                            required
                            sx={{
                              height: 43,
                              ...MInput.b,
                              "& .MuiInputBase-root": {
                                height: "100%",
                                minHeight: 43,
                              },
                            }}
                            InputLabelProps={{
                              sx: {
                                color: MInput.a, // Ensure this applies to the label
                                "&.Mui-focused": {
                                  color: "red", // Focused label color
                                },
                              },
                            }}
                          />
                        )}
                        disabled={inputStatus}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Autocomplete
                        options={dsaData}
                        getOptionLabel={(option) => option.dsaName}
                        value={
                          dsaData.some((item) => item.dsaId === selectedDSA)
                            ? dsaData.find((item) => item.dsaId === selectedDSA)
                            : null
                        }
                        onChange={(event, value) => setSelectedDSA(value ? value.dsaId : "")}
                        disableClearable={false}
                        filterOptions={(options, state) => {
                          const input = state.inputValue.toLowerCase();

                          // Separate options into those that start with the input and those that contain it
                          const startsWithInput = options.filter((option) =>
                            option.dsaName.toLowerCase().startsWith(input)
                          );

                          const containsInput = options.filter(
                            (option) =>
                              !option.dsaName.toLowerCase().startsWith(input) &&
                              option.dsaName.toLowerCase().includes(input)
                          );

                          // Combine the options with those starting with the input first, then the rest
                          return [...startsWithInput, ...containsInput];
                        }}
                        PaperComponent={CustomPaper}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select DSA"
                            variant="outlined"
                            required
                            sx={{
                              height: 43,
                              ...MInput.b,
                              "& .MuiInputBase-root": {
                                height: "100%",
                                minHeight: 43,
                              },
                            }}
                            InputLabelProps={{
                              sx: {
                                color: MInput.a, // Ensure this applies to the label
                                "&.Mui-focused": {
                                  color: "red", // Focused label color
                                },
                              },
                            }}
                          />
                        )}
                        disabled={inputStatus}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Autocomplete
                        options={subDsaData}
                        getOptionLabel={(option) => option.dsaName}
                        value={
                          subDsaData.some((item) => item.dsaId === selectedSubDSA)
                            ? subDsaData.find((item) => item.dsaId === selectedSubDSA)
                            : null
                        }
                        onChange={(event, value) => setSelectedSubDSA(value ? value.dsaId : "")}
                        disableClearable={false}
                        filterOptions={(options, state) => {
                          const input = state.inputValue.toLowerCase();
                          // Separate options into those that start with the input and those that contain it
                          const startsWithInput = options.filter((option) =>
                            option.dsaName.toLowerCase().startsWith(input)
                          );
                          const containsInput = options.filter(
                            (option) =>
                              !option.dsaName.toLowerCase().startsWith(input) &&
                              option.dsaName.toLowerCase().includes(input)
                          );
                          // Combine the options with those starting with the input first, then the rest
                          return [...startsWithInput, ...containsInput];
                        }}
                        PaperComponent={CustomPaper}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Connector"
                            variant="outlined"
                            sx={{
                              height: 43,
                              ...MInput.b,
                              "& .MuiInputBase-root": {
                                height: "100%",
                                minHeight: 43,
                              },
                            }}
                            InputLabelProps={{
                              sx: {
                                color: MInput.a, // Ensure this applies to the label
                                "&.Mui-focused": {
                                  color: "red", // Focused label color
                                },
                              },
                            }}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {params.InputProps.endAdornment}
                                  <PersonAddIcon
                                    style={{ cursor: "pointer", marginLeft: 8 }}
                                    onClick={handleOpenPopover}
                                  />
                                </>
                              ),
                            }}
                          />
                        )}
                        disabled={inputStatus}
                      />
                      <Popover
                        id={id}
                        open={openC}
                        anchorEl={anchorEl}
                        onClose={handleClosePopover}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        PaperProps={{
                          sx: {
                            backgroundColor: "#ffffff", // Dark white background
                            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)", // Optional shadow for better visibility
                            borderRadius: "8px", // Rounded corners for a modern look
                          },
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                      >
                        <Box sx={{ p: 1, width: "15vw", maxwidth: 250, minWidth: 150 }}>
                          <StyledTypographyA>Add Connector</StyledTypographyA>
                          <TextField
                            name="connectorId"
                            label="Connector ID"
                            value={connectorFormData.connectorId}
                            onChange={handleConnectorChange}
                            fullWidth
                            margin="dense"
                            sx={MInput.b}
                            InputLabelProps={{
                              sx: {
                                color: MInput.a,
                              },
                            }}
                          />
                          <TextField
                            name="connectorName"
                            label="Connector Name"
                            value={connectorFormData.connectorName}
                            onChange={handleConnectorChange}
                            fullWidth
                            margin="dense"
                            sx={MInput.b}
                            InputLabelProps={{
                              sx: {
                                color: MInput.a,
                              },
                            }}
                          />
                          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 1 }}>
                            <StyledButtonAnchor
                              onClick={handleClosePopover}
                              sx={{ backgroundColor: "#E07572" }}
                            >
                              close
                            </StyledButtonAnchor>
                            <StyledButtonAnchor
                              onClick={handleConnectorSave}
                              variant="contained"
                              color="primary"
                              sx={{ ml: 2 }}
                            >
                              Save
                            </StyledButtonAnchor>
                          </Box>
                        </Box>
                      </Popover>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Autocomplete
                        options={businessMasterData}
                        getOptionLabel={(option) => option.businessName}
                        value={
                          businessMasterData.some(
                            (item) => item.businessID === selectedInsuranceType
                          )
                            ? businessMasterData.find(
                                (item) => item.businessID === selectedInsuranceType
                              )
                            : null
                        }
                        onChange={(event, value) =>
                          setSelectedInsuranceType(value ? value.businessID : "")
                        }
                        disableClearable={false}
                        filterOptions={(options, state) => {
                          const input = state.inputValue.toLowerCase();
                          // Separate the options into those that start with the input and those that contain it
                          const startsWithInput = options.filter((option) =>
                            option.businessName.toLowerCase().startsWith(input)
                          );
                          const containsInput = options.filter(
                            (option) =>
                              !option.businessName.toLowerCase().startsWith(input) &&
                              option.businessName.toLowerCase().includes(input)
                          );
                          // Combine the options with those starting with the input first, then the rest
                          return [...startsWithInput, ...containsInput];
                        }}
                        PaperComponent={CustomPaper}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select Policy Type"
                            variant="outlined"
                            required
                            sx={{
                              height: 43,
                              ...MInput.b,
                              "& .MuiInputBase-root": {
                                height: "100%",
                                minHeight: 43,
                              },
                            }}
                            InputLabelProps={{
                              sx: {
                                color: MInput.a, // Ensure this applies to the label
                                "&.Mui-focused": {
                                  color: "red", // Focused label color
                                },
                              },
                            }}
                          />
                        )}
                        disabled={inputStatus}
                      />
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <Autocomplete
                        options={finalFinanceData}
                        getOptionLabel={(option) => option.finainacialInstitutionName}
                        value={
                          finalFinanceData.some((item) => item.finananceId === selectedAppliedTo)
                            ? finalFinanceData.find(
                                (item) => item.finananceId === selectedAppliedTo
                              )
                            : null
                        }
                        onChange={(event, value) =>
                          setSelectedAppliedTo(value ? value.finananceId : "")
                        }
                        disableClearable={false}
                        filterOptions={(options, state) => {
                          const input = state.inputValue.toLowerCase();

                          // Filter options that start with the input value
                          const startsWithInput = options.filter((option) =>
                            option.finainacialInstitutionName.toLowerCase().startsWith(input)
                          );

                          // Filter options that contain the input value but do not start with it
                          const containsInput = options.filter(
                            (option) =>
                              !option.finainacialInstitutionName.toLowerCase().startsWith(input) &&
                              option.finainacialInstitutionName.toLowerCase().includes(input)
                          );

                          // Combine the options: ones that start with the input first, then the rest
                          return [...startsWithInput, ...containsInput];
                        }}
                        PaperComponent={CustomPaper}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Provider Name"
                            variant="outlined"
                            required
                            sx={{
                              height: 43,
                              ...MInput.b,
                              "& .MuiInputBase-root": {
                                height: "100%",
                                minHeight: 43,
                              },
                            }}
                            InputLabelProps={{
                              sx: {
                                color: MInput.a, // Ensure this applies to the label
                                "&.Mui-focused": {
                                  color: "red", // Focused label color
                                },
                              },
                            }}
                          />
                        )}
                        disabled={inputStatus}
                      />
                    </Grid>

                    <Grid item xs={12} md={2.66}>
                      <TextField
                        label="Provider Payout %"
                        type="number" // Use "text" to handle custom validation for the format
                        fullWidth
                        required
                        value={providerPayout}
                        onChange={(e) => {
                          const value = e.target.value;
                          // Regex to match the format X.yyyy where X < 9 and yyyy can be 1-3 digits
                          if (/^\d{0,2}(\.\d{0,3})?$/.test(value)) {
                            setProviderPayout(value);
                            calculateMarginal(value, actualDsaPayout);
                          }
                        }}
                        inputProps={{
                          maxLength: 5,
                          readOnly: inputStatus,
                        }} // Limit the total length to 5 characters
                        placeholder="0.000" // Example format
                        sx={MInput.b}
                        InputLabelProps={{
                          sx: {
                            color: MInput.a,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={2.66}>
                      <TextField
                        label="DSA Payout %"
                        type="number" // Use "text" to handle custom validation for the format
                        fullWidth
                        required
                        value={actualDsaPayout}
                        onChange={(e) => {
                          const value = e.target.value;
                          // Regex to match the format X.yyyy where X < 9 and yyyy can be 1-3 digits
                          if (/^\d{0,2}(\.\d{0,3})?$/.test(value)) {
                            setActualDsaPayout(value);
                            calculateMarginal(providerPayout, value);
                          }
                        }}
                        inputProps={{
                          maxLength: 5,
                          readOnly: inputStatus,
                        }}
                        placeholder="0.000" // Example format
                        sx={MInput.b}
                        InputLabelProps={{
                          sx: {
                            color: MInput.a,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={2.66}>
                      <TextField
                        label="Marginal %"
                        type="number" // Use "text" to handle custom validation for the format
                        fullWidth
                        required
                        value={dsaPayout}
                        onChange={(e) => {
                          const value = e.target.value;
                          // Regex to match the format X.yyyy where X < 9 and yyyy can be 1-3 digits
                          if (/^\d{0,2}(\.\d{0,3})?$/.test(value)) {
                            setDSAPayout(value);
                          }
                        }}
                        inputProps={{
                          maxLength: 5,
                          readOnly: inputStatus,
                        }}
                        placeholder="0.000" // Example format
                        sx={MInput.b}
                        InputLabelProps={{
                          sx: {
                            color: MInput.a,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={2.66}>
                      <TextField
                        label="Connector Payout %"
                        type="text" // Use "text" to handle custom validation for the format
                        fullWidth
                        required
                        value={subDsaPayout}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (/^\d{0,2}(\.\d{0,3})?$/.test(value)) {
                            setSubDsaPayout(value);
                          }
                        }}
                        inputProps={{
                          maxLength: 5,
                          readOnly: inputStatus,
                        }}
                        placeholder="0.000" // Example format
                        sx={MInput.b}
                        InputLabelProps={{
                          sx: {
                            color: MInput.a,
                            paddingBottom: "5px",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={9.34}>
                      <TextField
                        label="Remarks"
                        fullWidth
                        multiline
                        rows={2}
                        value={remarks}
                        onChange={(e) => setRemarks(e.target.value)}
                        InputProps={{ readOnly: inputStatus }}
                        sx={MInput.b}
                        InputLabelProps={{
                          sx: {
                            color: MInput.a,
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                  <MDBox mt={3} mb={0} display="flex" justifyContent="space-between">
                    <FormControlLabel
                      control={
                        <CustomSwitch
                          checked={disbursedState || switchStatus}
                          onChange={handleSwitchChange}
                          disabled={switchStatus}
                        />
                      }
                      label={disbursedState || switchStatus ? "Disbursed" : "Not Disbursed"}
                      labelPlacement="end"
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          color: disbursedState ? "white" : "black", // Change color based on state
                          backgroundColor: disbursedState ? "orange" : "transparent", // Orange background when Disbursed
                          padding: "4px 8px",
                          borderRadius: "7px",
                        },
                      }}
                    />
                    <StyledButton disabled={inputStatus} type="submit">
                      Save
                    </StyledButton>
                  </MDBox>
                </MDBox>
              </form>
            </TableContainer>
          </Grid>
          <Grid item xs={12} md={5}>
            <TableContainer
              // sx={{ ...tableShadow }}
              component={Paper}
              sx={entryStyles.tableContainertwoStyle}
            >
              <MDBox sx={entryStyles.mdBoxStyle}>
                <MDBox mt={1} mb={2} sx={entryStyles.subMdBoxStyle}>
                  <Autocomplete
                    options={[
                      { label: "All", value: "All" },
                      { label: "New", value: "N" },
                      { label: "Disbursed", value: "D" },
                      { label: "Payout Received From Provider", value: "R" },
                      { label: "Paid to DSA", value: "C" },
                      { label: "Paid to Sub DSA", value: "S" },
                    ]}
                    getOptionLabel={(option) => option.label}
                    value={
                      [
                        { label: "All", value: "All" },
                        { label: "New", value: "N" },
                        { label: "Disbursed", value: "D" },
                        { label: "Payout Received From Provider", value: "R" },
                        { label: "Paid to DSA", value: "C" },
                        { label: "Paid to Sub DSA", value: "S" },
                      ].find((option) => option.value === businessStatusFilter) || null
                    }
                    onChange={handleStatusChange}
                    disableClearable={false}
                    PaperComponent={CustomPaper}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Business Status"
                        variant="outlined"
                        required
                        sx={{
                          height: 42,
                          ...MInput.b,
                          ...entryStyles.autoEntrySelectStyle,
                          "& .MuiInputBase-root": {
                            height: "100%",
                            minHeight: 42,
                          },
                        }}
                        InputLabelProps={{
                          sx: {
                            color: MInput.a, // Ensure this applies to the label
                            "&.Mui-focused": {
                              color: "red", // Focused label color
                            },
                          },
                        }}
                      />
                    )}
                  />
                  <Autocomplete
                    options={monthIdData}
                    getOptionLabel={(option) => option.date || ""}
                    value={
                      businessMonthID
                        ? monthIdData.find((item) => item.businessMonthID === businessMonthID)
                        : null
                    }
                    onChange={(event, newValue) => {
                      setBusinessMonthID(newValue ? newValue.businessMonthID : "");
                    }}
                    PaperComponent={CustomPaper} // Add your custom Paper component here if needed
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        {...params}
                        label="Date"
                        variant="outlined"
                        required
                        sx={{
                          height: 43,
                          ...MInput.b,
                          ...entryStyles.autoEntrySelectStyle,
                          maxWidth: "160px",
                          "& .MuiInputBase-root": {
                            height: "100%",
                            minHeight: 43,
                          },
                        }}
                        InputLabelProps={{
                          sx: {
                            color: MInput.a, // Ensure this applies to the label
                            "&.Mui-focused": {
                              color: "red", // Focused label color
                            },
                          },
                        }}
                      />
                    )}
                  />
                  <StyledButton onClick={handleFilter}> Get</StyledButton>
                </MDBox>
              </MDBox>
              <StyledAgGridContainer component={Paper} className={`ag-theme-alpine`}>
                <AgGridReact
                  rowData={entryData}
                  columnDefs={columns}
                  pagination={true}
                  paginationPageSize={11}
                />
              </StyledAgGridContainer>
            </TableContainer>
          </Grid>
        </Grid>
      </TableContainer>
    </DashboardLayout>
  );
}
InsuranceEntry.propTypes = {
  data: PropTypes.object.isRequired,
  node: PropTypes.shape({
    rowIndex: PropTypes.number.isRequired,
  }).isRequired,
  uuid: PropTypes.string.isRequired, // Add uuid to propTypes
  api: PropTypes.shape({
    getDisplayedRowAtIndex: PropTypes.func.isRequired,
  }).isRequired,
};
