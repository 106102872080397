import ta from "date-fns/locale/ta/index.js";

export const monthEndStyles = {
  selectField: {
    height: "40px",
    width: "80px",
  },
  yearField: {
    height: "40px",
    width: "150px",
  },
  formItemM: {
    display: "flex",
    width: "700px",
    justifyContent: "space-between",
  },
  textField: {
    height: "40px",
    width: "150px",
  },
  menuProps: {
    maxHeight: 200,
    overflow: "auto",
  },
  snackbar: {
    marginTop: "48px", // theme.spacing(6) -> 48px
  },
  tableContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "83vh",
    maxHeight: "800px",
    padding: "20px",
    marginTop: "0px", // theme.spacing(2) -> 16px
  },
  formContainer: {
    marginTop: "0px",
    marginLeft: "0px",
    marginBottom: "8px",
    display: "flex",
    width: "100%", // Adjust to use full width if needed
    paddingTop: "0px",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    flexWrap: "wrap",
    gap: "16px",
  },

  formItem: {
    marginTop: "8px",
    marginLeft: "8px",
    marginBottom: "8px",
    display: "flex",
    width: "48%", // Adjust width to fit components without overlapping
    minWidth: "300px", // Ensure this is flexible and not too narrow
    paddingTop: "4px",
    justifyContent: "flex-start",
    alignItems: "center",
    flexWrap: "wrap",
    gap: "16px",
  },

  nameField: {
    height: "40px",
    width: "100%", // Make sure this takes full width of the formItem
  },

  yearField: {
    height: "40px",
    width: "100%", // Make sure this takes full width of the formItem
    color: "#000000",
  },

  selectField: {
    height: "40px",
    width: "100px",
  },
  agBodyViewport: {
    "&::-webkit-scrollbar": {
      height: "5px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#888", // Color of the scrollbar thumb
      borderRadius: "4px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#888", // Color of the scrollbar thumb on hover
      boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.5)",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent", // Color of the scrollbar track
    },
  },
  table: {
    minWidth: "400px",
    // maxWidth: "800px",
    width: "100%",
    margin: "auto",
    boxShadow: "0px 4px 20px 10px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
    overflow: "scroll",
  },
  tableRow: {
    borderBottom: "1px solid #ccc",
  },
  mainTableRow: {
    borderBottom: "2px solid #ccc",
  },
  tableCell: {
    fontSize: "0.875rem",
    fontWeight: "500",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center", // Aligns items to the center
    marginTop: "16px", // theme.spacing(2) -> 16px
  },
  label: {
    backgroundColor: "#ffffff",
  },
  gridClass: {
    height: "30%",
    flexGrow: 1,
    width: "100%",
    minHeight: "300px",
    maxHeight: "550px",
  },
  dialogActions: {
    justifyContent: "center",
    padding: "16px", // theme.spacing(2) -> 16px
  },
  formItemm: {
    marginTop: "0px", // theme.spacing(0) -> 0px
    marginLeft: "0px", // theme.spacing(0) -> 0px
    marginBottom: "8px", // theme.spacing(1) -> 8px
    display: "flex",
    width: "45%",
    minWidth: "400px",
    paddingTop: "0px", // theme.spacing(0) -> 0px
    justifyContent: "flex-start",
    alignItems: "center",
    flexWrap: "wrap",
    gap: "16px", // theme.spacing(2) -> 16px
  },
  formContainer1: {
    width: "100vw", // Spans the entire width of the parent
    display: "flex", // Enables flexbox for layout
    justifyContent: "space-between", // Distributes children evenly with space between
    alignItems: "stretch", // Makes child elements stretch to equal height
    gap: "16px", // Adds spacing between child boxes
    maxWidth: "800px", // Restricts maximum width for better design control
    marginBottom: "24px", // Adds spacing below the container
    flex: "1", // Ensures container uses available space
  },
  tabContainer: {
    width: "100%",
    height: "100%",
    maxHeight: "400px",
  },
  tableContainer1: {
    width: "100%",
    height: "83vh",
    flex: 1,
    maxHeight: "800px",
    padding: "20px",
    marginTop: "16px", // theme.spacing(2) -> 16px
  },
};
