import React, { useState, useEffect } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css"; // AG Grid styles
import "ag-grid-community/styles/ag-theme-alpine.css"; // AG Grid theme
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PropTypes from "prop-types";
import { styled } from "@mui/system";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import {
  TableContainer,
  Paper,
  Grid,
  TextField,
  Dialog,
  Typography,
  CircularProgress,
  DialogContent,
  DialogActions,
  DialogContentText,
  Tooltip,
  Autocomplete,
  Box,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import {
  ViewButton,
  DeleteButton,
  StyledTypography,
  StyledButton,
  StyledButtonC,
  StyledAlert,
  StyledSnackbar,
  inputStyle,
  StyledAgGridContainer,
} from "components/StyledComponents/styledComponents.js";

import { employeeStyles } from "components/Styles/employeeMasterStyles";
import { subDialogContentStyle, dialogContentTextStyle } from "components/Styles/approvalStyles";
import URLs from "constants/urls";
import { selectScrollStyle, CustomPaper } from "components/Styles/globalStyles";
import { globalStyles } from "components/Styles/globalStyles";
import Cookie from "js-cookie";
import { useNavigate } from "react-router-dom";
export default function EmployeeMaster() {
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [selectedFirstName, setSelectedFirstName] = useState("");
  const [selectedLastName, setSelectedLastName] = useState("");
  const [selectedEmployeeCode, setSelectedEmployeeCode] = useState("");
  const [selectedDOB, setSelectedDOB] = useState("");
  const [dataOfJoining, setDataOfJoining] = useState("");
  const [companyCode, setCompanyCode] = useState("");
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [selectedStreet, setSelectedStreet] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedEmailID, setSelectedEmailID] = useState("");
  const [selectedAlternativePhone, setSelectedAlternativePhone] = useState("");
  const [selectedReference, setSelectedReference] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedCTCPerMonth, setSelectedCTCPerMonth] = useState("");
  const [selectedNeedAccessToApp, setSelectedNeedAccessToApp] = useState("");
  const [tableData, setTableData] = useState([]);
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [alternativePhoneError, setAlternativePhoneError] = useState("");
  const today = new Date();
  const eighteenYearsAgo = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());
  const formattedEighteenYearsAgo = eighteenYearsAgo.toISOString().split("T")[0];
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [alertType, setAlertType] = useState("success");
  const [roles, setRoles] = useState({ 1: "salesManager" });
  const [employeeRoleName, setRoleName] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [roleData, setRoleData] = useState([]);
  const [dsaData, setDsaData] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteEmpId, setDeleteEmpId] = useState(0);
  const [loading, setLoading] = useState(false);
  const [deleteEmpCode, setDeleteEmpCode] = useState(0);
  const jwt_token = Cookie.get("jwt_token");
  const navigate = useNavigate();
  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    if (!/^\d*\.?\d*$/.test(inputValue)) {
      setAlert("Please enter a valid number", "error"); // Custom alert function
      return;
    }
    setSelectedCTCPerMonth(inputValue);
  };
  // Format the amount using toLocaleString for display
  const formattedAmount = selectedCTCPerMonth;
  //   selectedCTCPerMonth !== "" ? Number(selectedCTCPerMonth).toLocaleString("en-IN") : "";
  const MInput = inputStyle();

  const menuProps = {
    PaperProps: {
      sx: {
        ...selectScrollStyle,
      },
    },
  };
  // Whenever companyCode changes, update selectedCompany
  useEffect(() => {
    const selected = dsaData.find((item) => item.dsaId == companyCode);
    setSelectedCompany(selected || null); // Set the selected company based on companyCode
  }, [companyCode]); // This effect runs when companyCode changes

  const StyledPaper = styled("div")({
    ...selectScrollStyle,
    maxHeight: "200px", // Ensure the max height is set here
    overflowY: "auto", // Ensure only vertical overflow is set
    overflowX: "hidden", // Hide horizontal overflow
  });

  const fetchDsaMasterData = async () => {
    const url = URLs.dsaMasterGetUrl;
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt_token}`,
      },
    };
    setLoading(true);
    try {
      const response = await fetch(url, options);
      if (response.status === 401) {
        Cookie.remove("jwt_token");
        navigate("/login");
        return null;
      }
      const data = await response.json();
      setDsaData([...data.data]);
    } catch (error) {
      setAlert("Failed to fetch data", "error");
    }
    setLoading(false);
  };

  const fetchEmployeeData = async () => {
    setLoading(true);
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt_token}`,
      },
    };
    const url = `${URLs.employeeMasterGetUrl}`;
    try {
      const response = await fetch(url, options);
      if (response.status === 401) {
        Cookie.remove("jwt_token");
        navigate("/login");
        return null;
      }
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setTableData(data);
    } catch (error) {
      setAlert("Data not Fetched: " + error.message, "error");
    }
    setLoading(false);
  };

  const fetchRoleData = async () => {
    const url = URLs.roleGetUrl;
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt_token}`,
      },
    };
    setLoading(true);
    try {
      const response = await fetch(url, options);
      if (response.status === 401) {
        Cookie.remove("jwt_token");
        navigate("/login");
        return null;
      }
      if (!response.ok) {
        throw new Error("Network response for roles was not ok");
      }
      const data = await response.json();
      setRoleData(data); // Set role data in state
    } catch (error) {
      setAlert("Role data not fetched: " + error.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchEmployeeData();
    fetchRoleData();
    fetchDsaMasterData();
  }, []);

  const setAlert = (msg, type) => {
    setMessage(msg);
    setAlertType(type);
    setOpen(true);
  };

  const employeeDeleteApiCall = async (emId) => {
    const url = `${URLs.employeeMasterGetUrl}/${emId}`;
    const options = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt_token}`,
      },
    };
    try {
      const response = await fetch(url, options);
      if (response.status === 401) {
        Cookie.remove("jwt_token");
        navigate("/login");
        return null;
      }
      if (response.ok) {
        fetchEmployeeData();
        setAlert("Deleted SucessFully", "success");
        setIsEditMode(false);
        resetInputData();
      } else {
        throw new Error();
      }
    } catch (error) {
      setAlert("Failed to Delete", "error");
    }
  };

  const employeePutApiCall = async () => {
    const url = `${URLs.employeeMasterGetUrl}/${selectedId}`;
    const data = {
      employeeId: selectedId,
      employeeFirstName: selectedFirstName,
      employeeLastName: selectedLastName,
      employeeCode: selectedEmployeeCode,
      dateOfJoining: dataOfJoining,
      dateOfBirth: selectedDOB,
      address: {
        street: selectedStreet,
        city: selectedCity,
        state: selectedState,
      },
      phoneNumber: phoneNumber,
      emailID: selectedEmailID,
      alternativePhoneNumber: selectedAlternativePhone,
      reference: selectedReference,
      role: {
        roleId: parseInt(selectedRole),
        roleName: employeeRoleName,
      },
      ctcPerMonth: parseFloat(selectedCTCPerMonth),
      applicationAccess: selectedNeedAccessToApp.charAt(0),
      activeStatus: "y",
      companyId: parseInt(companyCode),
    };
    const options = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt_token}`,
      },
      body: JSON.stringify(data),
    };
    setLoading(true);
    try {
      const response = await fetch(url, options);
      if (response.status === 401) {
        Cookie.remove("jwt_token");
        navigate("/login");
        return null;
      }
      if (response.ok) {
        // Success path
        fetchEmployeeData();
        setAlert("Successfully Updated", "success");
        setIsEditMode(false);
        resetInputData();
      } else {
        // Extract response body for the error
        const responseData = await response.json();
        throw new Error(responseData.message || "Failed to update employee");
      }
    } catch (error) {
      // Handle error
      setAlert(error.message, "error");
    } finally {
      setLoading(false);
    }
  };

  const employeePostApiCall = async () => {
    const url = URLs.employeeMasterGetUrl;
    const data = {
      employeeId: 0, // Use 0 for new employee
      employeeFirstName: selectedFirstName,
      employeeLastName: selectedLastName,
      employeeCode: selectedEmployeeCode,
      dateOfJoining: dataOfJoining,
      dateOfBirth: selectedDOB,
      address: {
        street: selectedStreet,
        city: selectedCity,
        state: selectedState,
      },
      phoneNumber: phoneNumber,
      emailID: selectedEmailID,
      alternativePhoneNumber: selectedAlternativePhone,
      reference: selectedReference,
      role: {
        roleId: parseInt(selectedRole),
        roleName: employeeRoleName,
      },
      ctcPerMonth: parseFloat(selectedCTCPerMonth),
      applicationAccess: selectedNeedAccessToApp.charAt(0),
      activeStatus: "y",
      companyId: parseInt(companyCode),
    };

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt_token}`,
      },
      body: JSON.stringify(data),
    };
    setLoading(true);
    try {
      const response = await fetch(url, options);
      if (response.status === 401) {
        Cookie.remove("jwt_token");
        navigate("/login");
        return null;
      }
      const responseData = await response.json(); // Parse the JSON response

      // Check if the response is OK and if the status is "CREATED"
      if (response.ok) {
        fetchEmployeeData(); // Refresh the employee data list
        setAlert(responseData.message || "Posted Successfully", "success"); // Show server message
        setIsEditMode(false); // Exit edit mode
        resetInputData(); // Reset input fields
      } else {
        // If response is not ok, throw an error with the message from the response
        const errorMessage = responseData.message || "Failed to post employee data";
        throw new Error(errorMessage);
      }
    } catch (error) {
      setAlert("Failed to Post: " + error.message, "error"); // Show error message
    }
    setLoading(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = (event) => {
    event.preventDefault();
    if (!isEmailValid) {
      setAlert("Please enter a valid email.", "error");
      return; // Stop form submission if the email is invalid
    }
    if (isEditMode) {
      employeePutApiCall();
    } else {
      employeePostApiCall();
    }
    handleClose();
  };

  const handleViewClick = (rowData) => {
    setSelectedId(rowData.employeeId); // Correctly set the employeeId
    setSelectedFirstName(rowData.employeeFirstName || "");
    setSelectedLastName(rowData.employeeLastName || "");
    setSelectedEmployeeCode(rowData.employeeCode || "");
    setSelectedDOB(rowData.dateOfBirth || "");
    setDataOfJoining(rowData.dateOfJoining || "");
    setSelectedStreet(rowData.address.street || "");
    setSelectedCity(rowData.address.city || "");
    setSelectedState(rowData.address.state);
    setPhoneNumber(rowData.phoneNumber || "");
    setSelectedEmailID(rowData.emailID || "");
    setSelectedAlternativePhone(rowData.alternativePhoneNumber || "");
    setSelectedReference(rowData.reference || "");
    setSelectedRole(rowData.role.roleId || "");
    setSelectedCTCPerMonth(rowData.ctcPerMonth || "");
    setSelectedNeedAccessToApp(rowData.applicationAccess || "");
    setCompanyCode(rowData.companyId);
    setIsEditMode(true);
  };

  const resetInputData = () => {
    setSelectedFirstName("");
    setSelectedLastName("");
    setSelectedEmployeeCode("");
    setSelectedDOB("");
    setPhoneNumber("");
    setSelectedStreet("");
    setSelectedCity("");
    setSelectedState("");
    setDataOfJoining("");
    setCompanyCode("");
    setSelectedEmailID("");
    setSelectedEmployeeCode("");
    setSelectedAlternativePhone("");
    setSelectedReference("");
    setSelectedRole("");
    setSelectedCTCPerMonth("");
    setSelectedNeedAccessToApp("");
    setSelectedId("");
  };

  const resetFormState = () => {
    resetInputData();
    setIsEditMode(false);
  };

  const handleDeleteClick = (empId, empCode) => {
    if (empId) {
      setDeleteEmpId(empId);
      setDeleteEmpCode(empCode);
      setOpenDialog(true);
    } else {
      console.warn("Row data or entry ID is not available for deletion.");
    }
  };

  const ActionCellRenderer = (props) => {
    const rowIndex = props.node.rowIndex;
    const rowData = props.api.getDisplayedRowAtIndex(rowIndex).data;

    const handleEditClick = (event) => {
      // event.stopPropagation(); // Prevent event bubbling
      handleViewClick(rowData); // Call the view click handler
    };

    const handleDelete = (event) => {
      handleDeleteClick(rowData.employeeId, rowData.employeeCode);
    };

    return (
      <div>
        <ViewButton type="button" onClick={handleEditClick}>
          <EditIcon />
        </ViewButton>
        <DeleteButton type="button" onClick={handleDelete}>
          <DeleteIcon />
        </DeleteButton>
      </div>
    );
  };

  // Utility function to format the input
  const formatInputValue = (value) => {
    return value.replace(/^\s+|\s+$/g, " ").replace(/\s+/g, " ");
  };

  const columnDefs = [
    {
      headerName: "Employee Name",
      field: "employeeName",
      valueGetter: (params) => `${params.data.employeeFirstName} ${params.data.employeeLastName}`,
      sortable: true,
      filter: true,
      flex: 2, // More flex to give it more space
    },
    {
      headerName: "Role",
      field: "role.roleName",
      sortable: true,
      filter: true,
      flex: 1,
    },
    {
      headerName: "Actions",
      field: "actions",
      cellRenderer: ActionCellRenderer, // Custom action cell renderer
      width: 100,
    },
  ];
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleEmailChange = (e) => {
    const email = e.target.value;
    setSelectedEmailID(email);
    // Validate the email while setting it
    if (!emailRegex.test(email)) {
      setAlert("Invalid email format", "error");
      setIsEmailValid(false); // Set validity to false if invalid
    } else {
      setAlert("It's valied email", "success"); // Clear alert if email is valid
      setIsEmailValid(true); // Set validity to true if valid
    }
  };

  function handleConfirmDelete() {
    employeeDeleteApiCall(deleteEmpId); // Only delete if entryId exists
    setTimeout(() => {
      setOpenDialog(false);
    }, 1000);
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <TableContainer component={Paper} sx={employeeStyles.mainTable}>
        <Grid container spacing={2}>
          <Dialog
            open={loading}
            onClose={() => setLoading(false)}
            aria-labelledby="loading-dialog-title"
          >
            <DialogContent
              style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
            >
              <CircularProgress color="primary" />
              <Typography variant="h6" style={{ marginTop: "20px" }}>
                Loading, please wait...
              </Typography>
            </DialogContent>
          </Dialog>
          <Dialog open={openDialog}>
            <DialogContent sx={subDialogContentStyle}>
              <DialogContentText sx={dialogContentTextStyle}>
                {`Are you sure you want to remove ${deleteEmpCode} employee `}
              </DialogContentText>
            </DialogContent>
            <DialogActions sx={employeeStyles.dialogActions}>
              <StyledButton onClick={handleConfirmDelete}>Yes</StyledButton>
              <StyledButton onClick={() => setOpenDialog(false)} variant="outlined">
                No
              </StyledButton>
            </DialogActions>
          </Dialog>
          <Grid item xs={12} md={7}>
            <TableContainer component={Paper} sx={{ boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.3)" }}>
              <div style={{ position: "relative", width: "100%" }}>
                <StyledTypography>
                  {!isEditMode ? "Create Employee" : "Edit Employee Details"}
                </StyledTypography>
                {isEditMode && (
                  <Tooltip title="Reset">
                    <StyledButtonC onClick={resetFormState}>
                      <RestartAltIcon />
                    </StyledButtonC>
                  </Tooltip>
                )}
              </div>
              <Grid sx={employeeStyles.root}>
                <StyledSnackbar
                  open={open}
                  autoHideDuration={5000}
                  onClose={handleClose}
                  anchorOrigin={{ vertical: "top", horizontal: "center" }}
                  severity={alertType}
                >
                  <StyledAlert onClose={handleClose} severity={alertType}>
                    {message}
                  </StyledAlert>
                </StyledSnackbar>
                <form onSubmit={handleSave} autoComplete="off">
                  <Grid container spacing={2} pt={0}>
                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        label="First Name"
                        fullWidth
                        required
                        value={selectedFirstName}
                        onChange={(e) => setSelectedFirstName(formatInputValue(e.target.value))}
                        inputProps={{
                          maxLength: 120,
                        }}
                        sx={MInput.b}
                        InputLabelProps={{
                          sx: {
                            color: MInput.a,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        label="Last Name"
                        fullWidth
                        required
                        value={selectedLastName}
                        onChange={(e) => setSelectedLastName(formatInputValue(e.target.value))}
                        inputProps={{
                          maxLength: 120,
                        }}
                        sx={MInput.b}
                        InputLabelProps={{
                          sx: {
                            color: MInput.a,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        label="Employee Id"
                        fullWidth
                        required
                        value={selectedEmployeeCode}
                        onChange={(e) => setSelectedEmployeeCode(e.target.value.trim())}
                        inputProps={{
                          maxLength: 100, // Set the maximum character limit to 10
                        }}
                        sx={MInput.b}
                        InputLabelProps={{
                          sx: {
                            color: MInput.a,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        label="DOB"
                        type="date"
                        fullWidth
                        value={selectedDOB}
                        onChange={(e) => setSelectedDOB(e.target.value)}
                        InputProps={{
                          inputProps: {
                            max: formattedEighteenYearsAgo, // Set the maximum date to today minus 18 years
                          },
                        }}
                        sx={MInput.b}
                        InputLabelProps={{
                          shrink: true,
                          sx: {
                            color: MInput.a,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        label="Phone Number"
                        fullWidth
                        required
                        type="tel"
                        onChange={(e) => {
                          const phoneNumberPattern = /^[1-9]\d{9}$/; // Example for Indian numbers
                          const value = e.target.value;
                          setPhoneNumber(value);
                          setPhoneNumberError(!phoneNumberPattern.test(value));
                        }}
                        value={phoneNumber}
                        error={phoneNumberError} // Display error if phone number is invalid
                        helperText={phoneNumberError ? "Please enter a valid phone number" : ""}
                        inputProps={{
                          maxLength: 10, // Restrict to 10 digits for phone numbers
                          pattern: "[1-9]{1}[0-9]{9}",
                        }}
                        sx={MInput.b}
                        InputLabelProps={{
                          sx: {
                            color: MInput.a,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        label="Email ID"
                        type="email"
                        fullWidth
                        required
                        value={selectedEmailID}
                        onChange={handleEmailChange} // Validate email while setting it
                        sx={MInput.b}
                        InputLabelProps={{
                          sx: {
                            color: MInput.a,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        label="Alternative Phone Number"
                        fullWidth
                        type="tel"
                        onChange={(e) => {
                          const phoneNumberPattern = /^[1-9]\d{9}$/; // Example for Indian numbers
                          const value = e.target.value;
                          setSelectedAlternativePhone(value);
                          setAlternativePhoneError(!phoneNumberPattern.test(value));
                        }}
                        value={selectedAlternativePhone}
                        error={alternativePhoneError} // Display error if phone number is invalid
                        helperText={
                          alternativePhoneError ? "Please enter a valid phone number" : ""
                        }
                        inputProps={{
                          maxLength: 10, // Restrict to 10 digits for phone numbers
                          pattern: "[1-9]{1}[0-9]{9}",
                        }}
                        sx={MInput.b}
                        InputLabelProps={{
                          sx: {
                            color: MInput.a,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        label="Date of Joining"
                        fullWidth
                        value={dataOfJoining}
                        onChange={(e) => setDataOfJoining(e.target.value.trim())}
                        type="date" // Ensures a date picker input
                        inputProps={{
                          max: new Date().toISOString().split("T")[0], // Restrict future dates
                        }}
                        sx={MInput.b}
                        InputLabelProps={{
                          shrink: true,
                          sx: {
                            color: MInput.a,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        label="Reference"
                        fullWidth
                        value={selectedReference}
                        onChange={(e) => setSelectedReference(formatInputValue(e.target.value))}
                        inputProps={{
                          maxLength: 15, // Set the maximum character limit to 10
                        }}
                        sx={MInput.b}
                        InputLabelProps={{
                          sx: {
                            color: MInput.a,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <Autocomplete
                        options={roleData}
                        getOptionLabel={(option) => option.roleName}
                        value={
                          selectedRole
                            ? roleData.find((role) => role.roleId === selectedRole) || null
                            : null
                        }
                        onChange={(event, value) => {
                          if (value) {
                            setSelectedRole(value.roleId);
                            setRoleName(value.roleName);
                          } else {
                            setSelectedRole("");
                            setRoleName("");
                          }
                        }}
                        filterOptions={(options, state) => {
                          const input = state.inputValue.toLowerCase();

                          // Separate the options into those that start with the input and those that contain it
                          const startsWithInput = options.filter((option) =>
                            option.roleName.toLowerCase().startsWith(input)
                          );

                          const containsInput = options.filter(
                            (option) =>
                              !option.roleName.toLowerCase().startsWith(input) &&
                              option.roleName.toLowerCase().includes(input)
                          );

                          // Combine the options with those starting with the input first, then the rest
                          return [...startsWithInput, ...containsInput];
                        }}
                        PaperComponent={CustomPaper}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Role"
                            variant="outlined"
                            required
                            sx={{
                              height: 43,
                              ...MInput.b,
                              ...employeeStyles.labelClass,
                              "& .MuiInputBase-root": {
                                height: "100%",
                                minHeight: 43,
                              },
                            }}
                            InputLabelProps={{
                              sx: {
                                color: MInput.a,
                              },
                            }}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        label="CTC Per Month"
                        fullWidth
                        required
                        value={formattedAmount}
                        onChange={handleInputChange}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">₹</InputAdornment>, // Adds a currency symbol
                          inputProps: { min: 0 }, // Prevents negative numbers
                        }}
                        sx={MInput.b}
                        InputLabelProps={{
                          sx: {
                            color: MInput.a,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <Autocomplete
                        options={[
                          { label: "Yes", value: "Y" },
                          { label: "No", value: "N" },
                        ]}
                        getOptionLabel={(option) => option.label}
                        value={
                          selectedNeedAccessToApp === "Y" || selectedNeedAccessToApp === "N"
                            ? {
                                label: selectedNeedAccessToApp === "Y" ? "Yes" : "No",
                                value: selectedNeedAccessToApp,
                              }
                            : null
                        }
                        onChange={(event, value) => {
                          setSelectedNeedAccessToApp(value ? value.value : "");
                        }}
                        filterOptions={(options, state) => {
                          const input = state.inputValue.toLowerCase();

                          // Separate options into those that start with the input and those that contain it
                          const startsWithInput = options.filter((option) =>
                            option.label.toLowerCase().startsWith(input)
                          );

                          const containsInput = options.filter(
                            (option) =>
                              !option.label.toLowerCase().startsWith(input) &&
                              option.label.toLowerCase().includes(input)
                          );

                          // Combine the options with those starting with the input first, then the rest
                          return [...startsWithInput, ...containsInput];
                        }}
                        PaperComponent={CustomPaper}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Application Access"
                            variant="outlined"
                            required
                            sx={{
                              height: 43,
                              ...MInput.b,
                              ...employeeStyles.labelClass,
                              "& .MuiInputBase-root": {
                                height: "100%",
                                minHeight: 43,
                              },
                            }}
                            InputLabelProps={{
                              sx: {
                                color: MInput.a,
                              },
                            }}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        label="Street"
                        fullWidth
                        value={selectedStreet}
                        onChange={(e) => setSelectedStreet(formatInputValue(e.target.value))}
                        inputProps={{
                          maxLength: 20, // Set the maximum character limit to 10
                        }}
                        sx={MInput.b}
                        InputLabelProps={{
                          sx: {
                            color: MInput.a,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        label="City"
                        fullWidth
                        value={selectedCity}
                        onChange={(e) => setSelectedCity(formatInputValue(e.target.value))}
                        inputProps={{
                          maxLength: 20, // Set the maximum character limit to 10
                        }}
                        sx={MInput.b}
                        InputLabelProps={{
                          sx: {
                            color: MInput.a,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        label="State"
                        fullWidth
                        value={selectedState}
                        onChange={(e) => setSelectedState(formatInputValue(e.target.value))}
                        inputProps={{
                          maxLength: 20, // Set the maximum character limit to 10
                        }}
                        sx={MInput.b}
                        InputLabelProps={{
                          sx: {
                            color: MInput.a,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <Autocomplete
                        options={dsaData}
                        getOptionLabel={(option) => option.dsaName}
                        value={selectedCompany}
                        onChange={(event, value) => {
                          setCompanyCode(value ? value.dsaId : "");
                        }}
                        filterOptions={(options, state) => {
                          const input = state.inputValue.toLowerCase();

                          // Separate options into those that start with the input and those that contain it
                          const startsWithInput = options.filter((option) =>
                            option.dsaName.toLowerCase().startsWith(input)
                          );

                          const containsInput = options.filter(
                            (option) =>
                              !option.dsaName.toLowerCase().startsWith(input) &&
                              option.dsaName.toLowerCase().includes(input)
                          );

                          // Combine the options with those starting with the input first, then the rest
                          return [...startsWithInput, ...containsInput];
                        }}
                        PaperComponent={CustomPaper}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Company"
                            variant="outlined"
                            required
                            sx={{
                              height: 43,
                              ...MInput.b,
                              "& .MuiInputBase-root": {
                                height: "100%",
                                minHeight: 43,
                              },
                            }}
                            InputLabelProps={{
                              sx: {
                                color: MInput.a,
                              },
                            }}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                    mt={2}
                  >
                    <StyledButton type="submit">Save</StyledButton>
                  </Grid>
                </form>
              </Grid>
            </TableContainer>
          </Grid>

          <Grid item xs={12} md={5}>
            <StyledAgGridContainer
              component={Paper}
              className={`ag-theme-alpine ${employeeStyles.agTable}`} // Apply the new class
            >
              <AgGridReact
                rowData={tableData} // Pass the table data as rowData
                columnDefs={columnDefs} // Pass the column definitions
                pagination={true} // Enable pagination
                paginationPageSize={11} // Set the number of rows per page
              />
            </StyledAgGridContainer>
          </Grid>
        </Grid>
      </TableContainer>
    </DashboardLayout>
  );
}
EmployeeMaster.propTypes = {
  data: PropTypes.object.isRequired,
  node: PropTypes.shape({
    rowIndex: PropTypes.number.isRequired,
  }).isRequired,
  api: PropTypes.shape({
    getDisplayedRowAtIndex: PropTypes.func.isRequired,
  }).isRequired,
};
